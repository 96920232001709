import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import { CapacitorContext } from "../../context/CapacitorContext";

const GanttChart = ({ partidas }) => {
  const [series, setSeries] = useState(null);
  const { device, platform } = useContext(CapacitorContext);


  useEffect(() => {
    if (Array.isArray(partidas)) {
      const dataPoints = getDataPoints();
      setSeries(dataPoints);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partidas]);

  const getDataPoints = () => {
    let series = [];
    if (Array.isArray(partidas)) {
      const data = [];
      partidas.forEach((partida) => {
        data.push({
          fillColor: partida.punto_critico ? "#FC6767" : "#007991",
          x: partida.nombre,
          y: [
            moment(partida.fecha_inicio).toDate().getTime(),
            moment(partida.fecha_fin).toDate().getTime(),
          ],
        });
      });
      series.push({ data });
    }
    return series;
  };

  const renderChart = () => {
    if (Array.isArray(series)) {
      return (
        <Chart
          type="rangeBar"
          series={series}
          height={'355px'}
          options={{
            chart: {
              type: "rangeBar",
              offsetX: platform === 'web' ? 0 : -25, 
            },
            plotOptions: {
              bar: {
                horizontal: true,
                distributed: true,
                dataLabels: {
                  hideOverflowingLabels: false,
                },
              },
            },
            yaxis: {
              show: platform === 'web' ? true : false,
            },
            xaxis: {
              type: "datetime",
            },
          }}
        />
      );
    }
  };

  return <div className="position-relative " style={{minHeight: '355px'}}>{renderChart()}</div>;
};

export default GanttChart;

import React, { useContext, useState, useEffect } from "react";
import { PartidasContratoContext } from "../../context/PartidasContratoContext";
import { ConceptosContext } from "../../context/ConceptosContext";
import PreviewPartidasTable from "./PreviewPartidasTable";
import FieldMapResult from "../global/FieldMapResult";
import FieldMapper from "../global/FieldMapper";
import {
  getConceptosFromWorkbook,
  getPartidasFromWorkbook,
  getSheetHeaders,
} from "../../utils";
import * as XLSX from "xlsx";
import PreviewConceptosTable from "../conceptos/PreviewConceptosTable";
import SelectSheet from "./SelectSheet";
import UploadSheet from "./UploadSheet";
import { useParams } from "react-router-dom";
import { PartidasContext } from "../../context/PartidasContext";
import { DestajosContext } from "../../context/DestajosContext";

const partidasOptions = ["clave", "nombre", "fecha_inicio", "fecha_fin"];
const conceptosOptions = [
  "clave",
  "concepto",
  "fecha_inicio",
  "fecha_fin",
  "unidad",
  "cantidad",
  "precio_unitario",
];

const UploadPreciarioForm = ({ idProyecto }) => {
  const { idContrato } = useParams();
  const [headers, setHeaders] = useState(null);
  const [workbook, setWorkbook] = useState(null);
  const [sheetName, setSheetName] = useState("");
  const [sheetNames, setSheetNames] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPartidas, setShowPartidas] = useState(true);
  const [partidasFields, setPartidasFields] = useState(null);
  const [conceptosFields, setConceptosFields] = useState(null);
  const { getDestajos } = useContext(DestajosContext);
  const { getPartidasProyecto } = useContext(PartidasContext);
  const { partidas, preciario, setPartidas } = useContext(
    PartidasContratoContext
  );
  const { conceptos, setConceptos } = useContext(ConceptosContext);

  useEffect(() => {
    getPartidasProyecto(idProyecto);
    getDestajos(idProyecto);
    setConceptos(null);
    setPartidas(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  useEffect(() => {
    if (preciario !== null) {
      setPartidas(null);
      const reader = new FileReader();
      reader.readAsArrayBuffer(preciario);
      reader.onload = (e) => {
        processPreciarioFile(e.target.result);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preciario]);

  useEffect(() => {
    if (sheetName !== "") {
      readSheet(sheetName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetName]);

  useEffect(() => {
    if (conceptosFields !== null) {
      extractPartidas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptosFields]);

  useEffect(() => {
    if (Array.isArray(partidas) && !Array.isArray(conceptos)) {
      let first = partidas[0];
      if (first) {
        if (first.createdAt) {
          setShowPartidas(false);
          extractConceptos();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partidas]);

  const extractConceptos = () => {
    setShowSpinner(true);
    let parsedConceptos = getConceptosFromWorkbook(
      workbook,
      sheetName,
      partidas,
      conceptosFields
    );
    setConceptos(parsedConceptos);
    setShowSpinner(false);
  };

  const extractPartidas = () => {
    setShowSpinner(true);
    const parsedPartidas = getPartidasFromWorkbook(
      workbook,
      sheetName,
      partidasFields,
      conceptosFields
    );
    setPartidas(parsedPartidas);
    setShowSpinner(false);
  };

  const processPreciarioFile = (bufferArray) => {
    const current = XLSX.read(bufferArray, { type: "buffer" });
    setSheetNames(current.SheetNames);
    setWorkbook(current);
  };

  const resetSheet = () => {
    setSheetName("");
    setHeaders(null);
    setPartidasFields(null);
    setConceptosFields(null);
  };

  const readSheet = (sheetName) => {
    const headerRow = getSheetHeaders(workbook, sheetName);
    setHeaders(headerRow);
  };

  const renderSelectColumns = () => {
    if (headers && headers !== null) {
      if (partidasFields === null) {
        return (
          <div className="card p-3 shadow mb-3">
            <FieldMapper
              title="Partidas"
              options={headers}
              fields={partidasOptions}
              handleSubmit={setPartidasFields}
            />
          </div>
        );
      }
      if (conceptosFields === null) {
        return (
          <div className="card p-3 shadow mb-3">
            <FieldMapper
              title="Conceptos"
              options={headers}
              fields={conceptosOptions}
              handleSubmit={setConceptosFields}
            />
          </div>
        );
      }
    }
  };

  const renderPartidasFields = () => {
    if (partidasFields !== null) {
      return (
        <FieldMapResult
          title="Campos Partidas"
          fields={partidasFields}
          clearFields={() => setPartidasFields(null)}
        />
      );
    }
  };

  const renderConceptosFields = () => {
    if (conceptosFields !== null) {
      return (
        <FieldMapResult
          title="Campos Conceptos"
          fields={conceptosFields}
          clearFields={() => setConceptosFields(null)}
        />
      );
    }
  };

  const renderPartidas = () => {
    if (conceptosFields !== null && showPartidas) {
      return (
        <PreviewPartidasTable idProyecto={idProyecto} idContrato={idContrato} />
      );
    }
  };

  const renderConceptos = () => {
    if (Array.isArray(conceptos) && Array.isArray(partidas)) {
      return (
        <PreviewConceptosTable
          idProyecto={idProyecto}
          idContrato={idContrato}
        />
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <h1 className="bold mb-3">Cargar Catálogo</h1>
      <UploadSheet />
      <SelectSheet
        sheetName={sheetName}
        sheetNames={sheetNames}
        resetSheet={resetSheet}
        setSheetName={setSheetName}
      />
      {renderPartidasFields()}
      {renderConceptosFields()}
      {renderSelectColumns()}
      {showSpinner && <div className="spinner-border" />}
      {renderPartidas()}
      {renderConceptos()}
    </div>
  );
};

export default UploadPreciarioForm;

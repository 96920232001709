import React, { useContext, useEffect } from "react";
import { formatMonto } from "../../utils";
import { DestajosContext } from "../../context/DestajosContext";

const ConceptoForm = ({ idProyecto, concepto, modifier, deleteConcepto }) => {
  const { destajos, getDestajos } = useContext(DestajosContext);
  const {
    idConcepto,
    idDestajo,
    clave,
    nombre,
    unidad,
    precio,
    importe,
    cantidad,
  } = concepto;

  useEffect(() => {
    if (!Array.isArray(destajos)) {
      getDestajos(idProyecto);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDestajos = () => {
    if (Array.isArray(destajos)) {
      return [
        <option key="null" value={null}>
          Sin Destajo
        </option>,
        ...destajos.map((destajo) => (
          <option key={destajo.idDestajo} value={destajo.idDestajo}>
            {destajo.nombre}
          </option>
        )),
      ];
    }
  };

  const handleChangeImporte = (key, value) => {
    if(key === 'precio') {
      const precioUnitario = value / cantidad;
      modifier(idConcepto, "precio_unitario",  precioUnitario);
    } 

    if(key === 'cantidad') {
      const precioUnitario = precio / value;
      modifier(idConcepto, "precio_unitario",  precioUnitario);
    } 

    modifier(idConcepto, key,  value);

  }

  return (
    <tr>
      <td />
      <td>
        <input
          name="clave"
          value={clave}
          maxLength={140}
          className="form-control"
          onChange={(e) => modifier(idConcepto, "clave", e.target.value)}
        />
      </td>
      <td>
        <input
          name="nombre"
          value={nombre}
          maxLength={140}
          className="form-control"
          onChange={(e) => modifier(idConcepto, "nombre", e.target.value)}
        />
      </td>
      <td>
        <input
          name="unidad"
          value={unidad}
          maxLength={140}
          className="form-control"
          onChange={(e) => modifier(idConcepto, "unidad", e.target.value)}
        />
      </td>
      <td>
        {String(idConcepto).includes("nuevo") && (
          <input
            min={0}
            type="number"
            name="cantidad"
            value={cantidad}
            className="form-control"
            onChange={(e) => handleChangeImporte("cantidad", Number(e.target.value))}
          />
        )}
        {!String(idConcepto).includes("nuevo") && cantidad}
      </td>
      <td>
        {String(idConcepto).includes("nuevo") && (
          <input
            min={0}
            name="precio"
            type="number"
            value={precio}
            className="form-control"
            onChange={(e) => handleChangeImporte("precio", Number(e.target.value))}
          />
        )}
        {!String(idConcepto).includes("nuevo") && formatMonto(precio)}
      </td>
      <td>
        {"$"}
        {String(idConcepto).includes("nuevo")
          ? formatMonto(precio / cantidad)
          : formatMonto(importe)}
      </td>
      <td colSpan={2}>
        <select
          value={idDestajo}
          className="form-control"
          onChange={(e) => modifier(idConcepto, "idDestajo", e.target.value)}
        >
          {renderDestajos()}
        </select>
      </td>
      <td>
        <button className="btn btn-outline-danger" onClick={deleteConcepto}>
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default ConceptoForm;

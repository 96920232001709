import React, { useState, useContext, useEffect } from "react";
import { ClientesContext } from "../../context/ClientesContext";
import { UsuariosContext } from "../../context/UsuariosContext";
import { ModalContext } from "../../context/ModalContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import UsuarioClienteForm from "./UsuarioClienteForm";
import UsuarioForm from "../usuarios/UsuarioForm";
import ListaUsuarios from "../usuarios/ListaUsuarios";
import { hideModal } from "../../utils";
import TabOptions from "../common/TabOptions";

const UsuariosCliente = ({ idCliente }) => {
  const { platform } = useContext(CapacitorContext);
  const { modalComponent, clearModal } = useContext(ModalContext);
  const { 
    usuario, 
    users, 
    getUsers, 
    createUsuario, 
    setPropiedadUser,
    clearUsuario,
    editUsuario
  } = useContext(UsuariosContext);

  const [usersData, setUsersData] = useState();
  const [query, setQuery] = useState("");
  const [isTableView, setIsTableView] = useState(platform === "web");


  const { 
    cliente, 
    usuarios: usersPerClient, 
    getUsuariosCliente, 
    deleteUsuarioCliente 
  } = useContext(ClientesContext);

  useEffect(() => {
    getUsers()
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsersData();
  }, [usersPerClient, users]);

  const fetchUsers = () => {
    getUsuariosCliente(idCliente);
  };

  const getUsersData = () => {
    const newUsersData = usersPerClient?.map(item => {
      const userData = users?.find(obj => {
        return obj.idUsuario === item.idUsuario;
      });

      return {
        ...item,
        ...userData
      }
    });

    setUsersData(newUsersData);
  }

  const handleCreateUsuario = () => {
    createUsuario();
    modalComponent(
      "Agregar Usuario",
      <UsuarioClienteForm
        usuario={usuario}
        idCliente={idCliente}
        handleCallback={fetchUsers}
        setPropiedadUser={setPropiedadUser}
      />
    );
  };

  const handleCancel = () => {
    hideModal();
    clearModal();
    clearUsuario();
  };

  const handleEditUsuario = (usuario) => {
    editUsuario(usuario);
    modalComponent(
      "Editar Usuario",
      <UsuarioForm handleCancel={handleCancel} title={"Editar Usuario"} />
    );
  };

  const handleDeleteUsuario = (usuario) => {
    modalComponent(
      "Precaución",
      <div className="container-fluid px-0">
        <p>
          ¿Eliminar el permiso de <b>{usuario.correo}</b> para el cliente <b>{cliente.nombre}</b>?
        </p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteUsuarioCliente(idCliente, usuario.idUsuario, fetchUsers)}
            >
              Eliminar
            </button>
          </div>
          <div className="col-6 text-end">
            <button className="btn btn-link text-muted" onClick={clearModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };


  function renderUsersTable() {
    let currentUsers = usersData;

    if (query && query !== "") {
      let currentQuery = String(query).toLowerCase();
      currentUsers = usersData.filter((user) => {
        let nombre = String(user.nombre).toLowerCase();
        let correo = String(user.correo).toLowerCase();
        return nombre.includes(currentQuery) || correo.includes(currentQuery);
      });
    }

    return(
      <ListaUsuarios
        query={query}
        users={currentUsers}
        platform={platform}
        isTableView={isTableView}
        editUsuario={handleEditUsuario}
        deleteUsuario={handleDeleteUsuario}
      />
    )
  }

  return (
    <>
      <TabOptions
        query={query}
        setQuery={setQuery}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        handleCreate={handleCreateUsuario}
        buttonCreateText={'+ NUEVO USUARIO'}
        searchInputPlaceholder={'Buscar usuario...'}
      />

      <div className="row show-mobile">
        <div className="col-12">
          <input
            type="text"
            value={query}
            className="form-control bg-white"
            placeholder="Buscar usuario..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
       
      </div>
      
      <div>{renderUsersTable()}</div>
    </>
  );
};

export default UsuariosCliente;

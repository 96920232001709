import React, { useContext } from "react";
import EstadoObra from "../common/EstadoObra";
import ColorBox from "../common/ColorBox";
import Chart from "react-apexcharts";
import Rubro from "../common/Rubro";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const colors = [
  { title: "Programado", color: "#007991", className: "programado-gradiente" },
  { title: "Real", color: "#89d405", className: "real-gradiente" },
  {
    title: "Diferencial",
    color: "#FC6767",
    className: "diferencial-gradiente",
  },
];

const AvanceObra = ({ proyecto }) => {
  const appconfig = useContext(AppConfigContext);

  const getProgresoReal = () => {
    if (getValue(appconfig, "progress") === "money") {
      return Math.ceil(
        (parseFloat(proyecto.ejecutado) / parseFloat(proyecto.autorizado)) * 100
      );
    }
    return Math.ceil(parseFloat(proyecto.real) * 100);
  };

  const renderProgreso = () => {
    if (proyecto.programado) {
      const programado = parseFloat(proyecto.programado) * 100;
      const real = getProgresoReal();
      return (
        <Chart
          type="radialBar"
          options={{
            stroke: {
              lineCap: "round",
            },
            colors: ["#89d405", "#007991"],
            labels: ["Real", "Programado"],
            fill: {
              type: "gradient",
              gradient: {
                shade: "dark",
                stops: [0, 100],
                gradientToColors: ["#B3FF00", "#05D4C6"],
              },
            },
            plotOptions: {
              radialBar: {
                track: {
                  background: [programado === 100 ? "#FC6767" : "#ddd", "#ddd"],
                },
              },
            },
          }}
          series={[real, programado]}
        />
      );
    }
  };

  const renderContent = () => {
    if (proyecto && proyecto !== null) {
      let real = getProgresoReal();
      if (isNaN(real)) real = 0;
      let programado = parseInt(proyecto.programado * 100);
      if (isNaN(programado)) programado = 0;
      let diferencial = Math.ceil(real - programado);
      if (isNaN(diferencial)) diferencial = 0;
      let estado = real < programado ? (programado - real > 0.05 ? 3 : 2) : 1;
      return (
        <>
          <div className="row">
            <div className="col-lg-12 col-xl-6" style={{ minHeight: 216 }}>
              {renderProgreso()}
            </div>
            <div className="col-lg-12 col-xl-6">
              <EstadoObra round estado={estado} />
              <div className="row">
                <div className="col-9 pt-3 px-0 col-sm-8 border-right">
                  {colors.map((color, index) => {
                    return (
                      <div className="row  mx-0" key={index}>
                        <ColorBox color={color} />
                      </div>
                    );
                  })}
                </div>
                <div className="col-3 px-0 col-sm-4">
                  <div className="row pt-3 mx-0 justify-content-end">
                    {programado}
                    {"%"}
                  </div>
                  <div className="row pt-3 mx-0 justify-content-end">
                    {real}
                    {"%"}
                  </div>
                  <div className="row pt-3 mx-0 justify-content-end">
                    {diferencial}
                    {"%"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-6 mb-3">
              <h6 className="bold mb-1">Actividad actual programada</h6>
              <Rubro completado={false} nombre={proyecto.actividadProgramada} />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <h6 className="bold mb-1">Actividad actual real</h6>
              <Rubro completado={false} nombre={proyecto.actividad} />
            </div>
          </div>
        </>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="container-fluid px-0">{renderContent()}</div>;
};

export default AvanceObra;

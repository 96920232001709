import {
  EDIT_CONCEPTOS,
  DELETE_CONCEPTO,
  CREATE_CONCEPTO,
  CONCEPTOS_RECIBIDOS,
  CREATE_CONCEPTO_EXTRA,
  DELETE_CONCEPTO_EXTRA,
  SET_PROPERTY_CONCEPTO,
  EDIT_CONCEPTOS_ESTIMACION,
} from "../types/conceptos";
import { SELECT_ESTIMACION, SET_CONCEPTO_PRECIOS } from "../types/estimaciones";

const getPrevious = (conceptos, conceptosExtra) => {
  let prevConceptos = conceptos;
  let prevExtras = conceptosExtra;
  if (prevConceptos === null || !prevConceptos) prevConceptos = [];
  if (prevExtras === null || !prevExtras) prevExtras = [];
  prevConceptos = JSON.parse(JSON.stringify([...prevConceptos]));
  prevExtras = JSON.parse(JSON.stringify([...prevExtras]));
  return { prevConceptos, prevExtras };
};

const conceptoSchema = (idPartidaContrato, num) => ({
  idPartidaContrato,
  idConcepto: `nuevo-${idPartidaContrato}-${num}`,
  clave: "",
  nombre: "",
  unidad: "",
  cantidad: "",
  precio_unitario: "",
});

const conceptoExtra = (num) => ({
  idPartidaContrato: "extra",
  idConceptoExtra: `nuevo-extra-${num}`,
  clave: "",
  nombre: "",
  unidad: "",
  cantidad: 0,
  precio_solicitado: 0,
  precio_autorizado: 0,
  importe: 0,
  extra: true,
});

const ConceptosReducer = (state, { type, payload }) => {
  switch (type) {
    case SELECT_ESTIMACION: {
      const conceptos = payload;
      let currentConceptos = Array.isArray(state.prevConceptos)
        ? state.prevConceptos
        : state.conceptos;
      //Initialize Array
      if (!Array.isArray(currentConceptos)) {
        currentConceptos = [];
      }
      //Deep clone of conceptos
      let conceptosEstimacion = JSON.parse(
        JSON.stringify([...currentConceptos])
      );
      //Append estimate information
      conceptosEstimacion = conceptosEstimacion.map((concepto) => {
        let conceptoEstimado = conceptos.find(
          (conceptoEst) =>
            parseInt(conceptoEst.idConcepto) === parseInt(concepto.idConcepto)
        );
        concepto = {
          ...concepto,
          estimadoAnterior:
            concepto.estimado !== null && concepto.estimadoAcumulado !== null
              ? parseFloat(concepto.estimadoAcumulado) -
                parseFloat(concepto.estimado)
              : concepto.estimado !== null
              ? concepto.estimado
              : 0,
          autorizadoAnterior:
            concepto.autorizado !== null &&
            concepto.autorizadoAcumulado !== null
              ? parseFloat(concepto.autorizadoAcumulado) -
                parseFloat(concepto.autorizado)
              : concepto.autorizado !== null
              ? concepto.autorizado
              : 0,
          supervisadoAnterior:
            concepto.supervisado !== null &&
            concepto.supervisadoAcumulado !== null
              ? parseFloat(concepto.supervisadoAcumulado) -
                parseFloat(concepto.supervisado)
              : concepto.supervisado !== null
              ? concepto.supervisado
              : 0,
          importeAnterior:
            concepto.importe_autorizado !== null
              ? concepto.importe_autorizado
              : 0,
        };
        if (conceptoEstimado) {
          concepto = {
            ...concepto,
            ...conceptoEstimado,
          };
        } else {
          concepto = {
            ...concepto,
            estimado: 0,
            autorizado: 0,
            supervisado: 0,
          };
        }
        if (
          !concepto.precio_autorizado ||
          concepto.precio_autorizado === null
        ) {
          if (conceptoEstimado) {
            if (
              conceptoEstimado.precio_autorizado &&
              conceptoEstimado.precio_autorizado !== null
            ) {
              concepto.precio_autorizado = conceptoEstimado.precio_autorizado;
            } else {
              concepto.precio_autorizado = concepto.precio;
            }
          } else {
            concepto.precio_autorizado = concepto.precio;
          }
        }
        return concepto;
      });
      return {
        ...state,
        conceptos: conceptosEstimacion,
        ...getPrevious(state.conceptos, state.conceptosExtra),
      };
    }
    case CONCEPTOS_RECIBIDOS:
      return { ...state, conceptos: payload, editMode: null, loading: false };
    case EDIT_CONCEPTOS:
      let { prevConceptos, prevPartidas } = getPrevious(
        state.partidas,
        state.conceptos
      );
      return {
        ...state,
        prevConceptos,
        prevPartidas,
        editMode: `conceptos-${payload}`,
      };
    case SET_PROPERTY_CONCEPTO:
      const { idConcepto, key, value } = payload;
      const conceptos = [...state.conceptos];
      const concepto = conceptos.find(
        (concepto) => String(concepto.idConcepto) === String(idConcepto)
      );
      if (concepto) concepto[key] = value;
      return { ...state, conceptos };
    case DELETE_CONCEPTO:
      let conceptos2 = [...state.conceptos];
      let idx = conceptos2.findIndex(
        (concepto) => String(concepto.idConcepto) === String(payload)
      );
      if (idx !== -1)
        conceptos2[idx].idConcepto = `delete-${conceptos2[idx].idConcepto}`;
      return { ...state, conceptos: conceptos2 };
    case CREATE_CONCEPTO:
      let conceptosPreciario = state.conceptos;
      if (conceptosPreciario === null) conceptosPreciario = [];
      let conceptosPartida = conceptosPreciario.filter(
        (concepto) => concepto.idPartidaContrato === payload
      );
      let num = conceptosPartida.length;
      return {
        ...state,
        conceptos: [...conceptosPreciario, conceptoSchema(payload, num)],
      };
    case SET_CONCEPTO_PRECIOS:
      return {
        ...state,
        concepto_precios: payload,
      };

    case EDIT_CONCEPTOS_ESTIMACION:
      let previous = getPrevious(
        state.partidas,
        state.conceptos,
        state.conceptosExtra
      );
      return {
        ...state,
        editMode: "conceptos-estimacion",
        prevPartidas: previous.prevPartidas,
        prevConceptos: previous.prevConceptos,
        prevConceptosExtras: previous.prevExtras,
      };
    case CREATE_CONCEPTO_EXTRA:
      let conceptosExtra = state.conceptosExtra;
      if (conceptosExtra === null || !conceptosExtra) conceptosExtra = [];
      let number = conceptosExtra.length;
      return {
        ...state,
        conceptosExtra: [...conceptosExtra, conceptoExtra(number)],
      };
    case DELETE_CONCEPTO_EXTRA:
      let conceptsExtras = [...state.conceptosExtra];
      let idxExtra = conceptsExtras.findIndex(
        (concepto) => concepto.idConceptoExtra === payload
      );
      if (idxExtra !== -1)
        conceptsExtras[
          idxExtra
        ].idConceptoExtra = `delete-${conceptsExtras[idxExtra].idConceptoExtra}`;
      return { ...state, conceptosExtra: conceptsExtras };
    default:
      return { ...state };
  }
};
export default ConceptosReducer;

import React, { useContext, useEffect, useState } from "react";
import { RevisionesContext } from "../../context/RevisionesContext";
import { ModalContext } from "../../context/ModalContext";
import moment from "moment";


const RevisionForm = ({
  idChecklist,
  idProyecto,
  handleCancel
}) => {

  const [revisionNumber, setRevisionNumber] = useState(0);
  const [revisionDate, setRevisionDate] = useState('');
  const { postRevision, revisiones } = useContext(RevisionesContext);

  const { clearModal } = useContext(ModalContext);

  const todayDate = moment().format('YYYY-MM-DD');

  useEffect(() => {
    setRevisionDate(todayDate);
  }, []);

  useEffect(() => {
    handleRevisionNumber();
  }, [revisiones]);  

  const handleRevisionNumber = () => {
    const currentRevision = revisiones.length + 1;
    setRevisionNumber(currentRevision);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    postRevision({
      numero: revisionNumber,
      fecha: revisionDate,
      idChecklist: parseInt(idChecklist),
      idProyecto:  parseInt(idProyecto)
    })

    handleCancel();
  };

  const handleChangeDate = (e) => {
    setRevisionDate(e.target.value);
  }

  return (
    <form onSubmit={onSubmit} className="container-fluid">

      <div className="d-flex flex-column mt-4">

        <div className="mb-5 col-8">
          <h5 className=" ">Número de Revision: <b>{revisionNumber}</b></h5>
        </div>

        <div className="col-8">
          <label className="mb-2 ">Fecha de Revision</label>
          <input
            type="date"
            className="form-control mb-4"
            value={revisionDate}
            onChange={handleChangeDate}
            placeholder="Revision de Obra"
          />
        </div>
        
      </div>


      <div className="d-flex justify-content-end mt-3">
        <button 
          className="btn" 
          type="button" 
          onClick={() => {
            handleCancel();
            clearModal();
          }}
        >
          CANCELAR
        </button>
        <button className="btn btn-primary">
          GENERAR <i className="fa fa-save"></i> 
        </button>
      </div>
    </form>
  );
};

export default RevisionForm;

import React, { useContext, useEffect } from "react";
import { UsuariosContext } from "../../context/UsuariosContext";
import { PermisosContext } from "../../context/PermisosContext";
import { ClientesContext } from "../../context/ClientesContext";
import { getValue } from "../../utils";
import { ModalContext } from "../../context/ModalContext";

const UsuarioClienteForm = ({ idCliente, handleCallback }) => {
  const { permisos, getPermisos } = useContext(PermisosContext);
  const { clearModal } = useContext(ModalContext);
  const { createUsuarioCliente } = useContext(ClientesContext);
  const { users, usuario, getUsers, setPropiedadUsuario } =
    useContext(UsuariosContext);

  useEffect(() => {
    getPermisos();
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usuario !== null) {
      handleUsuario();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario]);

  function handleUsuario() {
    if (!usuario.idUsuario && Array.isArray(users)) {
      if (users.length > 0) {
        setPropiedadUsuario("idUsuario", users[0].idUsuario);
      }
    }
    if (!usuario.idPermiso) {
      setPropiedadUsuario("idPermiso", 1);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    createUsuarioCliente({ ...usuario, idCliente }, handleCallback);
  }

  function renderUsers() {
    if (users) {
      return users.map((user) => (
        <option key={user.idUsuario} value={user.idUsuario}>
          {user.nombre}
          {" : "}
          {user.correo}
        </option>
      ));
    }
  }

  function renderPermisos() {
    if (Array.isArray(permisos)) {
      return permisos.map((permiso) => (
        <option key={permiso.nombre} value={permiso.idPermiso}>
          {permiso.nombre}
        </option>
      ));
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="container">
          <label>Usuario</label>
          <select
            className="form-control mb-3"
            value={getValue(usuario, "idUsuario")}
            onChange={(e) => setPropiedadUsuario("idUsuario", e.target.value)}
          >
            {renderUsers()}
          </select>
        </div>
      </div>
      <div className="row pt-3">
        <div className="container">
          <label>Permiso</label>
          <select
            className="form-control mb-3"
            value={getValue(usuario, "idPermiso")}
            onChange={(e) => setPropiedadUsuario("idPermiso", e.target.value)}
          >
            {renderPermisos()}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={clearModal}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default UsuarioClienteForm;

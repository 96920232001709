import React, { useState, useContext, useEffect } from "react";
import { PermisosContext } from "../context/PermisosContext";
import PermisoForm from "../components/permisos/PermisoForm";
import { ModalContext } from "../context/ModalContext";
import TabOptions from "../components/common/TabOptions"
import { CapacitorContext } from "../context/CapacitorContext";
import { MenuContext } from "../context/MenuContext";
import { UsuariosContext } from "../context/UsuariosContext";
import PermissionsList from "../components/permisos/PermissionsList";
import MobileSubHeader from "../components/movil/MobileSubHeader";
import { isCoordinador } from "../utils/permisos";
import { AuthContext } from "../context/AuthContext";
import MobileModal from "../components/movil/MobileModal";

const Permisos = () => {
  const [query, setQuery] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [permiso, setPermiso] = useState(null);

  const [filteredPermissions, setFilteredPermissions] = useState("");

  const { platform } = useContext(CapacitorContext);

  const [isTableView, setIsTableView] = useState(platform === "web");

  const { selectTabs, setSelected } = useContext(MenuContext);
  const { confirm, clearModal, modalComponent } = useContext(ModalContext);
  const { users, getUsers } = useContext(UsuariosContext);
  const { user } = useContext(AuthContext);

  
  const {
    editPermiso,
    deletePermiso,
    createPermiso,
    permisos_proyecto,
    getPermisosProyectos,
  } = useContext(PermisosContext);

  useEffect(() => {
    selectTabs();
    getUsers();
    getPermisosProyectos();
    setSelected({ name: "permisos" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterPermissions();
  }, [query]);

  useEffect(() => {
    const newPermissions = getUsersPhotos();
    setPermissions(newPermissions);
  }, [permisos_proyecto, users]);

  const getUsersPhotos = () => {
    const permissionsWithUserPhoto = permisos_proyecto?.map(item => {
      const userData = users?.find(user => {
        return user.idUsuario === item.idUsuario
      });

      return {
        ...item,
        idAdjunto: userData?.idAdjunto,
        tipo_adjunto: userData?.tipo_adjunto
      }
    });

    return permissionsWithUserPhoto;
  }

  const handleCancel = () => {
    editPermiso(null);
    clearModal();
  };

  const handleCreate = () => {
    clearModal();
    createPermiso();

    if(platform === 'web') {
      modalComponent(
        "Agregar Permiso",
        <PermisoForm handleCancel={handleCancel} />
      );
    } else {
      setShowCreateModal(true);
    }

  };

  const handleEdit = (permiso) => {
    clearModal();
    editPermiso(permiso);

    if(platform === 'web') {
      modalComponent(
        "Editar Permiso",
        <PermisoForm handleCancel={handleCancel} />
      );
    } else {
      setShowCreateModal(true);
    }
    
  };

  const handleDelete = (permiso) => {
    setPermiso(permiso);
    clearModal();

    if(platform === 'web') {
      confirm(
        `¿Estás seguro que deseas eliminar el permiso de ${permiso.nombre_usuario} para el proyecto ${permiso.nombre_proyecto}?`,
        () => {
          deletePermiso(
            permiso.idUsuario,
            permiso.idProyecto,
            getPermisosProyectos
          );
        }
      );
    } else {
      setShowDelete(true);
    }
    
  };

  const filterPermissions = () => {
    if (query !== "") {
      const currentQuery = String(query).toLowerCase();
      const newFilteredPermissions = permissions.filter(
        ({ nombre_usuario, nombre_proyecto, correo }) =>
          String(nombre_usuario).toLowerCase().includes(currentQuery) ||
          String(nombre_proyecto).toLowerCase().includes(currentQuery) ||
          String(correo).toLowerCase().includes(currentQuery)
      );

      setFilteredPermissions(newFilteredPermissions);
    }
  }

  const renderPermissionsList = () => {
    let currentPermissions = permissions;

    if(query.length > 0) {
      currentPermissions = filteredPermissions;
    } 

    return(
      <PermissionsList
        permissions={currentPermissions}
        query={query}
        platform={platform}
        isTableView={isTableView}
        editPermission={handleEdit}
        deletePermission={handleDelete}
      />
    )
  }

  const renderAddBtn = () => {
    if(isCoordinador(user));
    return(
      <div className="text-end">
        <button 
          className="btn btn-primary" 
          onClick={handleCreate}
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>
    )
  }

  const renderMobileHeader = () => {
    if(platform !== 'web') {
      return(
        <MobileSubHeader 
        title="Permisos"
        >
          {renderAddBtn()}
        </MobileSubHeader>
      )
    }
  }

  return (
    <div className="container-fluid mb-4 pb-3 view-container">
      {renderMobileHeader()}

      <div className="row align-items-start" >
        <div className="col-12 show-mobile " style={{height: 'max-content'}}>
          <input
            type="text"
            value={query}
            className="form-control bg-white"
            placeholder="Buscar permiso..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        <div className="col-12 px-0">
          <TabOptions
            query={query}
            setQuery={setQuery}
            isTableView={isTableView}
            handleCreate={handleCreate}
            setIsTableView={setIsTableView}
            buttonCreateText={'+ NUEVO PERMISO'}
            searchInputPlaceholder={'Buscar permisos...'}
          />

          <div className="row">
            {renderPermissionsList()}
          </div>
        </div>
      </div>

      <MobileModal
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
      >
        <PermisoForm handleCancel={() => setShowCreateModal(false)} />
      </MobileModal>

      <MobileModal
          showModal={showDelete}
          setShowModal={setShowDelete}
        >

          <h3>Precaución</h3>
          <p>
            ¿Estás seguro que deseas eliminar el permiso de {permiso?.nombre_usuario} 
            para el proyecto {permiso?.nombre_proyecto}?
          </p>

          <div className="row text-end">
            <div className="col-6">
              <button 
                className="w-100 btn btn-light"
                onClick={() => setShowDelete(false)}
              >
                Cancelar
              </button>
            </div>

            <div className="col-6">
              <button 
                className="w-100 btn btn-danger"
                onClick={() => {
                  deletePermiso(
                    permiso?.idUsuario,
                    permiso?.idProyecto,
                    getPermisosProyectos
                  );
                }}
              >
                Eliminar
              </button>
            </div>
          </div>

        </MobileModal>
    </div>
  );
};

export default Permisos;

import React, { useContext, useEffect, useState } from "react";
import PlantillaFormChecklist from "../components/plantillas/PlantillaFormChecklist";
import CrearChecklistForm from "../components/movil/CrearChecklistForm";
import ChecklistCuadro from "../components/checklists/ChecklistCuadro";
import ChecklistCard from "../components/checklists/ChecklistCard";
import ChecklistForm from "../components/checklists/ChecklistForm";
import { CapacitorContext } from "../context/CapacitorContext";
import { ChecklistContext } from "../context/ChecklistContext";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";
import MobileSubHeader from "../components/movil/MobileSubHeader";
import { ProyectosContext } from "../context/ProyectosContext";

const Checklists = ({ idProyecto }) => {
  const [showModal, setShowModal] = useState(false);

  const {
    checklist,
    checklists,
    editChecklist,
    deleteChecklist,
    createChecklist,
    getChecklistsProyecto,
  } = useContext(ChecklistContext);

  const {
    proyecto
  } = useContext(ProyectosContext);

  const { clearModal, modalComponent } = useContext(ModalContext);

  const { platform } = useContext(CapacitorContext);

  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected({ name: "checklists" });
    getChecklistsProyecto(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    clearModal();
    getChecklistsProyecto(idProyecto);
  };

  const handlePlantilla = () => {
    if (platform === "web") {
      modalComponent("", <PlantillaFormChecklist idProyecto={idProyecto} />);
    } else {
      setShowModal(true);
    }
  };

  const handleCreateChecklist = () => {
    createChecklist();
    if (platform === "web") {
      modalComponent(
        "Crear Checklist",
        <ChecklistForm idProyecto={idProyecto} handleCancel={handleCancel} />
      );
    } else {
      setShowModal(true);
    }
  };

  const handleEditChecklist = (checklist) => {
    editChecklist(checklist);
    if (platform === "web") {
      modalComponent(
        "Editar Checklist",
        <ChecklistForm idProyecto={idProyecto} handleCancel={handleCancel} />
      );
    } else {
      setShowModal(true);
    }
  };

  const handleDelete = (checklist) => {
    modalComponent(
      "Precaución",
      <div>
        <p>
          ¿Estás seguro que deseas eliminar el checklist {checklist.nombre}?
          Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteChecklist(idProyecto, checklist.idChecklist)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderChecklists = () => {
    if (Array.isArray(checklists)) {
      if (checklists.length === 0) {
        return (
          <p className="mb-0 py-2">
            No hay checklists registrados para este proyecto.
          </p>
        );
      }
      // if (platform === "web") {
        return checklists.map((checklist) => (
          <div className="col-12 col-md-6 col-lg-4" key={checklist.idChecklist}>
            <div className="card shadow hover-light py-2 px-0">
              <ChecklistCard
                
                checklist={checklist}
                handleDelete={handleDelete}
                handleEdit={handleEditChecklist}
              />
            </div>
          </div>
        ));
      // }
      // return checklists.map((checklist) => (
      //   <div className="col-12" key={checklist.idChecklist}>
      //     <ChecklistCuadro
      //       checklist={checklist}
      //       deleteChecklist={handleDelete}
      //       editChecklist={handleEditChecklist}
      //     />
      //   </div>
        
      // ));
    }
  };

  const renderTable = () => {
    if (platform === "web") {
      return (
        <div className="container-fluid px-0">
          <div className="row align-items-center mb-3">
            <div className="col-12 col-md-6"></div>
            <div className="col-12 col-md-6 text-end px-3">
              <button
                className="btn me-2 btn-outline-primary"
                onClick={handleCreateChecklist}
              >
                + Agregar Checklist
              </button>
            </div>
          </div>
          <div className="row">{renderChecklists()}</div>
        </div>
      );
    }
    return (
      <div className="container-fluid px-0">
        <MobileSubHeader
          title='Checklists'
        >
          <div className="text-end col-12 px-0">
            <button onClick={handleCreateChecklist} className="btn btn-primary">
              <i className="fa fa-plus"></i>
            </button>
          </div>
          
        </MobileSubHeader>
        <div className="row ">
          {renderChecklists()}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    if (checklist && checklist !== null) {
      return (
        <CrearChecklistForm
          showModal={showModal}
          setShowModal={setShowModal}
          idProyecto={idProyecto}
          handleCancel={handleCancel}
        />
      );
    }
  };

  return (
    <div>
      {renderTable()}
      {renderForm()}
    </div>
  );
};

export default Checklists;

import React, { useContext } from "react";
import { formatMonto } from "../../utils";
import ConceptoEstimacion from "./ConceptoEstimacion";
import ConceptoExtraAutorizado from "./ConceptoExtraAutorizado";
import { ConceptosContext } from "../../context/ConceptosContext";
import { PreciarioContext } from "../../context/PreciarioContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { AuthContext } from "../../context/AuthContext";

const ConceptoExtra = ({ concepto, showEstimaciones }) => {
  const { setPropiedadConcepto } = useContext(ConceptosContext);
  const { editMode } = useContext(PreciarioContext);
  const { proyecto } = useContext(ProyectosContext);
  const { user } = useContext(AuthContext);

  const permiso = proyecto !== null ? proyecto.permiso : user.permiso;

  function renderEstimacion() {
    if (showEstimaciones) {
      if (
        editMode === "conceptos-estimacion" &&
        ["finanzas", "obras"].includes(permiso)
      )
        return (
          <ConceptoExtraAutorizado
            concepto={concepto}
            modifier={setPropiedadConcepto}
            permiso={permiso}
          />
        );
      return <ConceptoEstimacion concepto={concepto} extra />;
    }
  }

  const {
    clave,
    nombre,
    unidad,
    cantidad,
    importe,
    precio_autorizado,
    precio_solicitado,
  } = concepto;

  return (
    <tr className={`concepto-row concepto-extra`}>
      <td>Extra</td>
      <td>{clave}</td>
      <td>{nombre}</td>
      <td>{unidad}</td>
      <td>{cantidad}</td>
      <td>
        {"$"}
        {formatMonto(precio_solicitado)}
      </td>
      <td>
        {"$"}
        {formatMonto(precio_autorizado)}
      </td>
      <td>
        {"$"}
        {formatMonto(importe)}
      </td>
      {renderEstimacion()}
    </tr>
  );
};

export default ConceptoExtra;

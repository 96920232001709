import React from "react";
import { formatMonto } from "../../utils";

const ConceptoExtraAutorizado = ({ concepto, modifier, permiso }) => {
  const { idConcepto, autorizado, precio_autorizado } = concepto;
  return (
    <>
      <td />
      <td>
        {permiso === "obras" && (
          <input
            min={0}
            type="number"
            value={autorizado}
            className="form-control"
            onChange={(e) => modifier(idConcepto, "autorizado", e.target.value)}
          />
        )}
        {permiso !== "obras" && autorizado !== undefined ? autorizado : ""}
      </td>
      <td>
        {permiso === "finanzas" && (
          <input
            min={0}
            type="number"
            value={precio_autorizado}
            className="form-control"
            onChange={(e) =>
              modifier(idConcepto, "precio_autorizado", precio_autorizado)
            }
          />
        )}
        {permiso !== "finanzas" && formatMonto(precio_autorizado)}
      </td>
      <td />
      <td />
      <td />
      <td />
      <td />
    </>
  );
};

export default ConceptoExtraAutorizado;

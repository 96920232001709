import React from "react";
import { formatMonto } from "../../utils";

const ConceptoExtraForm = ({ concepto, modifier, deleteConceptoExtra }) => {
  const { idConcepto, clave, nombre, unidad, cantidad, precio_solicitado } =
    concepto;

  return (
    <tr>
      <td>Extra</td>
      <td>
        <input
          type="text"
          value={clave}
          maxLength={140}
          className="form-control"
          onChange={(e) => modifier(idConcepto, "clave", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          value={nombre}
          maxLength={140}
          className="form-control"
          onChange={(e) => modifier(idConcepto, "nombre", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          value={unidad}
          maxLength={140}
          className="form-control"
          onChange={(e) => modifier(idConcepto, "unidad", e.target.value)}
        />
      </td>
      <td>
        <input
          min={0}
          type="number"
          value={cantidad}
          className="form-control"
          onChange={(e) => modifier(idConcepto, "cantidad", e.target.value)}
        />
      </td>
      <td>
        <input
          min={0}
          type="number"
          value={precio_solicitado}
          className="form-control"
          onChange={(e) =>
            modifier(idConcepto, "precio_solicitado", e.target.value)
          }
        />
      </td>
      <td>-</td>
      <td>
        {"$"}
        {formatMonto(cantidad * precio_solicitado)}
      </td>
      <td>
        <button
          className="btn btn-outline-danger"
          onClick={() => deleteConceptoExtra(idConcepto)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default ConceptoExtraForm;

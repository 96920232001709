import React, { useContext, useEffect, useState } from "react";
import {
  IonModal,
  IonContent,
  IonGrid,
  IonRow,
} from "@ionic/react";
import IconPickerModal from "../picker/IconPickerModal"; // Importa el componente IconPickerModal

const MobileModal = ({ showModal, setShowModal, children }) => {
  const [showIconModal, setShowIconModal] = useState(false);
 
  return (
    <IonModal
      isOpen={showModal}
      onDidDismiss={() => setShowModal(false)}
      initialBreakpoint={0.5}
      breakpoints={[0.3, 0.8]}
    >
      <IonContent className="py-4 ps-4">
        <IonGrid>
          <div className="p-3">
            {children}
          </div>
        </IonGrid>
      </IonContent>
      <IconPickerModal
        isOpen={showIconModal}
        onDismiss={() => setShowIconModal(false)}
      />
    </IonModal>
  );
};

export default MobileModal;

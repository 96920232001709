import React, { useContext, useEffect, useState } from "react";
import { ClientesContext } from "../../context/ClientesContext";
import ImageInput from "../common/ImageInput";
import { AppConfigContext } from "../../context/AppConfigContext";
import AdjuntosService from "../../services/AdjuntosService";

const ClienteForm = ({ handleCancel }) => {
  const { S3_ENDPOINT } = useContext(AppConfigContext);

  const [inputColor, setInputColor] = useState('fff');

  
  const { cliente, postCliente, setPropiedadCliente } =
    useContext(ClientesContext);

  const handleChangeColor = (e) => {
    setInputColor(e.target.value);
    setPropiedadCliente("color", e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (cliente.file && cliente.file !== null) {
      let formData = AdjuntosService.getFormData(cliente.file);

      AdjuntosService.postAdjunto(formData).then((res) => {
        const { idAdjunto } = res.data;
        postCliente({ ...cliente, idAdjunto });
      });

    } else {
      postCliente(cliente);
    }
  };

  const getSrc = () => {
    if (cliente?.Adjunto !== null && cliente?.Adjunto) {
      return `${S3_ENDPOINT}/files/adjuntos/${cliente.Adjunto.idAdjunto}.${cliente.Adjunto.tipo}`;
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <p className="mb-4 subtitlePlantilla">Ingrese la siguiente información</p>

      <div className="d-flex mb-5">
        <div className="col-5 me-5">
          <label className="mb-2 titleInputPlantilla">NOMBRE</label>
          <input
            type="text"
            value={cliente?.nombre}
            className="form-control mb-3 border"
            onChange={(e) => setPropiedadCliente("nombre", e.target.value)}
          />

          <div className="col-5">
            <label className="mb-2 titleInputPlantilla">COLOR</label>
            <div className="d-flex align-items-center mb-3">
              {/* <input
                type="text"
                value={cliente.color}
                className="form-control"
                readOnly
                style={{
                  cursor: "pointer",
                  marginRight: "0px",
                }}
                onClick={(e) => document.getElementById("colorPicker").click()}
              /> */}
              <div
                style={{
                  width: 37,
                  height: 37,
                  borderRadius: "3px",
                  backgroundColor: cliente?.color,
                  border: "1px solid #ced4da",
                  cursor: "pointer",
                }}
                onClick={(e) => document.getElementById("colorPicker").click()}
              ></div>
              <input
                type="color"
                id="colorPicker"
                value={inputColor}
                className="d-none"
                onChange={handleChangeColor}
              />
            </div>
          </div>
        </div>
        
        <div className="col-5">
          <label className="mb-2 titleInputPlantilla">FOTO</label>
          <ImageInput
            value={getSrc()}
            modifier={(file) => setPropiedadCliente("file", file)}
          />
        </div>
      </div>

      <div className="col-5  m-auto me-4 mb-4 end-0 bottom-0">
        <div className="d-flex justify-content-end mt-4">
          <button className="btn" type="button" onClick={handleCancel}>
            CANCELAR
          </button>
          <button className="btn btn-primary">
            <i className="fa fa-save"></i> GUARDAR
          </button>
        </div>
      </div>
    </form>
  );
};

export default ClienteForm;

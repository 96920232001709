import React from "react";
import ProveedoresHandler from "../../utils/proveedores/ProveedoresHandler";
import { Link } from "react-router-dom";

const ProveedorRow = ({ proveedor }) => {
  const { handleDeleteProveedor, handleEditProveedor } = ProveedoresHandler();
  const renderActionButtons = () => {
    return (
      <>
        <button
          className="btn me-2"
          onClick={() => handleEditProveedor(proveedor)}
        >
          <i className="fa fa-edit"></i>
        </button>
        <button
          className="btn"
          onClick={() => handleDeleteProveedor(proveedor.idProveedor)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </>
    );
  };

  return (
    <tr style={{ textAlign: "start" }}>
      <td>{proveedor.idProveedor}</td>
      <td>
        <Link to={`${proveedor.idProveedor}`}>
          <p
            className="mb-0 bold text-primary"
            style={{
              textDecoration: "underline",
            }}
          >
            {proveedor.nombre}
          </p>
        </Link>
      </td>
      <td>{proveedor.contact}</td>
      <td>{proveedor.email}</td>
      <td>{proveedor.numeroTelefono}</td>
      <td>{proveedor.domicilio}</td>
      <td>{proveedor.codigoPostal}</td>
      <td>{proveedor.RFC}</td>
      <td style={{ textAlign: "center" }}>{renderActionButtons()}</td>
    </tr>
  );
};

export default ProveedorRow;

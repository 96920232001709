import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { MenuContext } from "../context/MenuContext";
import { ModalContext } from "../context/ModalContext";
import ObraCard from "../components/proyectos/ObraCard";
import ObraForm from "../components/proyectos/ObraForm";
import { ProyectosContext } from "../context/ProyectosContext";
import ObrasFilters from "../components/proyectos/ObrasFilters";
import { setupColor } from "../utils";
import MobileSubHeader from "../components/movil/MobileSubHeader";
import { CapacitorContext } from "../context/CapacitorContext";
import { AppConfigContext } from "../context/AppConfigContext";
import { isCoordinador } from "../utils/permisos";
import MobileModal from "../components/movil/MobileModal";

const Obras = () => {
  const [filter, setFilter] = useState(null);
  const [query, setQuery] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentObra, setCurrentObra] = useState(null);

  const {
    options,
    proyecto,
    postObra,
    proyectos,
    hideOptions,
    viewOptions,
    getProyectos,
    editProyecto,
    deleteProyecto,
    createProyecto,
    clearProyectos,
    clearSingleProyecto,
  } = useContext(ProyectosContext);

  const { user } = useContext(AuthContext);

  const { primary } = useContext(AppConfigContext);

  const { platform } = useContext(CapacitorContext);

  const { selectTabs, setSelected } = useContext(MenuContext);

  const { confirm, clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    selectTabs();
    getProyectos();
    setSelected({ name: "obras" });
    return clearProyectos;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!proyecto || proyecto === null) {
      setupColor(primary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proyecto]);

  const handleDelete = (proyecto) => {

    if(platform === 'web') {
      confirm(
        <p>
          ¿Estás seguro que deseas eliminar el proyecto {proyecto.nombre}? Esta
          acción NO puede deshacerse.
        </p>,
        () => deleteProyecto(proyecto.idProyecto)
      );
    } else {
      setCurrentObra(proyecto);
      setShowDelete(true);
    }
   
  };

  const handleCancel = () => {
    clearModal();
    clearSingleProyecto();
  };

  const handleEdit = (proyecto) => {
    editProyecto(proyecto);

    if(platform === 'web') {
      modalComponent("Editar Proyecto", <ObraForm handleCancel={handleCancel} />);
    } else {
      setShowCreateModal(true);
    }
  };

  const handleCreate = () => {
    createProyecto();

    if(platform === 'web') {
      modalComponent(
        "Agregar Proyecto",
        <ObraForm handleCancel={handleCancel} />
      );
    } else {
      setShowCreateModal(true);
    }
    
  };

  const renderProyectos = () => {
    if (Array.isArray(proyectos)) {
      if (proyectos.length === 0) {
        return <p>Aún no tienes proyectos completadas</p>;
      }
      let proyectosRender = proyectos;
      if (filter !== null) {
        proyectosRender = proyectosRender.filter((proyecto) =>
          filter === "activas"
            ? parseInt(proyecto.real) < 1
            : parseInt(proyecto.real) === 1
        );
      }
      if (query && query !== "") {
        proyectosRender = proyectosRender.filter(({ nombre, calle }) => {
          nombre = String(nombre).toLowerCase();
          calle = String(calle).toLowerCase();
          const currentQuery = String(query).toLowerCase();
          return (
            nombre.includes(currentQuery) ||
            nombre.startsWith(currentQuery) ||
            calle.includes(currentQuery) ||
            calle.startsWith(currentQuery)
          );
        });
      }
      if (proyectosRender.length === 0) {
        return (
          <p className="mx-3 px-1">
            No se encontraron proyectos que coincidan con tu búsqueda.
          </p>
        );
      }
      return proyectosRender.map((proyecto) => (
        <div key={proyecto.idProyecto} className="col-sm-12 col-lg-6 col-xl-4">
          <ObraCard
            key={`proyecto-${proyecto.idProyecto}`}
            user={user}
            options={options}
            postObra={postObra}
            proyecto={proyecto}
            handleEdit={handleEdit}
            viewOptions={viewOptions}
            hideOptions={hideOptions}
            handleDelete={handleDelete}
          />
        </div>
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div 
      className="container-fluid view-container px-0"
      style={{
        overflowX: 'hidden',
        height: platform !== 'web' ? 'calc(100vh - 145px)' : 'calc(100vh - 80px)'
      }}
    >
      <MobileSubHeader title="Obras">
        <div className="col-12 col-md-6 text-end px-0 ">
          <button className="btn btn-primary" onClick={handleCreate}>
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </MobileSubHeader>

      <ObrasFilters
        query={query}
        filter={filter}
        setQuery={setQuery}
        setFilter={setFilter}
        handleCreate={handleCreate}
      />

      <div className="container-fluid px-0 mb-3 position-relative">
        <div className="row">{renderProyectos()}</div>
      </div>

      <MobileModal
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
      >
        <ObraForm handleCancel={() => setShowCreateModal(false)}/>
      </MobileModal>

      <MobileModal
        showModal={showDelete}
        setShowModal={setShowDelete}
      >
        <h3>Precaución</h3>
        <p>
          ¿Estás seguro que deseas eliminar el proyecto {currentObra?.nombre}? Esta
          acción NO puede deshacerse.
        </p>

        <div className="row text-end">
          <div className="col-6">
            <button 
              className="w-100 btn btn-light"
              onClick={() => setShowDelete(false)}
            >
              Cancelar
            </button>
          </div>

          <div className="col-6">
            <button 
              className="w-100 btn btn-danger"
              onClick={() => deleteProyecto(currentObra?.idProyecto)}
            >
              Eliminar
            </button>
          </div>
        </div>

      </MobileModal>
    </div>
  );
};

export default Obras;

import React, { useContext, useEffect } from "react";
import { ProyectosContext } from "../context/ProyectosContext";
import { MenuContext } from "../context/MenuContext";
import Folder from "./Folder";

const Supervision = ({ idProyecto }) => {
  const { setSelected } = useContext(MenuContext);

  const { getSingleProyecto } = useContext(ProyectosContext);

  useEffect(() => {
    getSingleProyecto(idProyecto);
    setSelected({ name: "supervision" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  return (
    <Folder
      title="Supervisión"
      idFolder="supervision"
      idProyecto={idProyecto}
    />
  );
};

export default Supervision;

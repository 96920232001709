import React, { useContext, useEffect } from "react";
import { PartidasContext } from "../../context/PartidasContext";

const PartidaPreciarioForm = ({
  partida,
  modifier,
  idProyecto,
  deletePartida,
}) => {
  const { partidas, getPartidasProyecto } = useContext(PartidasContext);
  const { idPartidaContrato, idPartida, clave, nombre } = partida;

  useEffect(() => {
    if (!Array.isArray(partidas)) {
      getPartidasProyecto(idProyecto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPartidas = () => {
    if (Array.isArray(partidas)) {
      return [
        <option value={null} key="null">
          Sin Cronograma
        </option>,
        <option value="true">Crear Nueva Partida</option>,
        ...partidas.map((partida) => (
          <option key={partida.idPartida} value={partida.idPartida}>
            {partida.nombre}
          </option>
        )),
      ];
    }
  };

  return (
    <tr>
      <td>
        <input
          type="text"
          value={clave}
          maxLength={140}
          className="form-control"
          onChange={(e) => modifier(idPartidaContrato, "clave", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          value={nombre}
          maxLength={140}
          className="form-control"
          onChange={(e) =>
            modifier(idPartidaContrato, "nombre", e.target.value)
          }
        />
      </td>
      <td>
        <select
          value={idPartida}
          className="form-control"
          onChange={(e) => {
            const { value } = e.target;
            if (isNaN(parseInt(value))) {
              modifier(idPartidaContrato, "idPartida", null);
              modifier(idPartidaContrato, "cronograma", e.target.value);
            } else {
              modifier(idPartidaContrato, "cronograma", true);
              modifier(idPartidaContrato, "idPartida", e.target.value);
            }
          }}
        >
          {renderPartidas()}
        </select>
      </td>
      <td>
        <button
          className="btn btn-outline-danger"
          onClick={() => deletePartida(idPartidaContrato)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default PartidaPreciarioForm;

import React, { useEffect, useState, useRef } from "react";
import Mensaje from "./Mensaje";

const Entradas = ({ max, height, entradas, idProyecto, scrollCallback }) => {
  const refContainer = useRef();
  const [reachedTop, setReachedTop] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (Array.isArray(entradas) && firstLoad) {
      const container = refContainer.current;
      refContainer.current.scrollTo({
        top: container.offsetHeight * 10,
      });
      setFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entradas]);

  const renderEntradas = () => {
    if (Array.isArray(entradas)) {
      if (entradas.length === 0) {
        return (
          <div className="row">
            <p className="px-0" style={{ color: "#acacac" }}>
              No hay comentarios creados
            </p>
          </div>
        );
      }
      let previous;
      let follow;
      let fechaAnterior;
      return entradas.map((entrada) => {
        if (previous) {
          fechaAnterior = previous.createdAt;
          follow = previous.idUsuario === entrada.idUsuario;
        }
        previous = entrada;
        return (
          <Mensaje
            key={`mensaje-${entrada.idEntrada}`}
            idProyecto={idProyecto}
            fechaAnterior={fechaAnterior}
            entrada={entrada}
            follow={follow}
          />
        );
      });
    }
    return <div className="spinner-border"></div>;
  };

  const handleScroll = () => {
    const container = refContainer.current;
    const scrollTop = container.scrollTop;
    if (scrollTop === 0) {
      if (entradas.length < max) {
        let offset = entradas.length;
        if (typeof scrollCallback === "function") {
          scrollCallback(offset);
        }
      } else {
        setReachedTop(true);
      }
    }
  };

  return (
    <div
      id="entradas"
      ref={refContainer}
      onScroll={handleScroll}
      className="container-fluid h-100"
    >
      {reachedTop && (
        <p className="text-center">No hay entradas más antiguas.</p>
      )}
      {renderEntradas()}
    </div>
  );
};

export default Entradas;

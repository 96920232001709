import React, { useContext } from "react";
import ColorLegend from "../common/ColorLegend";
import { colors } from "../../utils/colors";
import { hasNotch } from "../../utils/ionic";
import { CapacitorContext } from "../../context/CapacitorContext";
import { AuthContext } from "../../context/AuthContext";
import { isCoordinador } from "../../utils/permisos";

const ObrasFilters = ({ query, filter, setFilter, setQuery, handleCreate }) => {
  const { user } = useContext(AuthContext);
  const { device, platform, isMobileDevice } = useContext(CapacitorContext);


  const renderCreateBtn = () => {
    if(isCoordinador(user) ) {
      return(
        <div className="col-12 col-md-6 text-end hide-mobile">
          <button className="btn btn-primary" onClick={handleCreate}>
            <i className="fa fa-plus"></i>
          </button>
        </div>
      ) 
    }
  }


  return (
    <div className="container-fluid px-0">
      <div
        // style={{ display: hasNotch(device) ? "flex" : "flex" }}
        className="row align-items-center mb-2 mt-2"
      >
        <div className="col-12 col-md-6">
          <input
            type="text"
            value={query}
            className="form-control bg-white"
            placeholder="Buscar un proyecto..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        {renderCreateBtn()}
      </div>
      <div className="row align-items-center mb-2">
        <div className="col-12 col-lg-6">
          <div className="row align-items-center py-2 mx-0 obras-filters">
            <div className="col-4 px-1">
              <button
                className={`btn btn-tab w-100 ${
                  filter === null ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => setFilter(null)}
              >
                Todo
              </button>
            </div>
            <div className="col-4 px-1">
              <button
                className={`btn btn-tab w-100 ${
                  filter === "activas" ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => setFilter("activas")}
              >
                Activas
              </button>
            </div>
            <div className="col-4 px-1">
              <button
                className={`btn btn-tab w-100 ${
                  filter === "completadas"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() => setFilter("completadas")}
              >
                Completadas
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <ColorLegend colors={colors} block/>
        </div>
      </div>
    </div>
  );
};

export default ObrasFilters;

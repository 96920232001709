import api from "./api";

const route = "/revisiones";

export default {
  //General
  getRevisiones: (idChecklist, idProyecto) => api.get(`${route}/${idChecklist}/${idProyecto}`,),
  postRevision: (revision) => api.post(`${route}`, { ...revision }),
  addItemOnRevision: (item, revision, idProyecto) => api.post(`${route}/add-item`, { item, revision, idProyecto }),
  updateItemOnRevision: (idRevisionItem, data) => api.put(`${route}/revision-item/${idRevisionItem}`, { data }),
};

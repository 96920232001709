import React, { useState, useContext, useEffect } from "react";
import { OrdenesContext } from "../../context/OrdenesContext";
import { ModalContext } from "../../context/ModalContext";
import { ProveedoresContext } from "../../context/ProveedoresContext";
import SearchableSelect from "../global/SearchableSelect";

const ProductOrdenForm = ({ idProyecto, idProducto }) => {
    const { saveOrder } = useContext(OrdenesContext);
    const { clearModal } = useContext(ModalContext);
    const { proveedores, getAllProveedores } =
        useContext(ProveedoresContext);

    const [status, setStatus] = useState("solicitado");
    const [provider, setProvider] = useState();
    const [description, setDescription] = useState("");
    const [error, setError] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!provider) {
            setError(true);
        } else {
            setError(false);
            saveOrder(idProyecto, idProducto, { status, idProveedor: provider, description });
        }
    };

    const setSelectedProvider = (proveedor) => {
        setProvider(proveedor.value)
    }

    useEffect(() => {
        getAllProveedores()
    }, []);

    const getProveedoresOptions = () => {
        if (proveedores && proveedores.length > 0) {
            return <>
                <label>Proveedor:</label>
                <SearchableSelect className="form-control bg-light py-2"
                    items={proveedores}
                    itemText="nombre"
                    itemValue="idProveedor"
                    modifier={setSelectedProvider}
                    value={provider}
                />
            </>
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label>Status:</label>
                <select
                    className="form-control"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <option value="solicitado">Solicitado</option>
                    <option value="aprobado">Aprobado</option>
                    <option value="recibido">Recibido</option>
                </select>
            </div>
            <div className="mb-3">
                {getProveedoresOptions()}
                {error && <p className="invalid-feedback">Por favor selecciona un proveedor.</p>}
            </div>
            <div className="mb-3">
                <label>Descripción:</label>
                <textarea
                    className="form-control"
                    placeholder="borrador"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows="4"
                />
            </div>

            <div className="w-100 d-flex mt-4">
                <button className="btn me-1 w-50" onClick={clearModal}> Cancelar </button>
                <button
                    className="btn btn-primary ms-1 w-50"
                    type="submit"
                >
                    Agregar Orden
                </button>
            </div>

        </form>
    );
};

export default ProductOrdenForm;

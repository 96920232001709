import React, { useContext } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { MenuContext } from "../../context/MenuContext";
import { AuthContext } from "../../context/AuthContext";
import { Link, redirect, useNavigate } from "react-router-dom";
import { hasNotch } from "../../utils/ionic";
import UserItem from "../users/UserItem";

const MenuMobile = () => {
  const { tabs, selected } = useContext(MenuContext);
  const { user, signOut } = useContext(AuthContext);
  const { device, platform, isMobileDevice} = useContext(CapacitorContext);
  const { proyecto } = useContext(ProyectosContext);

  const navigate = useNavigate();
  const location = window.location;

  const renderHeader = () => {
    if (proyecto) {
      if (proyecto.idProyecto) {
        const selectedTab = tabs.find((tab) => tab.name === selected);
        let title = selected;
        if (selectedTab) {
          title = selectedTab.title;
        }
        return (
          <div className="row align-items-center">
            {title ? (
              <div className="px-2">
                <p className="h5 bold large mb-0 text-capitalize">{proyecto.nombre}</p>
              </div>
            ) : (
              <p className="h5 bold large mb-0">{proyecto.nombre}</p>
            )}
          </div>
        );
      }
    }
    if (user && user !== null) {
      return <UserItem user={user} handleSignOut={signOut}/>;
    }
  };

  const renderMenuButton = () => {
    if (platform === "web") {
      return (
        <button
          type="button"
          className="navbar-toggler ms-2"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      );
    }
  };

  const renderIcon = (tab) => {
    if (tab.icon) {
      return (
        <i 
          className={`${tab.icon} fs-6 me-2
          ${tab.name === selected ? "text-white" : "text-dark"}`} 
        />
      );
    }
  }

  const renderTabs = () => {
    if (tabs) {
      return tabs.map((tab) => (
        <Link 
          className="mt-2" 
          key={tab.name} 
          to={tab.link}
        >
          <button
            className={
              `btn btn-tab btn-${
              tab.name === selected ? "primary" : ""
              } mx-2 d-flex flex-row
              align-items-center px-1`
            }
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {renderIcon(tab)}
            {tab.title}
          </button>
        </Link>
      ));
    }
  };

  const renderHomeButton = () => {
    if (platform !== "web" && location.pathname.includes("obra")) {
      return (
        <button className="btn me-1" onClick={() => redirect("/")}>
          <i className="fa fa-home"></i>
        </button>
      );
    }
  };

  const renderAlertas = () => {
    /*if (showAlertasCard) {
      return (
        <div className="row bg-light text-dark">
          <div
            className="col pt-3"
            style={{ height: 200, overflowY: "scroll" }}
          >
            <Alertas idProyecto={idProyecto} />
          </div>
        </div>
      );
    }*/
  };

  return (
    <nav 
      className="navbar navbar-expand-lg navbar-light bg-white text-dark 
      fixed-top border-bottom px-0 show-mobile-flex"
      style={{
        height: "70px"
      }}
    >
      <div className="col-12 px-0 bg-white">
        <div
          style={{
            paddingTop: hasNotch(device) ? 50 : 0,
          }}
          className="row mx-0 align-items-center"
        >
          <div className="col-8 px-0">
            <div className="navbar-brand mx-0">{renderHeader()}</div>
          </div>

          <div className="col-4 text-end">
            {renderHomeButton()}
          
            <button 
              onClick={() => navigate('/alertas')}
              className="btn btn-light ms-2"
            >
              <i className="fa fa-bell"></i>
            </button>
            {renderMenuButton()}
          </div>
        </div>

        <div className="collapse navbar-collapse mw-100 bg-white" id="navbarNav">
          <nav className="navbar-nav me-auto text-start">
            {renderTabs()}
            <button
              className="btn btn-link text-start  mt-3 ps-00"
              onClick={signOut}
            >
              <i className="fas fa-sign-out-alt fa-flip-horizontal" /> Salir
            </button>
          </nav>
        </div>
        {/* {renderAlertas()} */}
      </div>
    </nav>
  )
};

export default MenuMobile;

import React, { useContext, useEffect, useState } from "react";
import { ProductsContext } from "../../../context/ProductsContext";
import SearchableSelect from "../../global/SearchableSelect";

const InsumoForm = ({ handleCancel, parentId, childId = null, insumoToEdit = null }) => {
    const { getProducts, products, addProductInsumo, updateProductInsumo } = useContext(ProductsContext);
    const [productId, setProductId] = useState();
    const [amount, setAmount] = useState("");

    useEffect(() => {
        if (!insumoToEdit) {
            getProducts();
        }
    }, []);

    useEffect(() => {
        if (insumoToEdit) {
            setAmount(insumoToEdit.cantidad);
        }
    }, [insumoToEdit])

    const handleProductChange = (selectedProduct) => {
        setProductId(selectedProduct.value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const displayProductSelector = () => {
        if (!insumoToEdit && products && products.length > 0) {
            return (
                <>
                    <label>Producto</label>
                    <SearchableSelect
                        className="form-control bg-light py-2"
                        items={products}
                        itemText="article"
                        itemValue="idProducto"
                        modifier={handleProductChange}
                        value={productId}
                    />
                </>
            );
        }
    };

    const handleSubmit = () => {
        if (!insumoToEdit) {
            addProductInsumo(parentId, productId, amount);
        } else {
            updateProductInsumo(parentId, childId, amount)
        }
    };

    return (
        <>
            <div className="mb-5">
                <div className="mb-2">
                    {displayProductSelector()}
                </div>
                <div className="mb-2">
                    <label>Cantidad:</label>
                    <input
                        className="form-control bg-light py-2 mb-4"
                        type="text"
                        name="amount"
                        value={amount}
                        onChange={handleAmountChange}
                    />
                </div>
                {!insumoToEdit && <div className="w-100 d-flex justify-content-end">
                    <button className="btn small btn-outline-primary">
                        nuevo producto
                        <i className="ms-1 fa fa-plus"></i>
                    </button>
                </div>}
            </div>

            <div className="w-100 d-flex mt-3">
                <button className="btn me-1 w-50" onClick={handleCancel}>
                    Cancelar
                </button>
                <button
                    className="btn btn-primary ms-1 w-50"
                    type="submit"
                    disabled={false}
                    onClick={handleSubmit}
                >
                    {!insumoToEdit ? "Agregar Insumo" : 'Editar Insumo'}
                </button>
            </div>
        </>
    );
};

export default InsumoForm;

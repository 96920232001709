import {
  CANCEL_EDIT,
  EDIT_CONCEPTOS,
  EDIT_CONCEPTOS_ESTIMACION,
  EDIT_CONCEPTOS_EXTRA,
  EDIT_PARTIDAS_PRECIARIO,
} from "../types/preciario";

const getPrevious = (partidas, conceptos, conceptosExtra) => {
  let prevConceptos = conceptos;
  let prevPartidas = partidas;
  let prevExtras = conceptosExtra;
  if (prevConceptos === null || !prevConceptos) prevConceptos = [];
  if (prevPartidas === null || !prevPartidas) prevPartidas = [];
  if (prevExtras === null || !prevExtras) prevExtras = [];
  prevConceptos = JSON.parse(JSON.stringify([...prevConceptos]));
  prevPartidas = JSON.parse(JSON.stringify([...prevPartidas]));
  prevExtras = JSON.parse(JSON.stringify([...prevExtras]));
  return { prevConceptos, prevPartidas, prevExtras };
};

const FinanzasReducer = (state, { type, payload }) => {
  switch (type) {
    case CANCEL_EDIT:
      let prevCon = state.prevConceptos;
      if (prevCon === null || !prevCon) prevCon = [];
      let prevPar = state.prevPartidas;
      if (prevPar === null || !prevPar) prevPar = [];
      let prevExt = state.prevExtras;
      if (prevExt === null || !prevExt) prevExt = [];
      return {
        ...state,
        conceptos: [...prevCon],
        partidas: [...prevPar],
        conceptosExtra: [...prevExt],
        editMode: null,
      };
    case EDIT_CONCEPTOS:
      let { prevConceptos, prevPartidas } = getPrevious(
        state.partidas,
        state.conceptos
      );
      return {
        ...state,
        prevConceptos,
        prevPartidas,
        editMode: `conceptos-${payload}`,
      };
    case EDIT_PARTIDAS_PRECIARIO:
      let prev = getPrevious(
        state.partidas,
        state.conceptos,
        state.conceptosExtra
      );
      return {
        ...state,
        editMode: "partidas",
        prevPartidas: prev.prevPartidas,
        prevConceptos: prev.prevConceptos,
        prevExtras: prev.prevExtras,
      };
    case EDIT_CONCEPTOS_ESTIMACION:
      let previous = getPrevious(
        state.partidas,
        state.conceptos,
        state.conceptosExtra
      );
      return {
        ...state,
        editMode: "conceptos-estimacion",
        prevPartidas: previous.prevPartidas,
        prevConceptos: previous.prevConceptos,
        prevConceptosExtras: previous.prevExtras,
      };
    case EDIT_CONCEPTOS_EXTRA: {
      let prevAll = getPrevious(
        state.partidas,
        state.conceptos,
        state.conceptosExtra
      );
      let conceptosExtra = state.conceptosExtra;
      if (conceptosExtra === null || !conceptosExtra) conceptosExtra = [];
      else conceptosExtra = [...conceptosExtra];
      return {
        ...state,
        editMode: "conceptos-extra",
        ...prevAll,
        conceptosExtra,
      };
    }
    default:
      return { ...state };
  }
};
export default FinanzasReducer;

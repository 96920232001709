import React from "react";
import moment from "moment";

const DateRange = ({ start_label, end_label, start_date, end_date, onTime }) => {
  return (
    <div className="row small">
      <div className="col-6 ps-0">
        {start_label && (
          <span className="h6 titleInputPlantilla">{start_label}</span>
        )}
        <div className="calendarContainer mt-1">
          <span className={`${onTime ? '' : 'text-danger'}`}>
            {moment(start_date).format("DD MMM YYYY")}
          </span>
          <i className="fa fa-calendar-alt text-primary"></i>
        </div>
      </div>
      <div className="col-6 ps-0">
        {end_label && (
          <span className="h6 titleInputPlantilla">{end_label}</span>
        )}
        <div className="calendarContainer mt-1">
          <span className={`${onTime ? '' : 'text-danger'}`}>
            {moment(end_date).format("DD MMM YYYY")}
          </span>
          <i className="fa fa-calendar-alt text-primary"></i>
        </div>
      </div>
    </div>
  );
};

export default DateRange;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { IonCol, IonContent, IonGrid, IonModal, IonRow } from "@ionic/react";
import {
    faPlus,
    faTimes,
    faCheck,
    faStar,
    faHeart,
    faFlag,
    faCircle,
    faExclamation,
  } from "@fortawesome/free-solid-svg-icons";
  
  library.add(
    faPlus,
    faTimes,
    faCheck,
    faStar,
    faHeart,
    faFlag,
    faCircle,
    faExclamation
  ); // Añade aquí los íconos adicionales que deseas usar
  
// ...

const IconPickerModal = ({ isOpen, onDismiss, onSelect }) => {
    const icons = [
      faPlus,
      faTimes,
      faCheck,
      faStar,
      faHeart,
      faFlag,
      faCircle,
      faExclamation,
    ]; // Aquí puedes agregar más íconos
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss}       initialBreakpoint={0.3}
    breakpoints={[0.3, 0.8]}>
      <IonContent className="py-4 ps-4">
        <IonGrid>
          <IonRow>
            {icons.map((icon, index) => (
              <IonCol key={index} className="text-center">
                <button
                  className="btn"
                  onClick={() => {
                    onSelect(icon);
                    onDismiss();
                  }}
                >
                  <FontAwesomeIcon icon={icon} />
                </button>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default IconPickerModal;

import React, { useContext, useEffect } from "react";
import { ProveedoresContext } from "../context/ProveedoresContext";
import ProveedoresTable from "../components/proveedores/ProveedoresTable";
import SearchBar from "../components/global/SearchBar";
import '../components/proveedores/proveedores.css'
import ProveedoresHandler from "../utils/proveedores/ProveedoresHandler";
import { MenuContext } from "../context/MenuContext";

const Proveedores = ({ idProyecto }) => {
  const { handleCreateProveedor } = ProveedoresHandler()
  const { selectTabs, setSelected } = useContext(MenuContext);

  const { proveedores, proveedor, getAllProveedores } =
    useContext(ProveedoresContext);

  useEffect(() => {
    getAllProveedores();
    selectTabs();
    setSelected({ name: "proveedores" });
  }, []);

  useEffect(() => {
    getAllProveedores();
  }, [proveedor]);

  const handleCreateProvider = () => {
    handleCreateProveedor(idProyecto)
  }

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between mb-4 align-items-bottom mt-2">
        <div className="w-50">
          <SearchBar />
        </div>
        <div className="w-50 d-flex justify-content-end">
          <button
            className="btn btn-primary px-3 me-2 h-100"
            onClick={handleCreateProvider}
          >
            <i className="me-2 fa fa-plus"></i>
            Añadir Proveedor
          </button>
        </div>
      </div>
      <ProveedoresTable proveedores={proveedores} />
    </div>
  );
};

export default Proveedores;

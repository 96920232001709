import React, { useContext, useEffect, useState } from "react";
import ChecklistDashboard from "../components/checklists/ChecklistDashboard";
import ChecklistSection from "../components/checklists/ChecklistSection";
import BitacoraItem from "../components/checklists/BitacoraIItem";
import { ChecklistContext } from "../context/ChecklistContext";
import { ProyectosContext } from "../context/ProyectosContext";
import { CapacitorContext } from "../context/CapacitorContext";
import Comentarios from "../components/checklists/Comentarios";
import SectionForm from "../components/items/SectionForm";
import { ModalContext } from "../context/ModalContext";
import CrearItem from "../components/movil/CrearItem";
import { MenuContext } from "../context/MenuContext";
import ItemForm from "../components/items/ItemForm";
import { json, useParams } from "react-router-dom";
import { mainColor } from "../utils/colors";
import { getSectionItems } from "../utils";
import PlantillasButtons from "../components/plantillas/PlantillasButtons";
import { AuthContext } from "../context/AuthContext";
import { ItemsContext } from "../context/ItemsContext";
import DropDown from "../components/common/DropDown";
import { InfoBtn } from "../components/common/InfoBtn";
import RevisionForm from "../components/revisiones/RevisionForm";
import "../css/SingleChecklist.css";
import { RevisionesContext } from "../context/RevisionesContext";
import MobileSubHeader from "../components/movil/MobileSubHeader";
import GuardarMovil from "../components/movil/GuardarMovil";
import PlantillaForm from "../components/plantillas/PlantillaForm";
import { isCoordinador } from "../utils/permisos";
import { PermisosContext } from "../context/PermisosContext";
import MobileModal from "../components/movil/MobileModal";

const SingleChecklist = ({ idProyecto }) => {
  const { idChecklist } = useParams();
  const [query, setQuery] = useState("");
  const [estado, setEstado] = useState("todo");
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [showModalComments, setShowModalComments] = useState(false);
  const [showModalSection, setShowModalSection] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showRevision, setShowRevision] = useState(false);
  const [idItemComment, setIdItemComment] = useState(null);
  const [revDropdownTitle, setRevDropdownTitle] = useState('checklist');
  const [userIsCoordinador, setUserIsCoordinador] = useState(false);

  const {
    item,
    entrada,
    progreso,
    checklist,
    getSingleChecklist,
    postChecklistSection,
  } = useContext(ChecklistContext);

  const { getPermisosProyectos, permisos_proyecto, permisos, getPermisos } = useContext(PermisosContext);
  const { user } = useContext(AuthContext);
  const { setSelected } = useContext(MenuContext);
  const { platform } = useContext(CapacitorContext);
  const { proyecto } = useContext(ProyectosContext);
  const { getRevisiones, revisiones, currentRevision, setCurrentRevision } = useContext(RevisionesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { 
    items, 
    setItem,
    postItem, 
    createItem, 
    getItemsChecklist,
    getItemsByRevision
  } = useContext(ItemsContext);
  const color = proyecto && proyecto !== null ? proyecto.color : mainColor;

  useEffect(() => {
    setSelected({ name: "checklist" });
    fetchChecklist();
    getRevisiones(idChecklist, idProyecto);
    getPermisosProyectos();

    return () => {
      setCurrentRevision(null);
    }
  }, []);

  useEffect(() => {
    if (estado !== "todo") {
      setExpandAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  useEffect(() => {
    if (checklist && checklist !== null) {
      setSelected({ name: checklist.nombre });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklist]);

  useEffect(() => {
    if(currentRevision) {
      getItemsByRevision(currentRevision.idRevision, idProyecto);
    }
  }, [currentRevision]);

  useEffect(() => {
    const usuarioPermiso = permisos_proyecto?.find(obj => {
      return obj.idProyecto == idProyecto && obj.idUsuario === user.idUsuario;
    });

    if(usuarioPermiso && isCoordinador(usuarioPermiso)) {
      setUserIsCoordinador(true);
    } else {
      setUserIsCoordinador(false);
    }
    
  }, [permisos_proyecto]);

  const fetchChecklist = () => {
    getSingleChecklist(idProyecto, idChecklist);
    getItemsChecklist(idProyecto, idChecklist);
  };

  const handleCreateItem = (idChecklistSection) => {
    if (platform === "web") {
      createItem();
      modalComponent(
        "Agregar Item",
        <ItemForm
          postItem={postItem}
          idProyecto={idProyecto}
          idChecklist={idChecklist}
          handleCancel={clearModal}
          idChecklistSection={idChecklistSection}
        />
      );
    } else {
      setShowModal(true);
    }
  };

  const handleCreateSection = () => {
    if (platform === "web") {
      modalComponent(
        "Agregar Sección",
        <SectionForm
          idProyecto={idProyecto}
          idChecklist={idChecklist}
          handleCancel={clearModal}
          postItem={postChecklistSection}
        />
      );
    } else {
      setShowModalSection(true);
    }
  };

  const handleComments = (item, idItem) => {
    setItem(item);
    if (platform === "web") {
      modalComponent(
        "Comentarios",
        <BitacoraItem idProyecto={idProyecto}/>
      );
    } else {
      setShowModalComments(true);
      setIdItemComment(idItem);
    }
  };

  const renderItems = () => {
    if (checklist !== null && proyecto !== null) {
      if (Array.isArray(checklist.sections)) {
        let sectionsRender = [...checklist.sections];
        if (sectionsRender.length === 0) {
          return <p>No hay secciones que mostrar aún. Agrega una.</p>;
        }
        if (query && query !== "" && query !== null) {
          let currentQuery = String(query).toLowerCase();
          sectionsRender = sectionsRender.filter((section) => {
            let nombre = String(section.nombre).toLowerCase();
            return (
              nombre.startsWith(currentQuery) ||
              nombre.includes(currentQuery) ||
              getSectionItems(items, section.idChecklistSection, query)
            );
          });
        }
        return sectionsRender.map((section) => (
          <ChecklistSection
            query={query}
            estado={estado}
            section={section}
            expand={expandAll}
            editMode={editMode}
            idProyecto={idProyecto}
            setEditMode={setEditMode}
            idChecklist={idChecklist}
            handleCallback={fetchChecklist}
            handleComments={handleComments}
            key={section.idChecklistSection}
            handleCreateItem={handleCreateItem}
            idSection={section.idChecklistSection}
          />
        ));
      }
    }
  };

  const handleRevisionModal = () => {
    if(platform === 'web') {
      modalComponent(
        "Generar Revision",
        <RevisionForm idChecklist={idChecklist} idProyecto={idProyecto}/>
      )
    } else {
      setShowRevision(true);
    }

  }

  const handleCurrentRevision = (revision) => {
    setCurrentRevision(revision);
    setRevDropdownTitle(`Revisión: ${revision.numero}`)
  }

  const handleChecklistBtn = () => {
    setCurrentRevision(null);
    setRevDropdownTitle('checklist');
  }

  const handlePlantilla = () => {
    if (platform === "web") {
      modalComponent(
        "Guardar Plantilla",
        <PlantillaForm idProyecto={idProyecto} tipo={'checklist'} />
      );
    } else {
      setShowSaveModal(true);
    }
  };

  const renderRevisionOptions = () => {
    const currentRevId = currentRevision?.idRevision;

    return revisiones.map(rev => {
      const isActive = currentRevId === rev.idRevision;

      return(
        <button
          key={rev.idRevision}
          className={
            `w-100 btn rounded-0 btn-light ${isActive ? 'text-primary' : null}`
          }
          onClick={() => handleCurrentRevision(rev)}
        >
          Revisión: {rev.numero}
        </button>
      )
    })
  }

  const renderAddSectionBtn = () => {
    if(userIsCoordinador) {
      return(
        <div className="text-end" style={{width: 'max-content'}}>
          <button onClick={handleCreateSection} className="btn btn-primary">
            <i className="fa fa-plus"></i>
          </button>
        </div>
      )
    } 
  }

  const renderMobileHeader = () => {
    if(platform !== 'web') {
      return(
        <MobileSubHeader 
          title={'Checklists'}
          subtitle={checklist?.nombre}
        >
          <div className="text-end">
            <button
              className="btn btn-primary"
              style={{ marginRight: "2px" }}
              onClick={handlePlantilla}
            >
              <i className="fa fa-save"></i>
            </button>
          </div>

        </MobileSubHeader>
      )
    }
  }

  return (
    <div className="container-fluid h-100 px-0" style={{overflowY: 'auto'}}>
    
      {renderMobileHeader()}
      <ChecklistDashboard
        item={item}
        color={color}
        entrada={entrada}
        progreso={progreso}
      />
      <div className="row px-2">
        <div className="card shadow-sm py-3 px-1 my-3 mb-4 border-0">
          <div 
            className="checklist__header d-flex flex-row flex-wrap 
            align-items-center mb-3 justify-content-center"
          >
            <div 
              className="px-2 flex-row header__opts justify-content-start" 
            >
              <div className="px-0 me-2" style={{width: 'max-content'}}>
                <button
                  onClick={() => setExpandAll(!expandAll)}
                  className="btn btn-light"
                  style={{
                    backgroundColor: "#db8c63",
                    color: "white",
                  }}
                >
                  <i className={`fa fa-chevron-${expandAll ? "up" : "down"}`}></i>
                </button>
              </div>

              <div className="me-2 w-75" style={{width: 'max-content'}}>
                <input
                  type="text"
                  value={query}
                  className="form-control"
                  placeholder="Buscar sección o item"
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>

              {renderAddSectionBtn()}
            </div>

            <div
              className="col-12 px-0 header__opts justify-content-start"
            >
              <div className=" col-6">
                <DropDown title='Filtrar'>
                  <button
                    onClick={() => setEstado("todo")}
                    className={`w-100 btn btn-${estado === "todo" ? "primary" : "outline-primary"}
                    rounded-0`}
                  >
                    Todo <i className="fa fa-circle me-2"></i>
                  </button>

                  <button
                    onClick={() => setEstado("bueno")}
                    className={`w-100 btn btn-${estado === "bueno" ? "success" : "outline-success"}
                    rounded-0`}
                  >
                    Buen Estado <i className="fa fa-check-circle me-2"></i>
                  </button>

                  <button
                    onClick={() => setEstado("mantenimiento")}
                    className={
                      `w-100 btn btn-${estado === "mantenimiento" ? "warning" : "outline-warning"}
                      rounded-0`
                    }
                  >
                    Mantenimiento <i className="fa fa-info-circle me-2"></i>
                  </button>
                </DropDown>
              
              </div>

              <div className={` col-6`} >
                <DropDown 
                  title={revDropdownTitle} 
                  btnOutlined={false}
                >
                  <button
                    onClick={handleChecklistBtn}
                    className={`w-100 btn rounded-0 btn-light
                    ${revDropdownTitle === 'checklist' ? 'text-primary' : null}`}
                  >
                    Checklist
                  </button>

                  {renderRevisionOptions()}

                  <button
                    className={`w-100 btn rounded-0 btn-primary`}
                    onClick={handleRevisionModal}
                  >
                    Generar Revisión
                  </button>
                </DropDown>
              </div>

            </div>

            
          </div>
          {renderItems()}
        </div>
      </div>
      <CrearItem
        showModal={showModal}
        setShowModal={setShowModal}
        postItem={postItem}
        idProyecto={idProyecto}
        idChecklist={idChecklist}
        handleCancel={() => {
          clearModal();
          setShowModal(false);
        }}
      />
      <Comentarios
        showModal={showModalComments}
        setShowModal={setShowModalComments}
        idProyecto={idProyecto}
        idItem={idItemComment}
      />

      <MobileModal
        showModal={showModalSection}
        setShowModal={setShowModalSection}
      >
       <SectionForm
          idProyecto={idProyecto}
          idChecklist={idChecklist}
          handleCancel={() => setShowModalSection(false)}
          postItem={postChecklistSection}
        />
      </MobileModal>

      <MobileModal
        showModal={showRevision}
        setShowModal={setShowRevision}
      >
        <RevisionForm 
          idChecklist={idChecklist} 
          idProyecto={idProyecto}
          handleCancel={() => setShowRevision(false)}
        />
      </MobileModal>

      <GuardarMovil
        idProyecto={idProyecto}
        showModal={showSaveModal}
        setShowModal={setShowSaveModal}
      />
    </div>
  );
};

export default SingleChecklist;

import React, { createContext, useReducer } from "react";
import CapacitorReducer from "../reducers/CapacitorReducer";
import { SET_DEVICE, SET_DEVICE_OS, SET_FCM_TOKEN, SET_IS_MOBILE, SET_PLATFORM } from "../types/capacitor";
import { PushNotifications } from "@capacitor/push-notifications";
import { StatusBar, Style } from "@capacitor/status-bar";
import { FCM } from "@capacitor-community/fcm";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";

const initialState = {
  platform: null,
  device: null,
  device_OS: null,
  isMobileDevice: null,
  fcm_token: null
};

export const CapacitorContext = createContext(initialState);

export const CapacitorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CapacitorReducer, initialState);

  const setupPlatform = () => {
    const platform = Capacitor.getPlatform();
    dispatch({ type: SET_PLATFORM, payload: platform });
  };

  const setupStatusBar = () => {
    StatusBar.setStyle({ style: Style.Dark });
  };

  const setupDevice = () => {
    Device.getInfo().then((info) => {
      if (info.operatingSystem === 'ios' || info.operatingSystem === 'android') {
        dispatch({ type: SET_IS_MOBILE, payload: true });
      } else {
        dispatch({ type: SET_IS_MOBILE, payload: false });
      }

      dispatch({ type: SET_DEVICE_OS, payload: info.operatingSystem });
      dispatch({ type: SET_DEVICE, payload: info.model });
    });
  };

  // const requestPermission = async () => {
  //   const { granted } = await FCM.requestPermission();
  //   if (granted) {
  //     const { token } = await FCM.getToken();
  //     console.log('FCM Token:', token);
  //     // Aquí puedes enviar el token al servidor para asociarlo con el usuario o dispositivo.
  //   }
  // };
  

  const setupPushNotifications = async () => {
    try {
      await PushNotifications.requestPermissions();
      await PushNotifications.register();


      const fcmToken = await FCM.getToken();
      dispatch({ type: SET_FCM_TOKEN, payload: fcmToken.token });

    } catch (err) {
      console.error('Error al obtener el token FCM:', err);
    }


    // PushNotifications.requestPermissions().then((result) => {
    //   if (result.receive === "granted") {
    //     console.log(result);
        
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     PushNotifications.register();
    //   } else {
    //     // Show some error
    //   }
    // });

    // // On success, we should be able to receive notifications
    // PushNotifications.addListener("registration", (token) => {
    //   alert("Push registration success, token: " + token.value);
    // });

    // // Some issue with our setup and push will not work
    // PushNotifications.addListener("registrationError", (error) => {
    //   alert("Error on registration: " + JSON.stringify(error));
    // });

    // // Show us the notification payload if the app is open on our device
    // PushNotifications.addListener(
    //   "pushNotificationReceived",
    //   (notification) => {
    //     alert("Push received: " + JSON.stringify(notification));
    //   }
    // );

    // // Method called when tapping on a notification
    // PushNotifications.addListener(
    //   "pushNotificationActionPerformed",
    //   (notification) => {
    //     alert("Push action performed: " + JSON.stringify(notification));
    //   }
    // );
  };

  return (
    <CapacitorContext.Provider
      value={{
        ...state,
        setupDevice,
        setupPlatform,
        setupStatusBar,
        setupPushNotifications,
      }}
    >
      {children}
    </CapacitorContext.Provider>
  );
};

import React, { createContext, useContext, useReducer } from "react";
import AdjuntoReducer from "../reducers/AdjuntoReducer";
import AdjuntosService from "../services/AdjuntosService";
import {
  SET_FOCUSED,
  SET_ADJUNTOS,
  SET_SINGLE_ADJUNTO,
  SET_PROPERTY_ADJUNTO,
} from "../types/adjuntos";
import { ModalContext } from "./ModalContext";
import { AppConfigContext } from "./AppConfigContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";

const initialState = {
  adjunto: null,
  adjuntos: null,
  spinner: false,
};

export const AdjuntosContext = createContext(initialState);

export const AdjuntosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AdjuntoReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const { S3_ENDPOINT } = useContext(AppConfigContext);

  // AdjuntosContext.js
  const getAdjuntoProyecto = (idProyecto, idAdjunto, callback, signal) => {
    const fetchData = async () => {
      try {
        const res = await AdjuntosService.getAdjuntoFolder(
          idProyecto,
          idAdjunto,
          { signal }
        );
        const { adjunto } = res.data;
        let src = `${S3_ENDPOINT}/files/proyectos/${adjunto.idAdjunto}.${adjunto.tipo}`;
        if (adjunto.content) {
          src = `data:image/${adjunto.tipo};base64,${adjunto.content}`;
        }
        if (typeof callback === "function") {
          callback(src);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error al obtener adjunto:", error);
          callback(null);
        }
      }
    };

    fetchData();
  };

  const setAdjuntos = (adjuntos) => {
    dispatch({ type: SET_ADJUNTOS, payload: adjuntos });
  };

  const setFocused = (adjunto) => {
    dispatch({ type: SET_FOCUSED, payload: adjunto });
  };

  const setSingleAdjunto = (adjunto) => {
    dispatch({ type: SET_SINGLE_ADJUNTO, payload: adjunto });
  };

  const clearSingleAdjunto = () => {
    dispatch({ type: SET_SINGLE_ADJUNTO, payload: null });
  };

  const uploadSingleAdjunto = (idProyecto, idFolder, file) => {
    const formData = AdjuntosService.getFormData(file);
    const service = AdjuntosService.postAdjuntoFolder;

    return new Promise((resolve, reject) => {
      service(idProyecto, idFolder, formData)
        .then(res => {
          const { data } = res;
          console.log(data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err)
        })
    })
  };

  const uploadAdjuntos = (idProyecto, idFolder, files, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let promises = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const formData = AdjuntosService.getFormData(file);
      promises.push(
        AdjuntosService.postAdjuntoFolder(idProyecto, idFolder, formData)
      );
    }

    return new Promise((resolve, reject) => {
      Promise.all(promises)
      .then(() => {
        clearModal();
        dispatch({ type: HIDE_SPINNER });
        success("Archivos guardados con éxito.");
        if (typeof callback === "function") callback();
        resolve()
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
        reject(error)
      });
    })

  };

  const setProperyAdjunto = (idAdjunto, nombre) => {
    dispatch({ type: SET_PROPERTY_ADJUNTO, payload: { idAdjunto, nombre } });
  };

  const deleteAdjunto = (idProyecto, idAdjunto, callback) => {
    AdjuntosService.deleteAdjuntoFolder(idProyecto, idAdjunto)
      .then(() => {
        if (callback) callback();
        success("Adjunto eliminado con éxito.");
      })
      .catch((error) => {
        alert(error);
      });
  };

  const updateSingleAdjunto = (idAdjunto, nombre, callback) => {
    AdjuntosService.putAdjunto(idAdjunto, nombre)
      .then(() => {
        if (callback) callback();
        success("Adjunto actualizado.");
      })
      .catch((error) => {
        alert(error);
      });
  };

  const downloadAdjunto = (idProyecto, adjunto) => {
    const { idAdjunto } = adjunto;
    AdjuntosService.downloadAdjunto(idProyecto, idAdjunto)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${adjunto.nombre}.${adjunto.tipo}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <AdjuntosContext.Provider
      value={{
        ...state,
        setFocused,
        setAdjuntos,
        deleteAdjunto,
        uploadAdjuntos,
        downloadAdjunto,
        setSingleAdjunto,
        setProperyAdjunto,
        clearSingleAdjunto,
        getAdjuntoProyecto,
        updateSingleAdjunto,
        uploadSingleAdjunto,
      }}
    >
      {children}
    </AdjuntosContext.Provider>
  );
};

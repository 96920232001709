import React, { useContext } from "react";
import moment from "moment";
import ImageInput from "../common/ImageInput";
import { formatMonto, FILES_ENDPOINT, getValue } from "../../utils";
import { PagosContext } from "../../context/PagosContext";

const PagoForm = ({ idProyecto, idContrato, handleCancel, handleCallback }) => {
  const { pago, postPagoContrato, setPropiedadPago } = useContext(PagosContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postPagoContrato(idProyecto, idContrato, pago, handleCallback);
  };

  const renderMonto = () => {
    if (isNaN(parseInt(pago.idPago))) {
      return (
        <input
          min={0}
          step=".01"
          type="number"
          value={getValue(pago, "monto")}
          className="form-control mb-3"
          onChange={(e) => setPropiedadPago("monto", e.target.value)}
        />
      );
    }
    return (
      <p>
        {"$"}
        {formatMonto(pago.monto)}
      </p>
    );
  };

  const renderAutorizacion = () => {
    if (isNaN(parseInt(pago.idPago))) {
      return (
        <input
          type="date"
          className="form-control mb-3"
          value={getValue(pago, "fecha_hora_autorizacion", "date")}
          onChange={(e) =>
            setPropiedadPago("fecha_hora_autorizacion", e.target.value)
          }
        />
      );
    }
    return <p>{moment(pago.fecha_hora_autorizacion).format("DD MMM YYYY")}</p>;
  };

  const renderComprobante = () => {
    if (!isNaN(parseInt(pago.idPago))) {
      const { idAdjunto } = pago;
      return (
        <div>
          <h4>Comprobante</h4>
          <ImageInput
            src={
              idAdjunto && idAdjunto !== null
                ? `${FILES_ENDPOINT}/${idAdjunto}`
                : undefined
            }
            modifier={setPropiedadPago}
          />
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Monto</label>
      {renderMonto()}
      <label>Fecha Autorizado</label>
      {renderAutorizacion()}
      {renderComprobante()}
      <div className="row align-items-center mt-3">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PagoForm;

import React, { useContext, useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";

import Folders from "./Folders";
import Almacen from "./Almacen";
import Alertas from "./Alertas";
import Progreso from "./Progreso";
import Proyecto from "./Proyecto";
import Catalogo from "./Catalogo";
import Bitacora from "./Bitacora";
import Finanzas from "./Finanzas";
import Recorrido from "./Recorrido";
import OrderList from "./OrderList";
import PurchaseOrder from "./PurchaseOrder";
import Recorridos from "./Recorridos";
import Analiticas from "./Analiticas";
import Checklists from "./Checklists";
import Supervision from "./Supervision";
import SingleProduct from "./SingleProduct";
import SingleChecklist from "./SingleChecklist";
import ChecklistsMobile from "./ChecklistsMobile";
import SingleContrato from "./SingleContrato";

import UploadEstimacionForm from "../components/preciario/UploadEstimacionForm";
import UploadPreciarioForm from "../components/preciario/UploadPreciarioForm";

import { MenuContext } from "../context/MenuContext";
import { AlertasContext } from "../context/AlertasContext";
import { UsuariosContext } from "../context/UsuariosContext";
import { ProyectosContext } from "../context/ProyectosContext";

import { setupColor } from "../utils";
import { CapacitorContext } from "../context/CapacitorContext";
import ConceptoHistorial from "../components/finanzas/ConceptoHistorial";

const Obra = () => {
  const { idProyecto } = useParams();
  const { proyecto, getSingleProyecto, clearSingleProyecto } =
    useContext(ProyectosContext);

  const { getUsuariosProyecto } = useContext(UsuariosContext);

  const { getAlertas } = useContext(AlertasContext);

  const { selectTabs } = useContext(MenuContext);
  const { platform } = useContext(CapacitorContext);

  useEffect(() => {
    getUsuariosProyecto(idProyecto);
    getSingleProyecto(idProyecto);
    getAlertas(idProyecto);
    selectTabs(idProyecto);
    return clearSingleProyecto;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  useEffect(() => {
    if (proyecto && proyecto !== null) {
      if (proyecto.color && proyecto.color !== null) {
        setupColor(proyecto.color);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proyecto]);

  return (
    <div
      id="obra-view"
      className="container-fluid px-0"
      style={{
        height:
          platform !== "web" ? "calc(100vh - 155px)" : "calc(100vh - 100px)",
      }}
    >
      <Routes>
        <Route path="/" element={<Progreso idProyecto={idProyecto} />} />
        <Route path="/alertas" element={<Alertas idProyecto={idProyecto} />} />
        <Route
          path="/bitacora"
          element={<Bitacora idProyecto={idProyecto} />}
        />
        <Route
          path="/proyecto"
          element={<Proyecto idProyecto={idProyecto} />}
        />
        <Route path="/almacen" element={<Almacen idProyecto={idProyecto} />} />
        <Route
          path="/almacen/producto/:idProducto"
          element={<SingleProduct />}
        />
        <Route
          path="/catalogo"
          element={<Catalogo idProyecto={idProyecto} />}
        />
        <Route
          path="/ordenes"
          element={<OrderList idProyecto={idProyecto} />}
        />
        <Route
          path="/orden/:idOrden"
          element={<PurchaseOrder idProyecto={idProyecto} />}
        />
        <Route
          path="/checklists"
          element={<Checklists idProyecto={idProyecto} />}
        />
        <Route
          path="/analiticas"
          element={<Analiticas idProyecto={idProyecto} />}
        />
        <Route
          path="/recorridos"
          element={<Recorridos idProyecto={idProyecto} />}
        />
        <Route
          path="/supervision"
          element={<Supervision idProyecto={idProyecto} />}
        />
        <Route
          path="/recorridos/:idTour"
          element={<Recorrido idProyecto={idProyecto} />}
        />
        <Route
          path="/finanzas"
          element={<Finanzas idProyecto={idProyecto} />}
        />
        <Route
          path="/finanzas/:idContrato"
          element={<SingleContrato idProyecto={idProyecto} />}
        />
        <Route
          path="/finanzas/conceptos/:idConcepto"
          element={<ConceptoHistorial idProyecto={idProyecto} />}
        />
        <Route
          path="/finanzas/:idContrato/preciario"
          element={<UploadPreciarioForm idProyecto={idProyecto} />}
        />
        <Route
          path="/finanzas/:idContrato/estimacion/:idEstimacion"
          element={<UploadEstimacionForm idProyecto={idProyecto} />}
        />
        <Route
          path="/checklists/:idChecklist"
          element={<SingleChecklist idProyecto={idProyecto} />}
        />
        {/* Mobile Views */}
        <Route path="/folders" element={<Folders idProyecto={idProyecto} />} />
        <Route
          path="/progreso"
          element={<ChecklistsMobile idProyecto={idProyecto} />}
        />
      </Routes>
    </div>
  );
};

export default Obra;

import React, { useContext, useEffect } from "react";
import "../product.css";
import SearchBar from "../../global/SearchBar";
import ProductOrderStatusBadge from "./ProductOrderStatusBadge";
import { OrdenesContext } from "../../../context/OrdenesContext";
import { ModalContext } from "../../../context/ModalContext";
import { Link } from "react-router-dom";
import ProductOrdenForm from "../../ordenes/ProductOrdenForm";
import OrderHandler from "../../../utils/ordendes/OrderHandler";

const ProductOrderList = ({ product }) => {

    const { ordenes, getOrdenesDeProducto } = useContext(OrdenesContext);
    const { handleCreateOrder } = OrderHandler();

    useEffect(() => {
        if (product && product.idProducto)
            getOrdenesDeProducto(product.idProducto);
    }, []);

    const renderOrdersRows = () => {
        return ordenes.map((order) => (
            <tr key={order.idOrden}>
                <td style={{ textAlign: "left" }}>
                    <Link to={`/orden/${order.idOrden}`}>
                        <a
                            className="bold text-primary"
                            style={{ textDecoration: "underline" }}
                        >
                            {order?.description}
                        </a>
                    </Link>
                </td>
                <td align="center">
                    <ProductOrderStatusBadge status={order?.status} />
                </td>
                <td>{order?.orderSummary?.subtotal}</td>
                <td>{"2023-08-18"}</td>
            </tr>
        ));
    };

    return (
        <div>
            <div className="w-100 d-flex flex-row align-items-center mb-3">
                <div className="w-50">
                    <h2 className="mb-3 bold">Órdenes de Compra </h2>
                </div>
                <div className="w-50 d-flex align-items-center justify-content-between">
                    <div className="w-75" style={{ paddingRight: "10px" }}>
                        <SearchBar />
                    </div>
                    <div className="w-25">
                        <button
                            className="btn btn-primary w-100"
                            onClick={() => handleCreateOrder(product)}
                        >
                            + Orden
                        </button>
                    </div>
                </div>
            </div>
            {ordenes && ordenes?.length > 0 ? (
                <div className="card shadow p-3">
                    <table className="text-center">
                        <thead>
                            <tr>
                                <th style={{ textAlign: "left" }}>ORDEN DE COMPRA</th>
                                <th>ESTADO</th>
                                <th>CANTIDAD</th>
                                <th>FECHA DE ENTREGA</th>
                            </tr>
                        </thead>
                        <tbody>{renderOrdersRows()}</tbody>
                    </table>
                </div>
            ) : (
                <p>No existen ordenes de compra</p>
            )}
        </div>
    );
};

export default ProductOrderList;

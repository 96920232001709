import React, { useContext, useEffect } from "react";
import ChecklistForm from "../components/checklists/ChecklistForm";
import { IonList, IonItem, IonIcon, IonLabel } from "@ionic/react";
import { ChecklistContext } from "../context/ChecklistContext";
import { ModalContext } from "../context/ModalContext";
import { chevronForwardOutline } from "ionicons/icons";
import { redirect } from "react-router-dom";
import { MenuContext } from "../context/MenuContext";

const ChecklistsMobile = ({ idProyecto }) => {
  const { checklists, createChecklist, getChecklistsProyecto } =
    useContext(ChecklistContext);

  const { clearModal, modalComponent } = useContext(ModalContext);

  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    getChecklistsProyecto(idProyecto);
    setSelected({ name: "progreso" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    clearModal();
    getChecklistsProyecto(idProyecto);
  };

  const handleCreateChecklist = () => {
    createChecklist();
    modalComponent(
      "Crear Checklist",
      <ChecklistForm idProyecto={idProyecto} handleCancel={handleCancel} />
    );
  };

  const renderChecklists = () => {
    if (Array.isArray(checklists)) {
      if (checklists.length === 0) {
        return <p>No hay checklists registrados</p>;
      }
      return (
        <div className="mx-n-15">
          <IonList inset={true} lines="full" className="shadow-sm">
            {checklists.map(({ idChecklist, nombre }) => (
              <IonItem
                key={idChecklist}
                onClick={() => redirect(`./checklists/${idChecklist}`)}
              >
                <IonLabel>{nombre}</IonLabel>
                <IonIcon slot="end" icon={chevronForwardOutline} />
              </IonItem>
            ))}
          </IonList>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <h1 className="mb-3 h2 bold">Progreso</h1>
      <div className="mx-n-15">
        <IonList inset={true} lines="full" className="shadow-sm">
          <IonItem onClick={() => redirect(`/obra/${idProyecto}`)}>
            <IonLabel>Cronograma</IonLabel>
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </IonItem>
        </IonList>
      </div>
      <div className="row px-2 align-items-center mt-4 mb-3">
        <div className="col-8">
          <h3 className="bold mb-0">Checklists</h3>
        </div>
        <div className="col-4">
          <button
            className="btn btn-sm btn-outline-primary w-100"
            onClick={handleCreateChecklist}
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
      {renderChecklists()}
    </div>
  );
};

export default ChecklistsMobile;

import React, { Fragment, useContext, useEffect } from "react";
import TrabajosRetrasados from "../components/analiticas/TrabajosRetrasados";
import CurvaCriticaObra from "../components/analiticas/CurvaCriticaObra";
import GraficaFinanzas from "../components/finanzas/GraficaFinanzas";
import { ProyectosContext } from "../context/ProyectosContext";
import { PartidasContext } from "../context/PartidasContext";
import AvanceObra from "../components/analiticas/AvanceObra";
import ChartCard from "../components/analiticas/ChartCard";
import GanttChart from "../components/charts/GanttChart";
import { MenuContext } from "../context/MenuContext";
import moment from "moment";
import MobileSubHeader from "../components/movil/MobileSubHeader";


const Analiticas = ({ idProyecto }) => {
  const { setSelected } = useContext(MenuContext);
  const { partidas, getPartidasProyecto } = useContext(PartidasContext);
  const { proyecto, progresoObra, getSingleProyecto } =
    useContext(ProyectosContext);

  useEffect(() => {
    setSelected({ name: "analiticas" });
    getPartidasProyecto(idProyecto);
    getSingleProyecto(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUltimoDia = () => {
    return moment(proyecto.fecha_fin).diff(proyecto.fecha_inicio, "days");
  };

  const renderDia = () => {
    if (progresoObra) {
      if (progresoObra.programadoDia) {
        return (
          <h4 className="h5">
            <b>Día Actual </b>
            {proyecto.diaActual} / {getUltimoDia()}
          </h4>
        );
      }
    }
  };

  const renderUltimaActualizacion = () => {
    if (proyecto.ultimoAvance !== null) {
      let ultimoAvance = moment(proyecto.ultimoAvance).utc();
      if (ultimoAvance.isValid()) {
        return ultimoAvance.format("DD MMM YYYY");
      }
    }
  };

  const renderContent = () => {
    if (proyecto && proyecto !== null) {
      return (
        <div className="container-fluid px-0 h-100" style={{overflowY: 'auto', overflowX: 'hidden'}}>
          <MobileSubHeader
            title='Analiticas'
          >

          </MobileSubHeader>
          <div className="row align-items-center ">
            <div className="col-12 col-md-6 mb-2">
              <h4 className="h5">
                Ultima Actualización: {renderUltimaActualizacion()}
              </h4>
            </div>
            <div className="col-12 col-md-6 mb-2"> {renderDia()}</div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <ChartCard titulo="Avance de Obra">
                <AvanceObra proyecto={proyecto} />
              </ChartCard>
            </div>
            <div className="col-12 col-lg-6">
              <ChartCard titulo="Ruta Crítica de Obra">
                <CurvaCriticaObra
                  real={proyecto.real}
                  partidas={partidas !== null ? partidas : []}
                  aTiempo={proyecto.real >= proyecto.programado}
                />
              </ChartCard>
            </div>
          </div>

          <div className="row mt-3 ">
            <div className="col-12" >
              <ChartCard titulo="Diagrama de Gantt">
                <div className="col-12">
                  <GanttChart idProyecto={idProyecto} partidas={partidas} />
                </div>
              </ChartCard>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <GraficaFinanzas idProyecto={idProyecto} />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <ChartCard>
                <TrabajosRetrasados
                  idProyecto={idProyecto}
                  partidas={partidas !== null ? partidas : []}
                  permiso={proyecto.permiso}
                />
              </ChartCard>
            </div>
          </div>
          
        </div>
      );
    }
  };

  return <Fragment>{renderContent()}</Fragment>;
};

export default Analiticas;

import React from "react";

const ColorBox = ({ color, block }) => {
  const renderContent = () => {
    if (block) {
      return (
        <span className="text-start px-0 mb-0 py-0">
          <span className={color.className + " colorBox shadow-sm"}></span>
          <span className="small pr-2">{color.title}</span>
        </span>
      );
    }

    return (
      <div className="mb-3">
        <span className={color.className + " colorBox shadow-sm"}></span>
        <span className="small pr-2">{color.title}</span>
      </div>
    );
  };

  return <span className={`${block ? 'd-inline' : 'd-block'} px-1 me-2`}>{renderContent()}</span>;
};

export default ColorBox;

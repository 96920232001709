import React, { useContext, useEffect, useState } from "react";
import ListaUsuarios from "../components/usuarios/ListaUsuarios";
import UsuarioForm from "../components/usuarios/UsuarioForm";
import { CapacitorContext } from "../context/CapacitorContext";
import { MenuContext } from "../context/MenuContext";
import { ModalContext } from "../context/ModalContext";
import { UsuariosContext } from "../context/UsuariosContext";
import { hideModal } from "../utils";
import TabOptions from "../components/common/TabOptions";
import MobileSubHeader from "../components/movil/MobileSubHeader";
import MobileModal from "../components/movil/MobileModal";


const Usuarios = () => {
  const { selectTabs } = useContext(MenuContext);
  const { platform, isMobileDevice } = useContext(CapacitorContext);

  const [query, setQuery] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isTableView, setIsTableView] = useState(platform === "web");

  const {
    users,
    getUsers,
    editUsuario,
    clearUsuario,
    // deleteUsuario,
    createUsuario,
  } = useContext(UsuariosContext);

  const { setSelected } = useContext(MenuContext);

  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getUsers();
    selectTabs();
    setSelected({ name: "usuarios" });
    console.log(currentUser);
    
    return clearUsuario;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    hideModal();
    clearModal();
    clearUsuario();
  };

  const handleEditUsuario = (usuario) => {
    editUsuario(usuario);

    if(platform === 'web') {
      modalComponent(
        "Editar Usuario",
        <UsuarioForm handleCancel={handleCancel} title={"Editar Usuario"} />
      );
    } else {
      setShowCreateModal(true);
    }

  };

  const handleCreateUsuario = () => {
    createUsuario();

    if(platform === 'web') {
      modalComponent(
        "Crear Usuario",
        <UsuarioForm 
          handleCancel={handleCancel} 
        />
      );
    } else {
      setShowCreateModal(true);
    }
  };

  const handleDeleteUsuario = (usuario) => {
    if(platform === 'web') {
      modalComponent(
        "Precaución",
        <div className="container-fluid px-0">
          <p>
            ¿Eliminar el usuario {usuario.correo}? Esta acción NO puede
            deshacerse.
          </p>
          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-danger"
                // onClick={() => deleteUsuario(usuario.uid, getUsers)}
              >
                Eliminar
              </button>
            </div>
            <div className="col-6 text-end">
              <button className="btn btn-link text-muted" onClick={clearModal}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      setCurrentUser(usuario);
      console.log(usuario);
      
      setShowDelete(true);
    }

  };

  const renderUsuarios = () => {
    let currentUsers = users;

    if (query && query !== "") {
      let currentQuery = String(query).toLowerCase();
      currentUsers = users.filter((user) => {
        let nombre = String(user.nombre).toLowerCase();
        let correo = String(user.correo).toLowerCase();
        return nombre.includes(currentQuery) || correo.includes(currentQuery);
      });

    }
    return (
      <ListaUsuarios
        query={query}
        platform={platform}
        users={currentUsers}
        isTableView={isTableView}
        editUsuario={handleEditUsuario}
        deleteUsuario={handleDeleteUsuario}
      />
    );
  };

  const renderContent = () => {
    if (!isMobileDevice) {
      return (
        <div className="container-fluid ">
          <TabOptions
            query={query}
            setQuery={setQuery}
            isTableView={isTableView}
            setIsTableView={setIsTableView}
            handleCreate={handleCreateUsuario}
            buttonCreateText={'+ NUEVO USUARIO'}
            searchInputPlaceholder={'Buscar usuario...'}
          />

          <div>{renderUsuarios()}</div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid mb-5 px-0 view-container">
          <MobileSubHeader
            title='Usuarios'
          >
            <div className="text-end">
              <button
                onClick={handleCreateUsuario}
                className="btn btn-primary"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </MobileSubHeader>

          <div className="row">
            <div className="col-12 col-md-6">
              <input
                type="text"
                value={query}
                className="form-control bg-white"
                placeholder="Buscar un usuario..."
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>

          {renderUsuarios()}

          <MobileModal
            showModal={showCreateModal}
            setShowModal={setShowCreateModal}
          >
            <UsuarioForm 
              handleCancel={() => setShowCreateModal(false)} 
            />
          </MobileModal>

          <MobileModal
            showModal={showDelete}
            setShowModal={setShowDelete}
          >
            <h3>Precaución</h3>
            <p>
              ¿Eliminar el usuario {currentUser?.correo}? Esta acción NO puede
              deshacerse.
            </p>

            <div className="row text-end">
              <div className="col-6">
                <button 
                  className="w-100 btn btn-light"
                  onClick={() => setShowDelete(false)}
                >
                  Cancelar
                </button>
              </div>

              <div className="col-6">
                <button 
                  className="w-100 btn btn-danger"
                  // onClick={() => deleteUsuario(currentUser.uid, getUsers)}
                >
                  Eliminar
                </button>
              </div>
            </div>

          </MobileModal>
        </div>
      );
    }
  };

  return renderContent();
};

export default Usuarios;

import React, { useState, useContext, useEffect } from "react";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { ConceptosContext } from "../../context/ConceptosContext";

const SelectEstimacion = ({ idProyecto, idContrato }) => {
  const [selected, setSelected] = useState("");
  const { getConceptosEstimacion } = useContext(ConceptosContext);
  const { estimaciones, clearEstimacion, selectEstimacion } = useContext(EstimacionesContext);

  useEffect(() => {
    if (!isNaN(parseInt(selected))) {
      selectEstimacion(idProyecto, idContrato, selected);
      getConceptosEstimacion(idProyecto, selected);
    } else {
      clearEstimacion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  function renderEstimaciones() {
    if (Array.isArray(estimaciones)) {
      return [
        <option value="" key="null">
          Mostrando Acumulados
        </option>,
        ...estimaciones.map((estimacion) => (
          <option key={estimacion.idEstimacion} value={estimacion.idEstimacion}>
            {estimacion.numero}
          </option>
        )),
      ];
    }
  }

  return (
    <select
      value={selected}
      className="form-control"
      onChange={(e) => setSelected(e.target.value)}
    >
      {renderEstimaciones()}
    </select>
  );
};

export default SelectEstimacion;

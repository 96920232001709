import React, { useContext } from "react";
import { ConceptosContext } from "../../context/ConceptosContext";

const PreviewConceptoEstimacion = ({ concepto }) => {
  const { deleteConcepto } = useContext(ConceptosContext);
  const { clave, estimado } = concepto;

  return (
    <div className="row border py-2">
      <div className="col">{clave}</div>
      <div className="col">{estimado}</div>
      <div className="col">
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => deleteConcepto(concepto.idConcepto)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default PreviewConceptoEstimacion;

import React, { useContext, useEffect, useState } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import { FolderContext } from "../../context/FolderContext";
import { ModalContext } from "../../context/ModalContext";
import { Document, Page, pdfjs } from "react-pdf";
import img from "../../assets/image.svg";
import ppt from "../../assets/pptx.svg";
import pdf from "../../assets/pdf.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AdjuntoCard = ({ adjunto }) => {
  const [outline, setOutline] = useState("");
  const [source, setSource] = useState("");
  const [src, setSrc] = useState("");

  const adjuntosContext = useContext(AdjuntosContext);

  const { platform } = useContext(CapacitorContext);

  // const platform =

  const {
    deleteAdjunto,
    updateAdjunto,
    setSingleAdjunto,
    setPropertyAdjunto,
    getAdjuntoProyecto,
  } = adjuntosContext;

  const { modalComponent } = useContext(ModalContext);

  const { proyecto } = useContext(ProyectosContext);

  const { getFolder } = useContext(FolderContext);

  const focused = adjuntosContext.adjunto;

  const idProyecto = proyecto ? proyecto.idProyecto : null;

  useEffect(() => {
    setupFileExtension();
    getAdjuntoProyecto(idProyecto, adjunto.idAdjunto, setSrc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  const isFocused = () => {
    if (focused && focused !== null) {
      return focused.idAdjunto === adjunto.idAdjunto;
    }
  };

  const setupFileExtension = () => {
    let currentOutline;
    let currentSource;
    switch (adjunto.tipo) {
      case "pdf":
        currentOutline = "pdf";
        currentSource = pdf;
        break;
      case "pptx":
        currentOutline = "ppt";
        currentSource = ppt;
        break;
      default:
        currentOutline = "image";
        currentSource = img;
    }
    setOutline(currentOutline);
    setSource(currentSource);
  };

  const confirmDelete = () => {
    modalComponent(
      "Eliminar Adjunto",
      <div>
        <p>¿Deseas eliminar {adjunto.nombre}?</p>
        <p>Esta acción NO puede deshacerse.</p>
        <button className="btn btn-danger" onClick={handleDeleteFile}>
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </div>
    );
  };

  const handleDeleteFile = () => {
    deleteAdjunto(idProyecto, adjunto.idAdjunto, getFolder);
  };

  const handleUpdateFile = () => {
    updateAdjunto(adjunto.idAdjunto, adjunto.nombre, getFolder);
  };

  const renameFile = () => {
    modalComponent(
      "Renombrar",
      <div className="conainer-fliud">
        <input
          type="text"
          className="form-control"
          value={adjunto.nombre}
          onChange={(e) =>
            setPropertyAdjunto(adjunto.idAdjunto, "nombre", e.target.value)
          }
        />
      </div>,
      getFolder,
      handleUpdateFile
    );
  };

  const renderOptions = () => {
    // if (showOptions) {
      if (platform === "web") {
        return (
          <div className="dropdown-menu">
            <button
              className="dropdown-item btn btn-link btn-sm text-secondary text-start"
              onClick={renameFile}
            >
              <i className="fa fa-edit"></i> Renombrar
            </button>
            <button
              className="dropdown-item btn btn-link btn-sm text-danger text-start"
              onClick={confirmDelete}
            >
              <i className="fa fa-trash"></i> Eliminar
            </button>
          </div>
        );
      } else {
        return (
          <div className=" dropdown-menu">
            <button
              className="btn  btn-sm text-start dropdown-item"
              style={{ fontWeight: "400px" }}
              onClick={confirmDelete}
            >
              <i className="fa fa-download" style={{ color: "##db8c61" }}></i>{" "}
              Descargar
            </button>
            <button className="btn btn-sm text-start dropdown-item" onClick={renameFile}>
              <i className="fa fa-edit" style={{ color: "##db8c61" }}></i>{" "}
              Editar Nombre
            </button>
            <button className="btn btn-sm text-start dropdown-item" onClick={confirmDelete}>
              <i className="fa fa-trash" style={{ color: "##db8c61" }}></i>{" "}
              Borrar
            </button>
          </div>
        );
      }
    // }
  };

  const handleClick = () => {
    setSingleAdjunto(adjunto);
  };

  const renderImage = () => {
    if (outline === "pdf") {
      return (
        <div className="adjunto-card-img" onClick={handleClick}>
          {src !== "" && (
            <Document file={src}>
              <Page pageNumber={1} className="m-auto" />
            </Document>
          )}
        </div>
      );
    } else if (outline !== "image" || src === "") {
      return (
        <div
          className="adjunto-card-img d-flex justify-content-center align-items-center"
          onClick={handleClick}
        >
          <i className="fas fa-file fa-3x" />
        </div>
      );
    }
    return (
      <div
        className="adjunto-card-img"
        style={{
          backgroundImage: `url(${src})`,
        }}
        onClick={handleClick}
      />
    );
  };

  const renderContent = () => {
    if (platform === "web") {
      return (
        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
          <div
            className={`card adjunto-card br-20 outline-${
              isFocused() ? "selected" : outline
            }`}
          >
            {renderImage()}
            <div className="adjunto-card-footer">
              <div className="dropdown">
                <button
                  data-bs-toggle="dropdown" 
                  aria-expanded="false"
                  className="btn btn-link btn-options text-dark"
                >
                  <i 
                    className="fa fa-ellipsis-v" 
                    style={{ color: "#db8c61", fontSize: "20px" }}
                  />
                </button>
                {renderOptions()}
              </div>

              <div className="row align-items-center mx-0">
                <div className="col-3 pr-0">
                  <img
                    src={`${source}`}
                    alt="Archivo"
                    className="mw-100 w-100 d-block m-auto"
                  />
                </div>
                <div className="col-8 overflow-hidden">
                  <p className="adjunto-card-title text-start bold mb-0">
                    {adjunto.nombre}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-6 px-1 col-md-6 col-lg-4 col-xl-3 mb-3">
          <div
            className={`card adjunto-card br-20 outline-${
              isFocused() ? "selected" : outline
            }`}
          >
            {renderImage()}

            <div className="adjunto-card-footer col-12">
              <div className="row align-items-center mx-0">
                {/* <div className="col-3 pr-0">
                <img
                  src={`${source}`}
                  alt="Archivo"
                  className="mw-100 w-100 d-block m-auto"
                />
              </div> */}
                <div className="col-8 overflow-hidden">
                  <p className="adjunto-card-title text-start bold mb-0">
                    {adjunto.nombre}
                  </p>
                </div>

                <div className="col-2 dropdown">
                  <button
                    className="btn btn-link text-dark btn-options-container"
                    type="button"
                    data-bs-toggle="dropdown" 
                    aria-expanded="false"
                  >
                    <i
                      className="fa fa-ellipsis-v"
                      style={{ color: "#db8c61", fontSize: "20px" }}
                    />
                  </button>
                  {renderOptions()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return renderContent();
};

export default AdjuntoCard;

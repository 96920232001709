import React from "react";
import { FILES_ENDPOINT } from "../../utils";

const ObraDetails = ({ obra, open }) => {
  return (
    <div className="container-fluid obra-details d-flex justify-content-center" style={{ maxWidth: 150 }}>
      <img
        src={`${FILES_ENDPOINT}/${obra.idAdjunto}.png`}
        className={`profile-image ${open ? "large" : "small"}`}
        alt=""
        style={{ borderRadius: "50%" }}
      />
    </div>
  );
};

export default ObraDetails;

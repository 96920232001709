import React, { useContext, useEffect } from "react";
import Home from "./Home";
import Login from "./Login";
//import { toggleAlertCard } from "./actions/alertasActions";
import Recuperar from "./views/Recuperar";
import { AuthContext } from "./context/AuthContext";
import { MenuContext } from "./context/MenuContext";
import { ProyectosContext } from "./context/ProyectosContext";
import { CapacitorContext } from "./context/CapacitorContext";
import Modal from "./components/global/Modal";
import ErrorAlert from "./components/global/ErrorAlert";
import SuccessAlert from "./components/global/SuccessAlert";
import { Routes, Route, useNavigate } from "react-router-dom";
import { AppConfigContext } from "./context/AppConfigContext";
import { setupColor, setupTitle } from "./utils";
import AdjuntoView from "./components/folder/AdjuntoView";
import MobileModal from "./components/folder/MobileModal";

const Main = () => {
  const navigate = useNavigate();
  const { hideOptions } = useContext(ProyectosContext);
  const { user, logout, userLoggedIn } = useContext(AuthContext);
  const { showSettings, toggleSettings } = useContext(MenuContext);
  const { primary, app_name, app_tagline } = useContext(AppConfigContext);
  const { platform, setupDevice, setupPlatform, setupPushNotifications, fcm_token } = useContext(CapacitorContext);

  useEffect(() => {
    setupDevice();
    setupPlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setupColor(primary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primary]);

  useEffect(() => {
    setupTitle(app_name, app_tagline);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app_name, app_tagline]);

  useEffect(() => {
    if (platform !== null && platform !== "web") {
      setupPushNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  useEffect(() => {
    if(platform === 'web' && platform !== null){
      if (user === null ) {
        userLoggedIn();
      }
      window.onclick = hideModals;
    } 
    
    if(platform !== 'web' && platform !== null) {
      if(user === null && fcm_token !== null) {
        userLoggedIn();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, fcm_token, platform]);


  useEffect(() => {
    if (logout) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  const hideModals = (event) => {
    const { target } = event;
    if (!Array.from(target.classList).includes("custom-modal")) {
      hideOptions();
      /*if (showAlertasCard) {
        toggleAlertCard();
      }
      */
      if (showSettings) {
        toggleSettings();
      }
    }
  };

  const renderContent = () => {
    if (user !== null) {
      return <Home />;
    }
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/recuperar" element={<Recuperar />} />
      </Routes>
    );
  };

  return (
    <div className={platform !== "web" ? "ionic" : ""}>
      {renderContent()}
      {platform === "web" ? <Modal /> : <MobileModal />}
      <ErrorAlert />
      <SuccessAlert />
      <AdjuntoView />
    </div>
  );
};

export default Main;

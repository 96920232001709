import React, { useEffect, useState } from "react";

const SectionForm = ({
  section,
  postItem,
  idProyecto,
  idChecklist,
  handleCancel,
  idChecklistSection,
}) => {
  const [nombre, setNombre] = useState("");

  useEffect(() => {
    if (section && section !== null && nombre === "") {
      setNombre(section.nombre);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nombre]);

  const onSubmit = (e) => {
    e.preventDefault();
    postItem(idProyecto, { nombre, idChecklist, idChecklistSection });
  };

  return (
    <form onSubmit={onSubmit}>
      <label className="h6 text-capitalize">Nombre</label>
      <input
        type="message"
        value={nombre}
        className="form-control mb-3"
        onChange={(e) => setNombre(e.target.value)}
      />
      <div className="row mt-2 align-items-center">
        <div className="col-6">
          <button
            type="button"
            className="btn text-muted w-100"
            onClick={handleCancel}
          >
            CANCELAR
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-primary w-100" type="submit">
            GUARDAR
          </button>
        </div>
      </div>
    </form>
  );
};

export default SectionForm;

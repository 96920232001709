import React, { useContext, useEffect, useState } from "react";
import { RecorridosContext } from "../context/RecorridosContext";
import { runTour } from "../utils/tours";
import fullscreen from "../assets/img/fullscreen.png";
import { MenuContext } from "../context/MenuContext";
import windowed from "../assets/img/windowed.png";
import collapse from "../assets/img/collapse.png";
import expand from "../assets/img/expand.png";
import { useParams } from "react-router-dom";
import right from "../assets/img/right.png";
import minus from "../assets/img/minus.png";
import pause from "../assets/img/pause.png";
import plus from "../assets/img/pause.png";
import play from "../assets/img/play.png";
import left from "../assets/img/left.png";
import down from "../assets/img/down.png";
//import { S3_ENDPOINT } from "../utils";
import up from "../assets/img/up.png";
import "../css/style.css";
import { BASE_URL } from "../actions/types";

//const base_url = `${S3_ENDPOINT}/tours/files/`;

const vendor = [
  { src: "es5-shim.js" },
  { src: "eventShim.js" },
  { src: "classList.js" },
  { src: "requestAnimationFrame.js" },
  { src: "screenfull.min.js", property: "screenfull" },
  { src: "bowser.min.js", property: "bowser" },
  { src: "marzipano.js", property: "Marzipano" },
];

const Recorrido = ({ idProyecto }) => {
  const { idTour } = useParams();
  const [ready, setReady] = useState(false);

  const { setSelected } = useContext(MenuContext);

  const { recorrido, getRecorrido, clearRecorrido } =
    useContext(RecorridosContext);

  useEffect(() => {
    getRecorrido(idProyecto, idTour);
    setSelected({ name: "recorridos" });
    handleRecorrido();
    return clearRecorrido;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idTour]);

  useEffect(() => {
    if (recorrido !== null && ready) {
      setReady(false);
      runTour(idProyecto, idTour, BASE_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorrido, ready]);

  const handleRecorrido = () => {
    let promises = [];
    vendor.forEach((src) => {
      promises.push(
        new Promise((resolve, reject) => {
          const script = document.createElement("script");
          script.src = `${BASE_URL}/tours/vendor/${src.src}`;
          script.async = true;
          script.onload = () => {
            resolve();
          };
          document.body.appendChild(script);
        })
      );
    });
    promises.push(
      new Promise((resolve, reject) => {
        const data = document.createElement("script");
        data.src = `${BASE_URL}/tours/${idProyecto}/${idTour}/app-files/data.js`;
        data.onload = () => {
          resolve();
        };
        document.body.appendChild(data);
      })
    );
    Promise.all(promises).then(() => {
      setReady(true);
    });
  };

  const renderRecorrido = () => {
    if (recorrido !== null) {
      return recorrido.map((scene) => (
        <span
          key={scene}
          className="scene d-flex align-items-center"
          data-id={`${scene}/`}
        >
          <li className="text">{scene}</li>
        </span>
      ));
    }

    return <div className="spinner-border"></div>;
  };

  return (
    <div
      className="container-fluid multiple-scenes"
      style={{ overflowX: "hidden" }}
    >
      <div id="pano"></div>

      <div id="sceneList" className="card border-0 shadow">
        <p
          className="pt-2 pb-2 mb-0 bold"
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          Escenas
        </p>
        <ul className="scene-list">{renderRecorrido()}</ul>
      </div>

      <div id="titleBar" className="border-0 shadow">
        <div className="container">
          <div className="row">
            <div className="col col-2 ps-1">
              <div id="autorotateToggle" className="mt-1">
                <img className="icon off" src={play} alt="" />
                <img className="icon on" src={pause} alt="" />
              </div>
            </div>
            <div className="col col-8">
              <h1 className="sceneName mt-1">Recorrido 360</h1>
            </div>
            <div className="col col-2 pr-1 text-end">
              <div id="sceneListToggle" className="mt-1 ms-auto">
                <img className="icon on" src={expand} alt="" />
                <img className="icon off" src={collapse} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <span id="fullscreenToggle">
        <img className="icon off" src={fullscreen} alt="" />
        <img className="icon on" src={windowed} alt="" />
      </span>

      <span id="viewUp" className="viewControlButton viewControlButton-1">
        <img className="icon" src={up} alt="" />
      </span>
      <span id="viewDown" className="viewControlButton viewControlButton-2">
        <img className="icon" src={down} alt="" />
      </span>

      <span id="viewLeft" className="viewControlButton viewControlButton-3">
        <img className="icon" src={left} alt="" />
      </span>
      <span id="viewRight" className="viewControlButton viewControlButton-4">
        <img className="icon" src={right} alt="" />
      </span>
      <span id="viewIn" className="viewControlButton viewControlButton-5">
        <img className="icon" src={plus} alt="" />
      </span>
      <span id="viewOut" className="viewControlButton viewControlButton-6">
        <img className="icon" src={minus} alt="" />
      </span>
    </div>
  );
};

export default Recorrido;

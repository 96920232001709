import React, { createContext, useContext, useReducer } from "react";
import { SELECT_TABS, SET_SELECTED, TOGGLE_SETTINGS } from "../types/menu";
import { homeTabs, obraTabs } from "../utils/menu";
import MenuReducer from "../reducers/MenuReducer";
import { AppConfigContext } from "./AppConfigContext";

const initialState = { tabs: [], selected: "", showSettings: false };

export const MenuContext = createContext(initialState);

export const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MenuReducer, initialState);

  const appConfig = useContext(AppConfigContext);

  const selectTabs = (idProyecto) => {
    if (!idProyecto) {
      dispatch({ type: SELECT_TABS, payload: homeTabs(appConfig) });
    } else {
      let tabs = obraTabs(idProyecto, appConfig);
      dispatch({ type: SELECT_TABS, payload: tabs });
    }
  };

  const setSelected = (tab) => {
    dispatch({ type: SET_SELECTED, payload: tab.name });
  };

  const toggleSettings = () => {
    dispatch({ type: TOGGLE_SETTINGS });
  };
  return (
    <MenuContext.Provider
      value={{ ...state, selectTabs, setSelected, toggleSettings }}
    >
      {children}
    </MenuContext.Provider>
  );
};

import React, { useEffect, useState, useContext, createRef } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import { ModalContext } from "../../context/ModalContext";
import { ItemsContext } from "../../context/ItemsContext";
import DateRange from "../global/DateRange";
import UserItem from "../users/UserItem";
import CheckBox from "../obra/CheckBox";
import moment from "moment";
import ItemEstadoForm from "./ItemEstadoForm";
import { RevisionesContext } from "../../context/RevisionesContext";
import "../../css/ItemRow.css"

const ItemRow = ({
  item,
  permiso,
  idProyecto,
  handleEdit,
  completeItem,
  handleUpload,
  handleCallback,
  handleComments,
}) => {
  const [showName, setShowName] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [itemIsOnRev, setItemIsOnRev] = useState();
  const [completado, setCompletado] = useState(false);

  const { proyecto } = useContext(ProyectosContext);
  const { modalComponent, confirm } = useContext(ModalContext);
  const { setItem, deleteItem, itemsByRevision } = useContext(ItemsContext);
  const { idItem, nombre, Adjunto } = item;
  const { setSingleAdjunto } = useContext(AdjuntosContext);
  const { currentRevision, isItemOnRevision } = useContext(RevisionesContext);


  useEffect(() => {
    setShowName(false);
  }, []);

  useEffect(() => {
    // console.log(currentRevision);
    if(currentRevision) {
      validateItemOnRev();
    } else {
      setCurrentItem(item);
    }
  }, [currentRevision, itemsByRevision]);

  const validateItemOnRev = () => {
    const itemRev = itemsByRevision.find(obj => {
      return obj.idItem === item?.idItem;
    });

    if(itemRev) {
      setCurrentItem(itemRev);
      setItemIsOnRev(true);
      itemRev.completedAt ? setCompletado(true) : setCompletado(false);

    } else {
      setCurrentItem(item);
      setItemIsOnRev(false);
    }
  }

  const handleDeleteItem = () => {
    confirm(
      `¿Estás seguro que deseas eliminar el item ${item.nombre}? Esta acción no puede deshacerse.`,
      () => deleteItem(proyecto.idProyecto, item.idItem, handleCallback)
    );
  };

  const handleDisplayName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowName(!showName);
  };

  const handleClick = () => {
    setSingleAdjunto(Adjunto);
  };

  const handleStatusInfo = () => {
    setItem(currentItem);
    modalComponent("Estado", <ItemEstadoForm idProyecto={idProyecto} />);
  };

  const renderAdjunto = () => {
    if(currentRevision) {
      return (
        <button
          className="btn me-2 btn-outline-primary"
          onClick={() => handleUpload(item)}
        >
          <i className="fa fa-image"></i>
        </button>
      );
    }

    // if (Adjunto && Adjunto !== null) {
    //   return (
    //     <button className="btn btn-outline-primary me-2" onClick={handleClick}>
    //       <img
    //         src={`${FILES_ENDPOINT}/${Adjunto?.idAdjunto}.${Adjunto?.tipo}`}
    //         style={{ height: "15px" }}
    //         alt="Evidencia"
    //       />
    //     </button>
    //   );
    // }
  };

  const renderUsuario = () => {
    if (item.responsable !== null) {
      return (
        <div className="px-2">
          <UserItem user={item.responsable} />
        </div>
      );
    }
  };

  const renderComentarios = () => {
    if(currentRevision) {
      return (
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            handleComments(item, idItem)
          }}
        >
          <i className="fa fa-comment"></i>
        </button>
      );
    }

  };

  const renderFechaLimite = () => {
    const fecha_inicio = moment(item.fecha_inicio);
    const fecha_limite = moment(item.fecha_fin);
    let completedOnTime = true;

    if(itemIsOnRev) {
      completedOnTime = fecha_limite > currentItem.completedAt;
    }

    if (fecha_limite.isValid() && fecha_inicio.isValid()) {
      return (
        <DateRange 
          end_date={item.fecha_fin} 
          start_date={item.fecha_inicio} 
          onTime={completedOnTime}
        />
      );
    }
  };

  const renderEdit = () => {
    if (["admin", "coordinador"].includes(permiso)) {
      return (

        <button
          onClick={() => handleEdit(item)}
          className={`btn btn-outline-primary me-2 
          ${currentRevision ? 'd-none' : null}`}
        >
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };

  const renderNameButton = () => {
    if (String(nombre).length > 80) {
      return (
        <button
          className="btn btn-sm btn-link px-0 text-muted mb-3 show-tablet"
          onClick={handleDisplayName}
        >
          {showName ? "Ocultar" : "Ver completo"}
        </button>
      );
    }
  };

  const renderDelete = () => {
    if (["admin", "coordinador"].includes(permiso)) {
      return (
        <button
          onClick={handleDeleteItem}
          className={`btn ms-2 btn-outline-danger
          ${currentRevision ? 'd-none' : null}`}
        >
          <i className="fa fa-trash"></i>
        </button>
      );
    }
  };

  const renderItemEstatus = () => {
    if(currentItem?.estado === null){
      return "Estado"
    } else {
      if(currentItem?.estado < 7){
        return(
          <>
            <span className="hide-mobile">Requiere Mantenimiento: </span> 
            <span>{currentItem?.estado}</span>
          </>
        )
      } else {
        return(
          <>
          <span className="hide-mobile">Buen Estado: </span> 
          <span>{currentItem?.estado}</span>
        </>
        )
      }

    }
  }

  const renderEstatus = () => {
    return (
      <div className="col-4 col-lg-8 align-self-center" style={{maxWidth: '300px'}}>
        {["admin", "coordinador"].includes(permiso) ? (
          <button
            onClick={handleStatusInfo}
            className={`btn btn-${
              currentItem?.estado === null
                ? "outline-primary"
                : currentItem?.estado < 7
                ? "warning"
                : "success"
            } w-100 estado__btn`}
          >
            <i
              className={`fa fa-${
                currentItem?.estado > 6 ? "check" : "info"
              }-circle me-2`}
            ></i>{" "}
            {renderItemEstatus()}
          </button>
        ) : (
          <span>{currentItem?.estado}</span>
        )}
      </div>
    );
  };

  return (
    <div className="row align-items-center p-2 hover-light border-bottom">
      <div className="col-1">
        <CheckBox 
          checked={completado} 
          modifier={() =>{
            completeItem(idItem, completado);
            setItem(item);
          }} 
        />
      </div>
      <div className="col-11">
        <div className="row align-items-center mb-2 justify-content-between">
          <div className="col-12 col-md-7 col-lg-6">
            <p
              className={`mb-0 ${showName ? "" : "item-overflow-ellipsis"}`}
            >
              {nombre}
            </p>
            {renderNameButton()}

            <div className="row align-items-center mb-3">
              <div className="col-12 fechas__label info__labels mb-3">
                {renderFechaLimite()}
              </div>
              
              <div className="col-12 usuario__label info__labels">{renderUsuario()}</div>
            </div>
            
          </div>

          <div className="col-12 col-md-5 col-lg-5 text-end">
            <div className="row">
              {renderEstatus()}

              <div className="col-8">
                {renderEdit()}
                {renderAdjunto()}
                {renderComentarios()}
                {renderDelete()}
              </div>
            </div>
             
          </div>

          {
            itemIsOnRev && currentItem.updatedAt !== null ? (
              <div className="col-12">
                <span 
                  className=" mt-3 d-flex flex-column 
                  justify-content-evenly align-items-center" 
                  style={{fontSize: '12px'}}
                >
                  <b>Ultima Actualización:</b>

                  <div>
                    {moment(currentItem?.updatedAt).format("DD MMM YYYY")}
                    <i className="fa fa-calendar-alt text-primary ms-2"></i>
                  </div>

                </span>
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  );
};

export default ItemRow;

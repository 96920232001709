import React, { useEffect, useState, useContext } from "react";
import { ProductsContext } from "../../../context/ProductsContext";
import { ProyectosContext } from "../../../context/ProyectosContext";

import "../product.css";
import SearchableSelect from "../../global/SearchableSelect";

const ProductForm = ({
    productToEdit,
    parentId,
    projectId,
    handleCancel
}) => {
    const { createProduct, updateProduct, addProductInsumo } = useContext(ProductsContext);
    const { getAllProyectos, proyectos } = useContext(ProyectosContext);
    const [product, setProduct] = useState({});
    const [skuError, setSkuError] = useState(false);

    const initialProductState = {
        sku: "ABC-123",
        article: "Articulo Sample",
        unit: "Cada uno",
        quantity: 0,
        orders: 0,
        maximum: 0,
        minimum: 0,
        unitPrice: 0,
        amount: 0,
    };

    useEffect(() => {
        // Edit product
        if (productToEdit) {
            setProduct(productToEdit);
        }
        // New child product (insumo)
        else if (!productToEdit && parentId) {
            setProduct(initialProductState);
        } else if (!parentId) {
            // Create new product
            setProduct({
                ...initialProductState,
                ...(projectId ? { idProyecto: projectId } : {}),
            });
        }
        getAllProyectos();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "sku") {
            const regex = /^[a-zA-Z0-9-]*$/;
            if (!regex.test(value)) {
                setSkuError(true);
            } else {
                setSkuError(false);
            }
        }
        setProduct({ ...product, [name]: value });
    };

    const handleProjectChange = (selectedProject) => {
        handleChange({ target: { name: "idProyecto", value: selectedProject.value } });
    };

    const displayProjectSelector = () => {
        if (!parentId && proyectos && proyectos.length > 0) {
            return (
                <div className="mb-4">
                    <label>Proyecto</label>
                    <SearchableSelect
                        className="form-control bg-light py-2"
                        items={proyectos}
                        itemText={"nombre"}
                        itemValue={"idProyecto"}
                        modifier={handleProjectChange}
                        value={product.idProyecto}
                    />

                </div>
            );
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!product) {
            return;
        }

        //update
        if (productToEdit) {
            updateProduct(product);
            //create
        } else if (!parentId) {
            createProduct(product);
        } else {
            addProductInsumo(parentId, product);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="form product-form">
            {displayProjectSelector()}
            <label>SKU:</label>
            <div className="mb-3">
                <input
                    className={`form-control bg-light py-2 ${skuError ? "is-invalid" : ""}`}
                    type="text"
                    name="sku"
                    value={product.sku}
                    onChange={handleChange}
                />
                {skuError && (
                    <div className="invalid-feedback">
                        SKU solo debe contener letras, números y '-'
                    </div>
                )}
            </div>
            <label>Articulo:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="text"
                name="article"
                value={product.article}
                onChange={handleChange}
            />
            <label>Unidad:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="text"
                name="unit"
                value={product.unit}
                onChange={handleChange}
            />

            <label>Precio Unitario ($ PU):</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="number"
                name="unitPrice"
                value={product.unitPrice}
                onChange={handleChange}
            />

            <label>Máximo:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="number"
                name="maximum"
                value={product.maximum}
                onChange={handleChange}
            />
            <label>Mínimo:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="number"
                name="minimum"
                value={product.minimum}
                onChange={handleChange}
            />

            <div className="w-100 d-flex mt-3">
                <button className="btn me-1 w-50" onClick={handleCancel}>
                    Cancelar
                </button>
                <button
                    className="btn btn-primary ms-1 w-50"
                    type="submit"
                    disabled={skuError}
                >
                    {productToEdit ? "Editar Producto" : (parentId ? "Agregar Insumo" : "Agregar Producto")}
                </button>
            </div>
        </form>
    );
};

export default ProductForm;

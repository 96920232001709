import React, { useContext, useEffect } from "react";
import ProductTable from "../components/product/ProductTable/ProductTable";
import ProductFilters from "../components/product/ProductFilters";
import ProductsHandler from "../utils/products/ProductsHandler";
import "../css/almacen.css";
import { MenuContext } from "../context/MenuContext";

const Almacen = ({ idProyecto }) => {

  const { selectTabs, setSelected } = useContext(MenuContext);

  useEffect(() => {
    selectTabs();
    setSelected({ name: "almacen" })
  }, []);
  
  const {
    handleCreateProduct,
  } = ProductsHandler({ idProyecto: idProyecto });

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between mb-4 align-items-bottom">
        <ProductFilters />
        <div className="mt-2">
          <button
            className="btn btn-primary px-3 me-2 h-100"
            onClick={handleCreateProduct}
          >
            <i className="fa fa-plus"></i>
          </button>
          <button
            className="btn btn-primary px-3 h-100"
            onClick={handleCreateProduct}
          >
            Agregar Item
          </button>
        </div>
      </div>
      <ProductTable
        projectId={idProyecto}
      />
    </div>
  );
}

export default Almacen;

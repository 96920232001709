import React, { useContext, useEffect } from "react";
import { MenuContext } from "../context/MenuContext";
import { AppConfigContext } from "../context/AppConfigContext";
import DynamicForm from "../components/global/DynamicForm";
import { ModalContext } from "../context/ModalContext";
import AdjuntosService from "../services/AdjuntosService";

const settings = [
  {
    order: 1,
    id: "navbar_logo",
    type: "file",
    label: "Logo",
  },
  {
    order: 2,
    id: "app_name",
    type: "text",
    label: "Nombre de App",
  },
  {
    order: 3,
    id: "app_tagline",
    type: "text",
    label: "Slogan",
  },
  {
    order: 4,
    id: "primary",
    type: "color",
    label: "Color Primario",
  },
  /*{
    order: 4,
    id: "secondary",
    type: "color",
    label: "Color Secundario",
  },
  {
    order: 5,
    id: "accent",
    type: "color",
    label: "Color de Acento",
  },*/
];

const Ajustes = () => {
  const appconfig = useContext(AppConfigContext);
  const { getAppConfig, setAppConfig, saveAppConfig, S3_ENDPOINT } = appconfig;
  const { selectTabs, setSelected } = useContext(MenuContext);
  const { success } = useContext(ModalContext);

  useEffect(() => {
    selectTabs();
    setSelected({ name: "ajustes" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    const promises = [];
    settings.forEach(({ id }) => {
      let value = appconfig[id];
      if (typeof value === "object" && id === "navbar_logo") {
        let formData = AdjuntosService.getFormData(value);
        promises.push(
          new Promise((resolve, reject) => {
            AdjuntosService.postAdjunto(formData).then((res) => {
              const { adjunto } = res.data;
              value = `${adjunto.idAdjunto}.${adjunto.tipo}`;
              saveAppConfig(id, value).then(resolve);
            });
          })
        );
      } else {
        promises.push(saveAppConfig(id, value));
      }
    });
    Promise.all(promises).then(() => {
      success("Ajustes guardados.");
      getAppConfig();
    });
  };

  const renderAjustes = () => {
    if (appconfig !== null) {
      return (
        <DynamicForm
          hideButtons
          layout="inline"
          object={appconfig}
          questions={settings}
          base_url={`${S3_ENDPOINT}/files/adjuntos`}
          modifier={setAppConfig}
          saveAction={handleSubmit}
        />
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="card p-4 shadow">
        <div className="col-12 col-md-8">
          {renderAjustes()}
          <button onClick={handleSubmit} className="btn btn-primary mt-4">
            <i className="fa fa-save me-2"></i>Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Ajustes;

import React, { createContext, useContext, useReducer } from "react";
import ClientesReducer from "../reducers/ClientesReducer";
import AdjuntosService from "../services/AdjuntosService";
import ClientesService from "../services/ClientesService";
import {
  CLIENTE_RECIBIDO,
  CLIENTES_RECIBIDOS,
  SET_PROPERTY_CLIENTE,
  USUARIOS_CLIENTE_RECIBIDOS,
} from "../types/clientes";

import { ModalContext } from "./ModalContext";

const initialState = {
  clientes: null,
  cliente: null,
  usuarios: null,
  usuario: null,
  obras: null,
  sinCliente: null,
  obra: null,
};

const clienteSchema = { idCliente: "nuevo", nombre: "" };

export const ClientesContext = createContext(initialState);

export const ClientesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClientesReducer, initialState);
  const { success, alert, clearModal } = useContext(ModalContext);

  const getClientes = () => {
    ClientesService.getClientes()
      .then((res) => {
        const { clientes } = res.data;
        dispatch({ type: CLIENTES_RECIBIDOS, payload: clientes });
      })
      .catch(alert);
  };

  const getCliente = (idCliente) => {
    ClientesService.getCliente(idCliente)
      .then((res) => {
        const { cliente } = res.data;
        dispatch({ type: CLIENTE_RECIBIDO, payload: cliente });
      })
      .catch(alert);
  };

  const getUsuariosCliente = (idCliente) => {
    ClientesService.getUsuariosCliente(idCliente).then((res) => {
      const { usuarios } = res.data;
      dispatch({ type: USUARIOS_CLIENTE_RECIBIDOS, payload: usuarios });
    });
  };

  const createCliente = () => {
    dispatch({
      type: CLIENTE_RECIBIDO,
      payload: clienteSchema,
    });
  };

  const clearCliente = () => {
    dispatch({ type: CLIENTE_RECIBIDO, payload: null });
  };

  const editCliente = (cliente) => {
    dispatch({ type: CLIENTE_RECIBIDO, payload: cliente });
  };

  const setPropiedadCliente = (key, value) => {
    dispatch({ type: SET_PROPERTY_CLIENTE, payload: { key, value } });
  };

  const postCliente = (cliente, callback) => {
    let service = ClientesService.putCliente;
    if (isNaN(cliente.idCliente)) {
      service = ClientesService.postCliente;
    }
    const promises = [];
    if (cliente.file && cliente.file !== null) {
      let formData = AdjuntosService.getFormData(cliente.file);
      promises.push(
        new Promise((resolve, reject) => {
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { idAdjunto } = res.data;
            cliente.idAdjunto = idAdjunto;
            delete cliente.file;
            resolve();
          });
        })
      );
    }
    Promise.all(promises).then(() => {
      service(cliente)
        .then(() => {
          clearModal();
          getClientes();
          clearCliente();
          success("Cliente actualizado con éxito.");
          if (typeof callback === "function") {
            callback();
          }
        })
        .catch(alert);
    });
  };

  const deleteCliente = (idCliente) => {
    ClientesService.deleteCliente(idCliente)
      .then(() => {
        getClientes();
        success("Cliente eliminado con éxito.");
      })
      .catch(alert);
  };

  const createUsuarioCliente = (usuario, callback) => {
    ClientesService.postUsuarioCliente(usuario).then(() => {
      success("Usuario guardado.");
      if (typeof callback === "function") {
        callback();
      }
      clearModal();
    });
  };

  const deleteUsuarioCliente = (idCliente, idUsuario, callback) => {
    ClientesService.deleteUsuarioCliente(idCliente, idUsuario).then(() => {
      success("Usuario eliminado.");
      if (typeof callback === "function") {
        callback();
      }
      clearModal();
    });
  };

  return (
    <ClientesContext.Provider
      value={{
        ...state,
        getCliente,
        getClientes,
        editCliente,
        postCliente,
        clearCliente,
        deleteCliente,
        createCliente,
        getUsuariosCliente,
        setPropiedadCliente,
        createUsuarioCliente,
        deleteUsuarioCliente,
      }}
    >
      {children}
    </ClientesContext.Provider>
  );
};

import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MenuContext } from "../context/MenuContext";
import { ClientesContext } from "../context/ClientesContext";
import { ProyectosContext } from "../context/ProyectosContext";
import ObrasCliente from "../components/clientes/ObrasCliente";
import UsuariosCliente from "../components/clientes/UsuariosCliente";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { useLocation } from "react-router-dom";
import MobileSubHeader from "../components/movil/MobileSubHeader";

const SingleCliente = () => {
  const [view, setView] = useState("proyectos");
  const { idCliente } = useParams();
  const { editProyecto } = useContext(ProyectosContext);
  const { setSelected, selectTabs } = useContext(MenuContext);
  const { cliente, getCliente, clearCliente, usuarios, getUsuariosCliente } = useContext(ClientesContext);
  const [query, setQuery] = useState("");
  
  const { state } = useLocation();

  useEffect(() => {
    getCliente(idCliente);
    selectTabs();
    getUsuariosCliente(idCliente);
    return clearCliente;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cliente !== null) {
      setSelected({ name: cliente.nombre });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliente]);


  const renderContent = () => {
    if (view === "proyectos") {
      return (
        <ObrasCliente
          cliente={cliente}
          idCliente={idCliente}
          editProyecto={editProyecto}
        />
      );
    }
    return <UsuariosCliente idCliente={idCliente} usuarios={usuarios} />;
  };

  return (
    <div className="container-fluid view-container px-0">
      <div className="row">
        <div className="col-md-6 hide-mobile">
          <h1 className="h2 mb-4 bold text-capitalize">
            {state?.clientName ? state?.clientName : cliente?.nombre}
          </h1>
        </div>

        <MobileSubHeader 
          title={state?.clientName ? state?.clientName : cliente?.nombre}
          subtitle={'Cliente'}
        >

        </MobileSubHeader>
        

        <div className="col-md-6">
          <IonSegment value={view} className="mb-3">
            <IonSegmentButton
              value="proyectos"
              onClick={() => setView("proyectos")}
            >
              <IonLabel>Proyectos</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="usuarios"
              onClick={() => setView("usuarios")}
            >
              <IonLabel>Usuarios</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
      </div>
      
      {renderContent()}
    </div>
  );
};

export default SingleCliente;

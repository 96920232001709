import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getValue } from "../../utils";

const PreciarioHeader = ({ editMode, showEstimaciones }) => {
  const appconfig = useContext(AppConfigContext);
  const renderHeaders = () => {
    if (editMode !== "partidas") {
      return (
        <>
          <th>Cantidad</th>
          <th>$ PU</th>
          <th>Importe</th>
        </>
      );
    }
  };

  const renderEstimacion = () => {
    if (
      String(editMode).includes("conceptos-") &&
      editMode !== "conceptos-extra"
    ) {
      return (
        <>
          <th className="border-0 ps-3">Estimado Anterior</th>
          <th className="border-0">Estimado Actual</th>
          <th className="border-0">$PU Estimado</th>
          <th className="border-0">Estimado</th>
          {getValue(appconfig, "supervise_estimates", "boolean") && [
            <th className="border-0">Supervisado Anterior</th>,
            <th className="border-0">Supervisado Actual</th>,
            <th className="border-0">Supervisado</th>,
          ]}
          {getValue(appconfig, "authorize_estimates", "boolean") && [
            <th className="border-0">Autorizado Anterior</th>,
            <th className="border-0">Autorizado Actual</th>,
            <th className="border-0">Autorizado</th>,
            <th className="border-0">P$ Autorizado</th>,
          ]}
          <th className="border-0">
            Importe{" "}
            {getValue(appconfig, "authorize_estimates", "boolean")
              ? "Autorizado"
              : ""}
          </th>
          <th className="border-0">Evidencia</th>
        </>
      );
    }
    if (
      showEstimaciones &&
      (editMode === null || editMode === "conceptos-estimacion")
    ) {
      return (
        <>
          <th className="border-0 ps-3 bg-secondary">Estimado</th>
          {getValue(appconfig, "supervise_estimates", "boolean") && (
            <th className="border-0 bg-secondary">Supervisado</th>
          )}
          {getValue(appconfig, "authorize_estimates", "boolean") && (
            <th className="border-0 bg-secondary">Autorizado</th>
          )}
          <th className="border-0 bg-secondary">
            Importe{" "}
            {getValue(appconfig, "authorize_estimates", "boolean")
              ? "Autorizado"
              : ""}
          </th>
          {/*<th className="border-0 bg-secondary">Evidencia</th>*/}
          <th className="border-0 bg-secondary">Acumulado</th>
          <th className="border-0 bg-secondary">Dif. Real</th>
          <th className="border-0 bg-secondary">% Ejecutado</th>
        </>
      );
    }
  };

  return (
    <thead className="border-bottom">
      <tr>
        {editMode !== "partidas" && <th className="td-short">Tipo</th>}
        <th className="min-120">Clave</th>
        <th className="nombre-concepto">Concepto</th>
        <th className="descripcion-concepto" style={{ minWidth: 400 }}>
          Descripción
        </th>
        {["partidas", "conceptos"].includes(editMode) && <th>Cronograma</th>}
        {editMode !== "partidas" && <th>Unidad</th>}
        {renderHeaders()}
        {editMode === "partidas" && <th>Acciones</th>}
        {renderEstimacion()}
      </tr>
    </thead>
  );
};

export default PreciarioHeader;

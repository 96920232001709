import React, { useContext, useEffect } from "react";
import UserCard from "../components/users/UserCard";
import { AlertasContext } from "../context/AlertasContext";
import { ModalContext } from "../context/ModalContext";
import { hideModal } from "../utils";
import moment from "moment";
import { MenuContext } from "../context/MenuContext";

const Alertas = ({ idProyecto }) => {
  const { alertas, getAlertas, deleteAlerta } = useContext(AlertasContext);
  const { modalComponent } = useContext(ModalContext);
  const { selectTabs } = useContext(MenuContext);

  useEffect(() => {
    getAlertas(idProyecto);
    selectTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (alerta) => {
    modalComponent(
      "Eliminar Alerta",
      <div>
        <p>
          ¿Eliminar alerta #{alerta.idAlerta}? Se eliminirá para todos los
          usuarios que reciben esta alerta actualmente.
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteAlerta(idProyecto, alerta.idAlerta)}
            >
              <i className="fa fa-trash"></i> Eliminar
            </button>
          </div>
          <div className="col-6 text-end">
            <button className="btn btn-link text-secondary" onClick={hideModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderAlertas = () => {
    if (Array.isArray(alertas)) {
      if (alertas.length === 0) {
        return <p>No hay alertas registradas.</p>;
      }
      return alertas.map((alerta) => (
        <div className="row mx-0 border-bottom py-1 align-items-center">
          <div className="col-3">
            {alerta.Entrada !== null ? alerta.Entrada.contenido : ""}
          </div>
          <div className="col-3 small">
            {moment(alerta.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="col-3">
            <UserCard user={alerta.Usuario !== null ? alerta.Usuario : ""} />
          </div>
          <div className="col-3">
            <button className="btn">
              <i className="fa fa-edit"></i>
            </button>
            <button className="btn" onClick={() => handleDelete(alerta)}>
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
      ));
    }
  };
  return (
    <div>
      <h1 className="bold">Alertas</h1>
      <div className="card">
        <div className="row mx-0 mb-1 bg-light bold p-2 border">
          <div className="col-3">Contenido</div>
          <div className="col-3">Creada en</div>
          <div className="col-3">Creada por</div>
          <div className="col-3">Acciones</div>
        </div>
        <div>{renderAlertas()}</div>
      </div>
    </div>
  );
};

export default Alertas;

import React, { useContext } from "react";
import { formatMonto, getValue } from "../../utils";
//import { AdjuntosContext } from "../../context/AdjuntosContext";
import { AppConfigContext } from "../../context/AppConfigContext";

const ConceptoEstimacion = ({ concepto, extra }) => {
  let {
    estimado,
    cantidad,
    //idAdjunto,
    autorizado,
    supervisado,
    //nombre_adjunto,
    importe_autorizado,
    autorizadoAcumulado,
  } = concepto;
  const appconfig = useContext(AppConfigContext);
  //const { setSingleAdjunto } = useContext(AdjuntosContext);

  const renderPorcentaje = () => {
    if (parseFloat(cantidad) > 0) {
      return `${formatMonto(
        (parseFloat(autorizado) / parseFloat(cantidad)) * 100
      )}%`;
    }
  };

  return (
    <>
      <td className="bg-secondary ps-3">
        {formatMonto(!isNaN(parseFloat(estimado)) ? estimado : 0.0)}
      </td>
      {getValue(appconfig, "supervise_estimates", "boolean") && (
        <td className="bg-secondary">
          {formatMonto(!isNaN(parseFloat(supervisado)) ? supervisado : 0.0)}
        </td>
      )}
      {getValue(appconfig, "supervise_estimates", "boolean") && (
        <td className="bg-secondary">
          {formatMonto(!isNaN(parseFloat(autorizado)) ? autorizado : 0.0)}
        </td>
      )}
      <td className="bg-secondary">
        $
        {formatMonto(
          !isNaN(parseFloat(importe_autorizado)) ? importe_autorizado : 0.0
        )}
      </td>
      {/*<td className="bg-secondary">
        {idAdjunto && idAdjunto !== null ? (
          <button
            className="btn btn-link p-0"
            onClick={() =>
              setSingleAdjunto({
                idAdjunto,
                nombre: nombre_adjunto,
                tipo: "jpg",
              })
            }
          >
            <i className="fa fa-eye"></i>
          </button>
        ) : (
          "N/D"
        )}
        </td>*/}
      <td className="bg-secondary">
        {formatMonto(autorizadoAcumulado !== null ? autorizadoAcumulado : 0.0)}
      </td>
      <td className="bg-secondary">
        {formatMonto(parseFloat(cantidad) - parseFloat(autorizado))}
      </td>
      <td className="bg-secondary">{renderPorcentaje()}</td>
    </>
  );
};

export default ConceptoEstimacion;

import React, { useState, useContext, useEffect } from "react";
import { AppConfigContext } from "./context/AppConfigContext";
import { AuthContext } from "./context/AuthContext";
import { Link } from "react-router-dom";
import { getValue } from "./utils";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { spinner, signIn, signInWithGoogle } = useContext(AuthContext);
  const { primary, bg_login, logo_login, google_auth, S3_ENDPOINT } =
    useContext(AppConfigContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
  };

  useEffect(() => {
    initialSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signIn]);

  const initialSetup = () => {
    document.documentElement.style.setProperty(`--ion-color-primary`, primary);
  };

  const toggleSignInWithGoogle = (e) => {
    e.preventDefault();
    signInWithGoogle();
  };

  return (
    <div
      className="container-fluid overflow-hidden"
      style={{ backgroundColor: "#f9f9fa" }}
    >
      <div className="row vh-100 align-items-center">
        <div
          className="col col-sm-12 col-md-6 vh-100 bg-image hide-mobile"
          style={{
            backgroundImage: `url("${S3_ENDPOINT}/files/adjuntos/${bg_login}")`,
          }}
        >
          <div className="row h-100 align-items-center">
            <img
              src={`${S3_ENDPOINT}/files/adjuntos/${logo_login}`}
              className="d-block w-100 m-auto pt-5"
              style={{ maxWidth: 700 }}
              alt="Logo"
            />
          </div>
        </div>
        <div className="col col-sm-12 col-md-6">
          <div className="login-container">
            <div className="card shadow p-3">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <h2>Bienvenido</h2>
                  <p>Inicia Sesión para comenzar a supervisar tus proyectos</p>
                  <label className="text-muted text-uppercase">
                    Correo Electrónico
                  </label>
                  <input
                    type="text"
                    value={email}
                    autoCapitalize="none"
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control bg-light py-2 mb-3"
                  />
                  <label className="text-muted text-uppercase">
                    Contraseña
                  </label>
                  <div className="position-relative mb-4">
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      className="form-control bg-light py-2"
                      style={{ paddingRight: "60px" }}
                      autoCapitalize="false"
                      value={password}
                    />
                    <button
                      type="button"
                      className="btn btn-link position-absolute end-0 top-50 translate-middle-y fw-400 text-muted text-small py-0 mb-1"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                      style={{
                        fontSize: "27px",
                        marginRight: "20px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <span className="small">
                        <i
                          className={`fa fa-eye${showPassword ? "-slash" : ""}`}
                        ></i>{" "}
                        {showPassword ? "" : ""}
                      </span>
                    </button>
                  </div>
                  <button
                    className="btn btn-primary py-3 w-100"
                    disabled={spinner}
                  >
                    {spinner ? (
                      <div className="spinner-border"></div>
                    ) : (
                      "INICIAR SESIÓN"
                    )}
                  </button>
                  {getValue({ google_auth }, "google_auth", "boolean") && (
                    <button
                      className="btn btn-outline-primary py-3 w-100 mt-3"
                      onClick={toggleSignInWithGoogle}
                    >
                      <i className="fab fa-google me-2"></i>
                      Iniciar sesión con Google
                    </button>
                  )}
                  <Link
                    to="/recuperar"
                    className="btn text-secondary fw-normal mt-3 no-decoration w-100"
                  >
                    ¿Olvidaste tu contraseña?
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { createContext, useContext, useReducer } from "react";
import FoldersService from "../services/FoldersService";
import { ModalContext } from "./ModalContext";
import {
  SET_FOLDER,
  CLEAR_FOLDER,
  POP_BREADCRUMB,
  PUSH_BREADCRUMB,
  SET_FOLDER_ROOT,
  FOLDER_RECEIVED,
  CREATE_SUBFOLDER,
  CLEAR_BREADCRUMB,
  SET_FILES_FOLDER,
  SET_SUBFOLDER_NAME,
  FOLDERS_RECEIVED,
  SET_NAVBAR,
} from "../types/folder";
import FolderReducer from "../reducers/FolderReducer";

const initialState = {
  root: null,
  folder: null,
  folders: null,
  idFolder: null,
  navbar: null, // Agregamos una propiedad llamada navbar,
  breadcrumb: [],
};

export const FolderContext = createContext(initialState);

export const FolderProvider = ({ children }) => {
  const { alert, success, clearModal } = useContext(ModalContext);

  const [state, dispatch] = useReducer(FolderReducer, initialState);

  const getFoldersProyecto = (idProyecto) => {
    FoldersService.getFoldersProyecto(idProyecto).then((res) => {
      const { folders } = res.data;
      dispatch({ type: FOLDERS_RECEIVED, payload: folders });
    });
  };

  // Función que actualiza el estado de Navbar
  const setNavbar = (newNavbarState) => {
    dispatch({ type: SET_NAVBAR, payload: newNavbarState });
  };

  const getFolder = (idProyecto, idFolder) => {
    if (isNaN(idFolder)) dispatch({ type: SET_FOLDER_ROOT, payload: idFolder });
    else dispatch({ type: SET_FOLDER, payload: idFolder });
    FoldersService.getFolder(idProyecto, idFolder)
      .then((res) => {
        let { folder } = res.data;
        dispatch({ type: FOLDER_RECEIVED, payload: folder });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setFolder = (idFolder) => {
    dispatch({ type: SET_FOLDER_ROOT, payload: idFolder });
  };

  const pushBreadcrumb = (folder) => {
    dispatch({ type: PUSH_BREADCRUMB, payload: folder });
  };

  const popBreadcrumb = () => {
    dispatch({ type: POP_BREADCRUMB });
  };

  const clearBreadcrumb = () => {
    dispatch({ type: CLEAR_BREADCRUMB });
  };

  const clearFolder = () => {
    dispatch({ type: CLEAR_FOLDER });
  };

  const createSubFolder = () => {
    dispatch({
      type: CREATE_SUBFOLDER,
      payload: { idFolder: "nuevo", name: "" },
    });
  };

  const setFolderName = (idFolder, nombre) => {
    dispatch({ type: SET_SUBFOLDER_NAME, payload: { idFolder, nombre } });
  };

  const postSubFolder = (idProyecto, idPadre, nombre, callback) => {
    if (!nombre) return alert("Debes agregar un nombre al folder.");
    else {
      FoldersService.postFolder(idProyecto, idPadre, nombre)
        .then(() => {
          clearModal();
          if (callback) callback();
          success("Carpeta agregada con éxito.");
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const updateFolder = (idProyecto, folder, callback) => {
    const { idFolder, nombre } = folder;
    FoldersService.putFolder(idProyecto, idFolder, nombre)
      .then(() => {
        success("Carpeta actualizada con éxito.");
        if (callback) callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const deleteFolder = (idProyecto, idFolder, callback) => {
    FoldersService.deleteFolder(idProyecto, idFolder)
      .then(() => {
        success("Carpeta eliminada con éxito.");
        if (callback) callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setFilesFolder = (files) => {
    dispatch({ type: SET_FILES_FOLDER, payload: files });
  };

  return (
    <FolderContext.Provider
      value={{
        ...state,
        getFolder,
        setFolder,
        clearFolder,
        updateFolder,
        deleteFolder,
        setFolderName,
        postSubFolder,
        popBreadcrumb,
        pushBreadcrumb,
        setFilesFolder,
        clearBreadcrumb,
        createSubFolder,
        getFoldersProyecto,
        setNavbar,
      }}
    >
      {children}
    </FolderContext.Provider>
  );
};

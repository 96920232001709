import api from "./api";

const route = "/adjuntos";

export default {
  getFormData: (file) => {
    const formData = new FormData();
    formData.append("adjunto", file);
    return formData;
  },
  getAdjuntoFolder: (idProyecto, idAdjunto) =>
    api.get(`${route}/${idProyecto}/adjunto/${idAdjunto}`),
  postAdjunto: (formData) =>
    api.post(route, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  postAdjuntoFolder: (idProyecto, idFolder, formData) =>
    api.post(`${route}/${idProyecto}/${idFolder}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  postAdjuntoBitacora: (idProyecto, formData) =>
    api.post(`${route}/${idProyecto}/entradas`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  putAdjunto: (idAdjunto, nombre) =>
    api.put(`${route}/${idAdjunto}`, { nombre }),
  deleteAdjuntoFolder: (idProyecto, idAdjunto) =>
    api.delete(`${route}/${idProyecto}/adjunto/${idAdjunto}`),
  downloadAdjunto: (idProyecto, idAdjunto) =>
    api.get(`${route}/${idProyecto}/adjunto/${idAdjunto}/download`, {
      responseType: "blob",
    }),
};

import React, { useContext, useEffect, useState } from "react";
import ColorLegend from "../common/ColorLegend";
import { ItemsContext } from "../../context/ItemsContext";
import { ModalContext } from "../../context/ModalContext";
import { RevisionesContext } from "../../context/RevisionesContext";

const ItemEstadoForm = ({ idProyecto }) => {
  const { clearModal } = useContext(ModalContext);
  
  const { 
    currentRevision, 
    addItemToRevision, 
    updateItemByRevision,
    isItemOnRevision
  } = useContext(RevisionesContext);

  const { 
    item, 
    setItem, 
    getItemsByRevision,
    setPropertyItem, 
  } = useContext(ItemsContext);


  const handleCancel = () => {
    setItem(null);
    clearModal();
  };

  const handleSubmitStatus = async () => {
    const [isOnRevision, revisionItem] = isItemOnRevision();

    const newItemData = {
      idItem: item.idItem,
      estado: item.estado
    }

    if(isOnRevision) {
      await updateItemByRevision(revisionItem.idRevisionItem, newItemData);
    } else {
      await addItemToRevision(newItemData, currentRevision, idProyecto);
    }

    getItemsByRevision(currentRevision.idRevision);
    
    // postItem(idProyecto, item);
    clearModal();
  };

  return (
    <div>
      <div className="row">
        <ColorLegend
          block
          colors={[
            {
              className: "bg-success",
              title: "(7 - 10) Buen Estado",
            },
            {
              className: "bg-warning",
              title: "(< 7) Requiere Mantenimiento",
            },
          ]}
        />
      </div>
      <label>Asigna un Estado</label>
      <input
        type="number"
        value={item.estado}
        placeholder="Estado (1-10)"
        className="form-control"
        onChange={(e) => setPropertyItem("estado", e.target.value)}
      />
      <div className="row mt-3">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={handleSubmitStatus}
            className="btn btn-primary w-100"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemEstadoForm;

import api from "./api";

const route = "/estimaciones";

export default {
  getEstimaciones: (idProyecto, idContrato) =>
    api.get(`${route}/${idProyecto}/${idContrato}`),
  getEstimacion: (idProyecto, idContrato, idEstimacion) =>
    api.get(`${route}/${idProyecto}/${idContrato}/estimacion/${idEstimacion}`),
  postEstimacion: (idProyecto, idContrato, estimacion) =>
    api.post(`${route}/${idProyecto}/${idContrato}`, { ...estimacion }),
  putEstimacion: (idProyecto, idContrato, idEstimacion, numero, fecha_hora) =>
    api.put(`${route}/${idProyecto}/${idContrato}/estimacion/${idEstimacion}`, {
      numero,
      fecha_hora,
    }),
  deleteEstimacion: (idProyecto, idContrato, idEstimacion) =>
    api.delete(
      `${route}/${idProyecto}/${idContrato}/estimacion/${idEstimacion}`
    ),
};

import React, { useContext, useState, useEffect } from "react";
import { PartidasContratoContext } from "../../context/PartidasContratoContext";
import { ConceptosContext } from "../../context/ConceptosContext";
import FieldMapResult from "../global/FieldMapResult";
import FieldMapper from "../global/FieldMapper";
import {
  getConceptosEstimacionFromWorkbook,
  getSheetHeaders,
  getValue,
} from "../../utils";
import * as XLSX from "xlsx";
import { PartidasContext } from "../../context/PartidasContext";
import { DestajosContext } from "../../context/DestajosContext";
import { useParams } from "react-router-dom";
import SelectSheet from "./SelectSheet";
import UploadSheet from "./UploadSheet";
import PreviewConceptosEstimacionTable from "../conceptos/PreviewConceptosEstimacionTable";
import { AppConfigContext } from "../../context/AppConfigContext";
import { AuthContext } from "../../context/AuthContext";

const conceptosOptions = ["clave", "estimado"];

const UploadEstimacionForm = ({ idProyecto }) => {
  const { idContrato, idEstimacion } = useParams();
  const [headers, setHeaders] = useState(null);
  const [workbook, setWorkbook] = useState(null);
  const [sheetName, setSheetName] = useState("");
  const [sheetNames, setSheetNames] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [conceptosFields, setConceptosFields] = useState(null);

  const { user } = useContext(AuthContext);
  const appconfig = useContext(AppConfigContext);
  const { getDestajos } = useContext(DestajosContext);
  const { getPartidasProyecto } = useContext(PartidasContext);
  const { conceptos, setConceptos } = useContext(ConceptosContext);
  const { preciario, setPartidas } = useContext(PartidasContratoContext);

  useEffect(() => {
    getPartidasProyecto(idProyecto);
    getDestajos(idProyecto);
    setConceptos(null);
    setPartidas(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  useEffect(() => {
    if (preciario !== null) {
      setPartidas(null);
      const reader = new FileReader();
      reader.readAsArrayBuffer(preciario);
      reader.onload = (e) => {
        processPreciarioFile(e.target.result);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preciario]);

  useEffect(() => {
    if (sheetName !== "") {
      readSheet(sheetName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetName]);

  useEffect(() => {
    if (conceptosFields !== null) {
      extractConceptos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptosFields]);

  const extractConceptos = () => {
    setShowSpinner(true);
    let parsedConceptos = getConceptosEstimacionFromWorkbook(
      workbook,
      sheetName,
      conceptosFields
    );
    if (!getValue(appconfig, "supervise_estimates", "boolean")) {
      parsedConceptos = parsedConceptos.map((concepto) => ({
        ...concepto,
        supervisado: concepto.estimado,
      }));
    }
    if (!getValue(appconfig, "authorize_estimates", "boolean")) {
      parsedConceptos = parsedConceptos.map((concepto) => ({
        ...concepto,
        autorizado: concepto.estimado,
      }));
    }
    setConceptos(parsedConceptos);
    setShowSpinner(false);
  };

  const processPreciarioFile = (bufferArray) => {
    const current = XLSX.read(bufferArray, { type: "buffer" });
    setSheetNames(current.SheetNames);
    setWorkbook(current);
  };

  const resetSheet = () => {
    setSheetName("");
    setHeaders(null);
    setConceptosFields(null);
  };

  const readSheet = (sheetName) => {
    const headerRow = getSheetHeaders(workbook, sheetName);
    setHeaders(headerRow);
  };

  const renderSelectColumns = () => {
    if (headers && headers !== null) {
      if (conceptosFields === null) {
        return (
          <div className="card p-3 shadow mb-3">
            <FieldMapper
              title="Conceptos"
              options={headers}
              fields={conceptosOptions}
              handleSubmit={setConceptosFields}
            />
          </div>
        );
      }
    }
  };

  const renderConceptosFields = () => {
    if (conceptosFields !== null) {
      return (
        <FieldMapResult
          title="Campos Conceptos"
          fields={conceptosFields}
          clearFields={() => setConceptosFields(null)}
        />
      );
    }
  };

  const renderConceptos = () => {
    if (Array.isArray(conceptos)) {
      return (
        <PreviewConceptosEstimacionTable
          permiso={user.permiso}
          idProyecto={idProyecto}
          idContrato={idContrato}
          idEstimacion={idEstimacion}
        />
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <h1 className="bold mb-3">Cargar Estimación</h1>
      <UploadSheet />
      <SelectSheet
        sheetName={sheetName}
        sheetNames={sheetNames}
        resetSheet={resetSheet}
        setSheetName={setSheetName}
      />
      {renderConceptosFields()}
      {renderSelectColumns()}
      {showSpinner && <div className="spinner-border" />}
      {renderConceptos()}
    </div>
  );
};

export default UploadEstimacionForm;

import React, { useContext } from "react";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { ConceptosContext } from "../../context/ConceptosContext";
import { PreciarioContext } from "../../context/PreciarioContext";
import { ContratosContext } from "../../context/ContratosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { AuthContext } from "../../context/AuthContext";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";
import { Link } from "react-router-dom";

const EstimacionesButtons = () => {
  const { loading, estimacion, editEstimacion, createConceptoExtra } =
    useContext(EstimacionesContext);
    
  const {
    conceptos,
    conceptosExtra,
    postConceptosExtra,
    postConceptosEstimacion,
    postConceptosAutorizados,
  } = useContext(ConceptosContext);
  const { editMode, cancelEdit, editConceptosEstimacion } =
    useContext(PreciarioContext);
  const { contrato } = useContext(ContratosContext);
  const { proyecto } = useContext(ProyectosContext);
  const appconfig = useContext(AppConfigContext);
  const { user } = useContext(AuthContext);

  const idContrato = contrato !== null ? contrato.idContrato : null;
  const idProyecto = proyecto !== null ? proyecto.idProyecto : null;
  const permiso = proyecto !== null ? proyecto.permiso : user.permiso;
  const idEstimacion = estimacion !== null ? estimacion.idEstimacion : null;

  const handleEdit = () => editEstimacion(estimacion);

  const handleSubmitExtras = () => {
    postConceptosExtra(idProyecto, idContrato, idEstimacion, conceptosExtra);
  };

  const postConceptos = () => {
    if (["admin", "supervisor"].includes(permiso)) {
      postConceptosEstimacion(idProyecto, idContrato, idEstimacion, conceptos);
      const data = {
        idProyecto, idContrato, idEstimacion, conceptos
      }

    }
    if (["admin", "obras", "finanzas"].includes(permiso)) {
      const hasAutorizados = conceptos.find(
        (concepto) => concepto.autorizado !== null || concepto.autorizado > 0
      );
      if (hasAutorizados) {
        postConceptosAutorizados(
          idProyecto,
          idContrato,
          idEstimacion,
          conceptos,
          conceptosExtra
        );
      }
    }
  };

  const renderSupervisorButtons = () => {
    if (
      ["admin", "supervisor"].includes(permiso) &&
      estimacion &&
      estimacion !== null
    ) {
      return (
        <div className="d-inline">
          <button
            onClick={() => this.deleteEstimacion(estimacion)}
            className="btn btn-outline-danger me-2 ml-auto"
            disabled={loading}
          >
            <i className="fa fa-trash"></i> Eliminar Estimacion
          </button>
          <button
            onClick={handleEdit}
            className="btn btn-outline-primary me-2 ml-2"
            disabled={loading}
          >
            <i className="fa fa-edit"></i> Editar Estimacion
          </button>
        </div>
      );
    }
  };

  const renderFinancesButtons = () => {
    if (["admin", "finanzas"].includes(permiso)) {
      if (getValue(appconfig, "authorize_estimates", "boolean")) {
        return (
          <button
            onClick={editConceptosEstimacion}
            className="btn btn-outline-primary ms-1"
          >
            <i className="fa fa-exclamation"></i> Autorizar Conceptos
          </button>
        );
      }
    }
  };

  const renderObrasButtons = () => {
    if (["obras", "supervisor", "admin"].includes(permiso)) {
      return [
        <button
          className="btn btn-outline-primary mx-2"
          onClick={editConceptosEstimacion}
          disabled={loading}
        >
          <i className="fas fa-tasks me-2"></i>{" "}
          {permiso === "obras" ? "Autorizar " : "Estimar "}
          Conceptos
        </button>,
        <Link
          className="btn d-inline-block btn-outline-primary"
          to={`/obra/${idProyecto}/finanzas/${idContrato}/estimacion/${idEstimacion}`}
        >
          <i className="fa fa-upload"></i> Subir Estimación
        </Link>,
      ];
    }
  };

  const renderButtons = () => {
    if (estimacion && estimacion !== null) {
      if (editMode === "conceptos-estimacion") {
        return (
          <>
            <button
              className="btn btn-primary me-2"
              onClick={postConceptos}
              disabled={loading}
            >
              Guardar Estimacion
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={cancelEdit}
              disabled={loading}
            >
              Cancelar
            </button>
          </>
        );
      }
      if (editMode === "conceptos-extra") {
        return (
          <div>
            <button
              className="btn btn-primary me-2"
              onClick={handleSubmitExtras}
              disabled={loading}
            >
              <i className="fa fa-save"></i> Guardar Extras
            </button>
            <button
              className="btn btn-outline-primary me-3"
              onClick={createConceptoExtra}
              disabled={loading}
            >
              <i className="fa fa-plus"></i> Agregar Extra
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={cancelEdit}
              disabled={loading}
            >
              <i className="fa fa-times"></i> Cancelar
            </button>
          </div>
        );
      }
      if (!["cliente", "invitado"].includes(permiso)) {
        return (
          <div className="container-fluid px-0">
            {renderSupervisorButtons()}
            {renderObrasButtons()}
            {renderFinancesButtons()}
          </div>
        );
      }
    }
  };

  return <div className="d-inline-block">{renderButtons()}</div>;
};

export default EstimacionesButtons;

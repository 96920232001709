import {
  SET_FOLDER,
  CLEAR_FOLDER,
  POP_BREADCRUMB,
  SET_FOLDER_ROOT,
  PUSH_BREADCRUMB,
  FOLDER_RECEIVED,
  CREATE_SUBFOLDER,
  CLEAR_BREADCRUMB,
  SET_FILES_FOLDER,
  SET_SUBFOLDER_NAME,
  FOLDERS_RECEIVED,
  SET_NAVBAR,
} from "../types/folder";

const INITIAL_STATE = {
  root: null,
  folder: null,
  folders: null,
  adjunto: null,
  idFolder: null,
  breadcrumb: [],
  navbar: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FOLDER_RECEIVED:
      if (
        parseInt(payload.idFolder) === parseInt(state.idFolder) ||
        (payload.root && payload.nombre === state.root)
      ) {
        return { ...state, folder: payload };
      }
      return { ...state };
    case SET_FOLDER:
      return { ...state, idFolder: payload };
      case SET_NAVBAR:
        return { ...state, navbar: payload };
    case SET_FOLDER_ROOT:
      return { ...state, root: payload, folder: null };
    case SET_SUBFOLDER_NAME:
      let subfolders = [...state.folder.subfolders];
      let folder = subfolders.find(
        (folder) => String(folder.idFolder) === String(payload.idFolder)
      );
      if (folder) {
        folder.nombre = payload.nombre;
      }
      return { ...state, folder: { ...state.folder, subfolders } };
    case CREATE_SUBFOLDER:
      return {
        ...state,
        folder: {
          ...state.folder,
          subfolders: [...state.folder.subfolders, payload],
        },
      };
    case CLEAR_FOLDER:
      return { ...state, folder: null };
    case PUSH_BREADCRUMB:
      return { ...state, breadcrumb: [...state.breadcrumb, payload] };
    case POP_BREADCRUMB:
      let breadcrumb = [...state.breadcrumb];
      breadcrumb.pop();
      return { ...state, breadcrumb };
    case CLEAR_BREADCRUMB:
      return { ...state, breadcrumb: [] };
    case SET_FILES_FOLDER:
      return { ...state, folder: { ...state.folder, files: payload } };
    case FOLDERS_RECEIVED:
      return { ...state, folders: payload };
    default:
      return { ...state };
  }
};

import React, { useState, useContext, useEffect } from "react";

import { EntradasContext } from "../../context/EntradasContext";
import { ItemsContext } from "../../context/ItemsContext";
import ImageInput from "../common/ImageInput";
import { CapacitorContext } from "../../context/CapacitorContext";
import { UsuariosContext } from "../../context/UsuariosContext";
import { ProyectosContext } from "../../context/ProyectosContext";

const BitacoraInput = ({ modifier }) => {
  const [value, setValue] = useState("");
  const [files, setFiles] = useState([]);

  const { platform } = useContext(CapacitorContext);

  const { proyecto } = useContext(ProyectosContext);

  const { 
    adjuntos, 
    previewAdjunto, 
    cancelAdjunto, 
    clearAdjuntos 
  } = useContext(EntradasContext);

  const { 
    item, 
    setItem, 
    setPropertyItem, 
  } = useContext(ItemsContext);


  const handleChange = (evt) => {
    let value = evt.target.value;
    setValue(value);
 
    // setPropertyItem()
  };

  const getStyle = () => {
    if (platform !== "web") {
      return {
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        margin: "auto"
      };
    }
    return {};
  };

  const saveFiles = (files) => {
    setFiles(files);
  };

  const postMensaje = () => {
    value === "" ? modifier("", files) :  modifier(value, files);

    setValue("");
    setFiles([]);
    clearAdjuntos();
  };

  const pushImage = (file) => {
    let reader = new FileReader();
    let name = file.name;
    reader.onload = (e) => {
      let src = e.target.result;
      previewAdjunto(name, src);
    };
    reader.readAsDataURL(file);
  };

  const renderAdjuntos = () => {
    if (adjuntos)
      if (adjuntos.length > 0) {
        return (
          <div className="row mx-0 mb-3">
            {adjuntos.map((adjunto, index) => (
              <div
                className="card border-0 position-relative me-2 mb-2 file-preview"
                style={{
                  backgroundImage: `url(${adjunto.src})`,
                  backgroundPosition: 'center',
                  display: "inline-block",
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  height: '100px',
                }}
                key={index}
              >
              </div>
            ))}
          </div>
        );
      }
  };

  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      postMensaje();
    }
  };

  return (
    <div
      id="bitacora-input"
      style={getStyle()}
      className="container-fluid card border p-3 shadow"
    >
      {renderAdjuntos()}
      <div className="row align-items-center mx-0">
        <div className="col-9 ps-0">
          <input
            type="text"
            value={value}
            maxLength={500}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder="Escribe un mensaje..."
            className="form-control bg-light py-2"
          />
        </div>
        <div className="col-1 px-0">
          <ImageInput modifier={saveFiles} hideImage hideLabel multiple />
        </div>
        <div className="col-2 ps-1 px-0">
          <button className="btn btn-primary w-100 px-0" onClick={postMensaje}>
            <i className="fas fa-paper-plane" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BitacoraInput;

import React, { useContext, useEffect, useState } from "react";
import {
  IonModal,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonIcon,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import moment from "moment";
import { ChecklistContext } from "../../context/ChecklistContext";
import "./guardarMovil.css";
import { FaSave } from "react-icons/fa";
import IconPickerModal from "../picker/IconPickerModal"; // Importa el componente IconPickerModal

const CrearChecklistForm = ({ showModal, setShowModal, idProyecto }) => {
  const { checklist, postChecklist, setPropertyChecklist } =
    useContext(ChecklistContext);

  const [showIconModal, setShowIconModal] = useState(false);

  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    if (checklist.fecha_inicio === null) {
      setPropertyChecklist("fecha_inicio", moment().format("YYYY-MM-DD"));
    }
    if (checklist.fecha_fin === null) {
      setPropertyChecklist(
        "fecha_fin",
        moment().add(1, "week").format("YYYY-MM-DD")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (checklist) {
      checklist.idProyecto = idProyecto;
      postChecklist(checklist);
    }
  };

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  return (
    <IonModal
      isOpen={showModal}
      onDidDismiss={() => setShowModal(false)}
      initialBreakpoint={0.8}
      breakpoints={[0.8]}
    >
      <IonContent className="py-4 ps-4">
        <IonGrid>
          <form
            className="py-4 ps-4"
            style={{ paddingRight: "30px" }}
            onSubmit={onSubmit}
          >
            <IonRow>
              <IonCol>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2 style={{ fontWeight: "700" }}>Crear Checklist</h2>
                  <IonIcon
                    icon={closeOutline}
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => setShowModal(!showModal)}
                  />
                </div>
                <h4 className="h5">Ingresar la siguiente información</h4>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel className="mt-4 h6">Nombre</IonLabel>
                <IonInput
                  value={checklist.nombre}
                  type="text"
                  className="form-control mb-3"
                  onIonChange={(e) =>
                    setPropertyChecklist("nombre", e.target.value)
                  }
                ></IonInput>
              </IonCol>
            </IonRow>

            <div className="col-7 d-flex">
              <div className="col-12">
                <label className="mb-2 titleInputPlantilla">COLOR ICONO</label>
                <div className="d-flex align-items-center mb-3">
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={color}
                    style={{
                      cursor: "pointer",
                      marginRight: "0px",
                    }}
                    onClick={(e) =>
                      document.getElementById("colorPicker").click()
                    }
                  />
                  <div
                    style={{
                      width: 37,
                      height: 37,
                      borderRadius: "3px",
                      border: "1px solid #ced4da",
                      cursor: "pointer",
                      backgroundColor: color,
                    }}
                    onClick={(e) =>
                      document.getElementById("colorPicker").click()
                    }
                  ></div>
                  <input
                    type="color"
                    id="colorPicker"
                    className="d-none"
                    onChange={handleColorChange}
                  />
                </div>
              </div>

              <div className="col-3 ps-2">
                <label className="mb-2 titleInputPlantilla">ICONO</label>
                <button
                  type="button"
                  className="btn border"
                  style={{ width: "35vw", backgroundColor: "#f8f9fa" }}
                  onClick={() => setShowIconModal(true)}
                >
                  <i className="fa fa-plus text-primary" />
                </button>

                <div className="d-flex align-items-center mb-3"></div>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-12 text-end mt-2">
                <button
                  type="button"
                  className="btn"
                  onClick={() => setShowModal(!showModal)}
                >
                  CANCELAR
                </button>
                <button className="btn btn-primary me-2" type="submit">
                  <FaSave className="me-2" />
                  GUARDAR
                </button>
              </div>
            </div>
          </form>
        </IonGrid>
      </IonContent>
      <IconPickerModal
        isOpen={showIconModal}
        onDismiss={() => setShowIconModal(false)}
      />
    </IonModal>
  );
};

export default CrearChecklistForm;

import React, { useState, useEffect } from "react";
import ProgressBar from "../global/ProgressBar";
import EstadoObra from "../common/EstadoObra";
import DateRange from "../global/DateRange";
import Responsable from "./Responsable";
import CheckBox from "../obra/CheckBox";
import ItemAlertas from "./ItemAlertas";
import "../../css/ItemDetail.css";

const ItemDetail = ({
  id,
  item,
  callback,
  proyecto,
  modifier,
  saveItem,
  itemLabel,
}) => {
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (isNaN(item[id]) && item[id] && id) {
      setEditMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (item) => {
    setEditMode(false);
    const { idProyecto } = proyecto;
    if (typeof saveItem === "function") {
      setEditMode(false);
      saveItem(idProyecto, item, callback);
    }
  };

  const getProgress = () => {
    let { progreso } = item;
    if (item.real) {
      progreso = item.real;
    }
    return parseFloat(progreso) * 100;
  };

  const getDifference = () => {
    return parseFloat(item.programado) - parseFloat(item.real);
  };

  const renderDates = () => {
    if (item.fecha_inicio && item.fecha_fin) {
      return (
        <DateRange
          start_label="FECHA INICIO"
          end_label="FECHA FIN"
          start_date={item.fecha_inicio}
          end_date={item.fecha_fin}
        />
      );
    }
  };

  const renderCritical = () => {
    if (item.punto_critico || item.puntoCritico) {
      return (
        <div className="mb-3">
          <CheckBox checked={true} />
          <span className="titleInputPlantilla">PUNTO CRITICO</span>
        </div>
      );
    }
  };

  const renderDiferencial = () => {
    const difference = getDifference();
    if (!isNaN(difference)) {
      if (difference !== 0) {
        return (
          <div className="row align-items-middle mb-3 px-1">
            <div className="col-6">
              <span className="bold">{parseFloat(difference).toFixed(2)}%</span>{" "}
              diferencial
            </div>
            <div className="col-6">
              <EstadoObra estado={difference <= 0 ? 1 : 2} />
            </div>
          </div>
        );
      }
    }
  };

  const renderButton = () => {
    if (proyecto.permiso === "coordinador") {
      if (editMode) {
        return (
          <div className="d-flex">
            <button className="btn btn-primary" onClick={handleSubmit}>
              <i className="fa fa-save"></i>
            </button>
            <button className="btn text-muted">
              <i className="fa fa-plus-square"></i>
            </button>
            <button className="btn text-muted">
              <i className="fa fa-trash"></i>
            </button>
          </div>
        );
      }
      return (
        <div className="d-flex">
          <button className="btn text-muted" onClick={() => setEditMode(true)}>
            <i className="fa fa-edit"></i>
          </button>
          <button className="btn text-muted">
            <i className="fa fa-plus-square"></i>
          </button>
          <button className="btn text-muted">
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
    }
  };

  const renderPonderacion = () => {
    let { ponderacion } = item;
    if (parseFloat(ponderacion) < 1) {
      ponderacion = parseFloat(ponderacion) * 100;
    }
    return (
      <p className="mt-3">
        <b>{ponderacion}%</b> ponderación.
      </p>
    );
  };
  const progreso = getProgress();

  return (
    <div className="container-fluid px-0 position-relative">
      <div className="row mx-0 mb-2 align-items-center">
        <div className="col-10 px-0">
          {editMode ? (
            <>
              <h4 className="bold text-capitalize h6">DETALLE</h4>
              <input
                type="text"
                className="form-control"
                value={item.nombre}
                onChange={(e) => modifier("nombre", e.target.value)}
              />
            </>
          ) : (
            <>
              <h4 className="bold text-capitalize h6">DETALLE</h4>
              <h4 className="bold text-capitalize ">{item.nombre}</h4>
            </>
          )}
        </div>
      </div>
      <div className="col-12">{renderButton()}</div>
      <div className="mb-3">
        {renderPonderacion()}
        <ProgressBar progress={progreso} />
      </div>
      {renderCritical()}
      {renderDiferencial()}
      {renderDates()}
      <div className="row mx-0 mt-3">
        <Responsable
          item={item}
          modifier={modifier}
          itemLabel={itemLabel}
          handleSubmit={handleSubmit}
        />
      </div>
      <ItemAlertas idKey={id} idItem={item[id]} />
    </div>
  );
};

export default ItemDetail;

import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import ProductDetail from '../components/product/SingleProduct/ProductDetail';
import { ProductsContext } from '../context/ProductsContext';
import ProductLastComment from '../components/product/SingleProduct/ProductLastComment';
import ProductInventoryLevel from '../components/product/SingleProduct/ProductInventoryLevel';
import ProductWarehousesTable from '../components/product/SingleProduct/ProductWarehousesTable';
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import ProductOrderList from '../components/product/SingleProduct/ProductOrderList';
import ProductInputList from '../components/product/SingleProduct/ProductInputList';
import ProductAnalytics from '../components/product/SingleProduct/ProductAnalytics';

const SingleProduct = () => {

    const { getSingleProduct, product, products } = useContext(ProductsContext);
    const [foundProduct, setFoundProduct] = useState()
    const { idProyecto } = useParams();
    const { idProducto } = useParams();
    const [activeTab, setActiveTab] = useState("main");

    useEffect(() => {
        getSingleProduct(idProyecto, idProducto)
    }, [products])

    useEffect(() => {
        setFoundProduct(product)
    }, [product])

    const renderComponent = () => {
        switch (activeTab) {
            case "warehouses":
                return <ProductWarehousesTable product={foundProduct} />;
            case "supplies":
                return <ProductInputList product={foundProduct} />;
            case "orders":
                return <ProductOrderList product={foundProduct} />
            case "analytics":
                return <ProductAnalytics product={foundProduct} />
            default:
                return <ProductWarehousesTable product={foundProduct} />;
        }
    };


    return (<div className='p-4'>
        <div className='d-flex justify-content-between mb-3'>
            <h1>Detalle del Producto</h1>
            <h2>{foundProduct?.sku}</h2>
            <button className='btn btn-primary w-25'>+ Reporte</button>
        </div>
        <div className='d-flex justify-content-between mb-4'>
            <div className='me-4' style={{ width: '45%' }}>
                <ProductDetail product={product} />
            </div>
            <div className='d-flex flex-column justify-content-between' style={{ width: '55%' }}>
                <div>
                    <p className='bold mb-1'>Nivel de Inventario</p>
                    <ProductInventoryLevel />
                </div>
                <ProductLastComment />
            </div>
        </div>


        <div className='w-100 mt-4'>
            <IonSegment className="mb-3 mt-5" value={activeTab}>
                <IonSegmentButton value="warehouses" onClick={() => setActiveTab("warehouses")}>
                    <IonLabel>Almacenes</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="supplies" onClick={() => setActiveTab("supplies")}    >
                    <IonLabel>Insumos</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="orders" onClick={() => setActiveTab("orders")}>
                    <IonLabel>Órdenes</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="analytics" onClick={() => setActiveTab("analytics")}>
                    <IonLabel>Reportes</IonLabel>
                </IonSegmentButton>
            </IonSegment>
            {renderComponent()}
        </div>



    </div>);
}

export default SingleProduct;
import React, { useState, useContext, useEffect } from "react";
import ConceptoExtra from "./ConceptoExtra";
import PartidaPreciario from "./PartidaPreciario";
import ConceptoExtraForm from "./ConceptoExtraForm";
import PartidaPreciarioForm from "./PartidaPreciarioForm";
import PreciarioHeader from "../preciario/PreciarioHeader";
import PreciarioButtons from "../preciario/PreciarioButtons";
import { ConceptosContext } from "../../context/ConceptosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { PreciarioContext } from "../../context/PreciarioContext";
import EstimacionesButtons from "../estimaciones/EstimacionesButtons";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import EstimacionesContrato from "../estimaciones/EstimacionesContrato";
import { PartidasContratoContext } from "../../context/PartidasContratoContext";
import FilaTotales from "./FilaTotales";
import { AppConfigContext } from "../../context/AppConfigContext";

const Preciario = ({ idContrato }) => {
  const [query, setQuery] = useState("");
  const { estimacion, showEstimaciones, toggleEstimaciones } =
    useContext(EstimacionesContext);
  const {
    conceptos,
    conceptosExtra,
    deleteConceptoExtra,
    getConceptosContrato,
    setPropiedadConceptoExtra,
  } = useContext(ConceptosContext);
  const { partidas, deletePartida, setPropiedadPartida, getPartidasContrato } =
    useContext(PartidasContratoContext);
  const { proyecto } = useContext(ProyectosContext);
  const { editMode } = useContext(PreciarioContext);
  const { authorize_estimates, PartidaContratoName } =
    useContext(AppConfigContext);
  const { idProyecto, permiso } = proyecto;

  useEffect(() => {
    getConceptosContrato(idProyecto, idContrato);
    getPartidasContrato(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato]);

  function renderPartidas() {
    if (Array.isArray(partidas)) {
      //Edit Partidas
      if (editMode === "partidas") {
        let partidasRender = partidas.filter(
          (partida) => String(partida.idPartidaContrato)[0] !== "d"
        );
        return partidasRender.map((partida) => (
          <PartidaPreciarioForm
            partida={partida}
            idProyecto={idProyecto}
            key={partida.idPartidaContrato}
            modifier={setPropiedadPartida}
            deletePartida={deletePartida}
          />
        ));
      }

      //Edit Conceptos Extra
      if (editMode === "conceptos-extra") {
        let conceptosRender = conceptosExtra.filter(
          (concepto) => String(concepto.idConceptoExtra)[0] !== "d"
        );
        return conceptosRender.map((concepto) => (
          <ConceptoExtraForm
            concepto={concepto}
            key={concepto.idConceptoExtra}
            modifier={setPropiedadConceptoExtra}
            deleteConceptoExtra={deleteConceptoExtra}
          />
        ));
      }
      let components = [];
      let partidasRender = [...partidas];
      
      if (editMode === "conceptos-estimacion") {
        if (["finanzas", "obras"].includes(permiso)) {
          let conceptosEstimados = conceptos.filter(
            (concepto) => concepto.estimado
          );
          let partidasConceptosEstimados = conceptosEstimados.map(
            (concepto) => concepto.idPartidaContrato
          );
          partidasRender = partidasRender.filter((partida) =>
            partidasConceptosEstimados.includes(partida.idPartidaContrato)
          );
        }
      }

      if (estimacion !== null) {
        partidasRender = partidasRender.filter((partida) => {
          return (
            conceptos.find(
              (concepto) =>
                concepto.idPartidaContrato === partida.idPartidaContrato
            ) !== undefined
          );
        });
      }
      
      components = partidasRender.map((partida) => (
        <PartidaPreciario
          partida={partida}
          estimacion={estimacion}
          key={partida.idPartidaContrato}
          showEstimaciones={showEstimaciones}
        />
      ));

      if (Array.isArray(conceptosExtra)) {
        components = [
          ...components,
          ...conceptosExtra.map((concepto) => (
            <ConceptoExtra
              showEstimaciones={showEstimaciones}
              key={`concepto-${concepto.idConceptoExtra}`}
              concepto={concepto}
            />
          )),
        ];
      }
      components.push(<FilaTotales key="totales" conceptos={conceptos} />);
      return components;
    }
  }

  function renderBotones() {
    if (!["cliente", "invitado"].includes(permiso)) {
      return (
        <div className="row mt-3">
          <div className="container-fluid">
            <PreciarioButtons estimacion={estimacion} />
            <EstimacionesButtons showEstimaciones={showEstimaciones} />
          </div>
        </div>
      );
    }
  }

  function renderPreciario() {
    if (Array.isArray(partidas)) {
      if (editMode !== "partidas") {
        if (partidas.length === 0) {
          return (
            <p>No hay {PartidaContratoName} registradas para este contrato.</p>
          );
        }
      }
      return (
        <div className="table-responsive border th-border-0 align-items-top tabla-preciario">
          <table className="table mb-0">
            <PreciarioHeader
              editMode={editMode}
              showEstimaciones={showEstimaciones}
              toggleEstimaciones={toggleEstimaciones}
              authorize_estimates={authorize_estimates}
            />
            <tbody>{renderPartidas()}</tbody>
          </table>
        </div>
      );
    }
  }

  return (
    <div className="card p-3 shadow my-3">
      <div className="row align-items-center mb-3">

        <div className="col-12 col-md-8">
          <h2 className="h4 mb-0 bold">Catálogo</h2>
          <input
            type="text"
            value={query}
            className="form-control bg-light"
            placeholder="Buscar partidas y conceptos"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        <div className="col-12 col-md-4">
          <EstimacionesContrato
            idProyecto={idProyecto}
            idContrato={idContrato}
            permiso={permiso}
          />
        </div>

      </div>
      {renderPreciario()}
      {renderBotones()}
    </div>
  );
};

export default Preciario;

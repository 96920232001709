import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProductsContext } from "../../../context/ProductsContext";
import { AppConfigContext } from "../../../context/AppConfigContext";
import ProductsHandler from "../../../utils/products/ProductsHandler";
import { hexToRgb } from "../../../utils/colors"
import "../product.css";

const ProductTableRow = ({
    product,
    cantidad = null,
    parentId = null,
    depthColor,
}) => {
    const {
        handleCreateChildProduct,
        handleEditProduct,
        handleDeleteProduct,
        handleCloneProduct,
        handleDeleteChildProduct,
        handleEditChildProduct,
    } = ProductsHandler();
    const { getProductInsumos, product: contextProduct } = useContext(ProductsContext);
    const { primary } = useContext(AppConfigContext);

    const [insumos, setInsumos] = useState([]);
    const [visibleChildProducts, setVisibleChildProducts] = useState(false);

    const rgbColor = hexToRgb(primary);
    const backgroundColor = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${0 + depthColor * 0.1})`;
    const color = depthColor > 5 ? "#fff" : "black";

    const singleProductRoute = product.idProyecto != null ? `/obra/${product.idProyecto}/almacen/producto/${product.idProducto}` : `producto/${product.idProducto}`


    useEffect(() => {
        if (contextProduct.idProducto == product.idProducto) {
            getProductInsumos(product.idProducto, (insumos) => {
                setInsumos(insumos);
            });
        }
    }, [contextProduct])

    const handleProductChildren = (productId) => {
        if (insumos.length <= 0) {
            getProductInsumos(productId, (insumos) => {
                setInsumos(insumos);
            });
        }
        setVisibleChildProducts(!visibleChildProducts);
    };

    const handleRenderChildProducts = () => {
        return (
            <button
                className="btn btn-primary expand-button"
                onClick={() => handleProductChildren(product.idProducto)}
            >
                <i
                    className={
                        visibleChildProducts ? "fa fa-chevron-up" : "fa fa-chevron-down"
                    }
                ></i>
            </button>
        );
    };

    const renderChildProductRow = (insumo) => {
        return (
            <ProductTableRow
                key={insumo.producto.idProducto}
                product={insumo.producto}
                depthColor={depthColor + 1}
                parentId={product.idProducto}
                cantidad={insumo.cantidad}
            />
        );
    };

    const renderActionButtons = () => {
        const cloneFunction = () => handleCloneProduct(product.idProducto);
        const deleteFunction = () => parentId ? handleDeleteChildProduct(parentId, product.idProducto) : handleDeleteProduct(product.idProducto);
        const createChildFunction = () => handleCreateChildProduct(product.idProducto);
        const editFunction = () => parentId ? handleEditChildProduct(parentId, product, cantidad) : handleEditProduct(product);

        return (
            <div className="product-table-buttons">
                <button className="btn">
                    <i style={{ color }} className="fa fa-bell"></i>
                </button>
                <button className="btn" onClick={createChildFunction}>
                    <i style={{ color }} className="fa fa-plus"></i>
                </button>
                <button className="btn" onClick={editFunction}>
                    <i style={{ color }} className="fa fa-edit"></i>
                </button>
                {!parentId && (
                    <button className="btn" onClick={cloneFunction}>
                        <i style={{ color }} className="fa fa-clone"></i>
                    </button>
                )}
                <button className="btn" onClick={deleteFunction}>
                    <i style={{ color }} className="fa fa-trash"></i>
                </button>
            </div>
        );
    };

    return (
        <>
            <tr style={{ backgroundColor, color }} key={product.idProducto}>
                <td width="6%" style={{ textAlign: "left" }}>
                    <Link to={singleProductRoute}>
                        <a className="mb-0 bold"
                            style={{
                                textDecoration: "underline",
                                color: depthColor > 5 ? "#fff" : primary,
                            }}
                        >
                            {product.sku}
                        </a>
                    </Link>
                </td>
                <td width="10%" className="product-article">
                    {handleRenderChildProducts()}
                    <p className="mb-0"> {`${product.article}${cantidad != null ? ` (×${cantidad})` : ''}`}</p>
                </td>
                <td width="5%">{product.unit}</td>
                <td width="5%">{product.quantity}</td>
                <td width="5%">{product.orders}</td>
                <td width="5%">{product.maximum}</td>
                <td width="5%">{product.minimum}</td>
                <td width="5%">{product.unitPrice}</td>
                <td width="5%">{product.amount}</td>
                <td width="10%">
                    <div className="w-100 d-flex justify-content-center">
                        {renderActionButtons()}
                    </div>
                </td>
            </tr>
            {visibleChildProducts &&
                insumos?.length > 0 &&
                insumos.map((insumo) => renderChildProductRow(insumo))}
        </>
    );
};

export default ProductTableRow;

import React, { useContext } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";

const MobileSubHeader = ({ title, subtitle, children }) => {
  const { isMobileDevice } = useContext(CapacitorContext);

    return (
      <div className="row bg-secondary px-0 mb-3 align-items-center show-mobile-flex" style={{height: '50px'}}>
        <div className="container-fluid px-0">
          <div className="row align-items-center">
            <div className="col-8">
              <h3 className="mb-0 bold text-capitalize">{title}</h3>
              {subtitle && <p className="mb-0">{subtitle}</p>}
            </div>
            <div className="col-4">{children}</div>
          </div>
        </div>
      </div>
    );

};

export default MobileSubHeader;

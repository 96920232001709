import React, { useState, useEffect, useContext } from "react";
import ImageInput from "../common/ImageInput";
import { FILES_ENDPOINT, getValue } from "../../utils";
import { ProyectosContext } from "../../context/ProyectosContext";
import { ClientesContext } from "../../context/ClientesContext";
import { AppConfigContext } from "../../context/AppConfigContext";

const ObraForm = ({ handleCancel }) => {
  const [src, setSrc] = useState("");

  const appconfig = useContext(AppConfigContext);

  const { clientes, getClientes } = useContext(ClientesContext);

  const { proyecto, postProyecto, setPropiedadProyecto } =
    useContext(ProyectosContext);

  useEffect(() => {
    getClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (proyecto && proyecto !== null) {
      handleImg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proyecto]);

  const handleImg = () => {
    if (proyecto.idAdjunto && proyecto.idAdjunto !== null) {
      let newSrc = `${FILES_ENDPOINT}/${proyecto.idAdjunto}.png`;
      if (newSrc !== src) {
        setSrc(newSrc);
      }
    }
  };

  const getSrc = () => {
    if (src !== "") {
      return src;
    }
    if (proyecto?.tipo_adjunto && proyecto?.tipo_adjunto !== null) {
      return `${FILES_ENDPOINT}/${proyecto.idAdjunto}.${proyecto.tipo_adjunto}`;
    }
  };

  const handleFile = (file) => {
    setPropiedadProyecto("file", file);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    postProyecto(proyecto);
  };

  const renderClientes = () => {
    if (Array.isArray(clientes)) {
      return [
        <option key="null" value={null}>
          Sin cliente
        </option>,
        ...clientes.map((cliente) => (
          <option key={cliente.idCliente} value={cliente.idCliente}>
            {cliente.nombre}
          </option>
        )),
      ];
    }
  };

  const renderClienteForm = () => {
    if (getValue(appconfig, "clients_enabled", "boolean")) {
      return (
        <div>
          <label>Cliente</label>
          <select className="form-control mb-3">{renderClientes()}</select>
        </div>
      );
    }
  };

  return (
    <form onSubmit={onSubmit} className="container px-0">
      <div className="row mb-2">
        <div className="col col-md-6">
          <label>Nombre de Obra</label>
          <input
            type="text"
            maxLength={140}
            value={proyecto?.nombre}
            className="form-control mb-3"
            placeholder="Ejemplo: Edificio Uno"
            onChange={(e) => setPropiedadProyecto("nombre", e.target.value)}
          />
        </div>
        <div className="col col-md-6">
          <label>Ubicación</label>
          <input
            type="text"
            maxLength={140}
            value={proyecto?.calle}
            className="form-control mb-3"
            placeholder="Ejemplo: Cumbres"
            onChange={(e) => setPropiedadProyecto("calle", e.target.value)}
          />
        </div>
      </div>
      {renderClienteForm()}
      <div className="row mb-4">
        <ImageInput value={getSrc()} modifier={handleFile} />
      </div>
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 btn-link text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button className="btn w-100 btn-primary" type="submit">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ObraForm;

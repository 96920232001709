import React, { useContext, useState, useEffect } from "react";
import { ProductsContext } from "../../../context/ProductsContext";
import ProductTableRow from "./ProductTableRow";
import "../product.css";

const ProductTable = ({ projectId }) => {
    const { getProducts, getProjectProducts, products, product } =
        useContext(ProductsContext);
    const [productsToDisplay, setProductsToDisplay] = useState([]);

    useEffect(() => {
        if (projectId) {
            getProjectProducts(projectId);
        } else {
            getProducts();
        }
    }, [product]);

    useEffect(() => {
        if (projectId) {
            const filteredProducts = products.filter(
                (product) => product.idProyecto == projectId
            );
            setProductsToDisplay(filteredProducts);
        } else {
            setProductsToDisplay([...products]);
        }
    }, [products, projectId]);

    const renderProductRow = (product) => {
        return (
            <ProductTableRow
                key={product.idProducto}
                product={product}
                depthColor={0}
                parentId={null}
            />
        );
    };

    return (
        <div>
            {productsToDisplay.length > 0 ? (
                <div className="product-table-container">
                    <table className="product-table">
                        <thead>
                            <tr>
                                <th style={{ textAlign: "start" }}>SKU</th>
                                <th style={{ textAlign: "start" }}>ARTÍCULO</th>
                                <th>UNIDAD</th>
                                <th>CANTIDAD</th>
                                <th>ÓRDENES</th>
                                <th>MÁXIMO</th>
                                <th>MÍNIMO</th>
                                <th>$PU</th>
                                <th>$IMPORTE</th>
                                <th>ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productsToDisplay.map((product) => renderProductRow(product))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>No hay productos</p>
            )}
        </div>
    );
};

export default ProductTable;

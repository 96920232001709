import React, { useContext, useState } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";
import { ModalContext } from "../../context/ModalContext";
import GuardarMovil from "../movil/GuardarMovil";
import PlantillaForm from "./PlantillaForm";
import Plantillas from "./Plantillas";

const PlantillasButtons = ({
  idChecklist,
  idProyecto,
  sections,
  partidas,
  permiso,
  tipo,
}) => {
  const { modalComponent } = useContext(ModalContext);
  const [showModal, setShowModal] = useState(false);

  const { platform } = useContext(CapacitorContext);

  const hasPartidasPreciario = () => {
    //Connect with preciario
    return false;
  };

  const handleUploadPlantilla = () => {
    modalComponent(
      "Selecciona una Plantilla",
      <Plantillas
        idChecklist={idChecklist}
        idProyecto={idProyecto}
        tipo={tipo}
      />
    );
  };

  const handleViewPlantillas = () => {
    modalComponent(
      "Mis Plantillas",
      <Plantillas
        idChecklist={idChecklist}
        idProyecto={idProyecto}
        tipo={tipo}
        disabled
      />
    );
  };

  const handlePlantilla = () => {
    if (platform === "web") {
      modalComponent(
        "Guardar Plantilla",
        <PlantillaForm idProyecto={idProyecto} tipo={tipo} />
      );
    } else {
      setShowModal(true);
    }
  };

  const handlePreciario = () => {};

  const renderButtonsHeader = () => {
    if (platform === "web") {
      return (
        <div className="d-inline-block">
          {(Array.isArray(partidas) || Array.isArray(sections)) && (
            <button
              className="btn btn-outline-primary me-2 mb-2"
              onClick={handlePlantilla}
            >
              <i className="fa fa-save"></i> Guardar Plantilla
            </button>
          )}
          <button
            className="btn btn-outline-primary mb-2"
            onClick={handleViewPlantillas}
          >
            <i className="fa fa-eye"></i> Ver Plantillas
          </button>
        </div>
      );
    }
    return (
      <div>
        <button
          className="btn btn-primary"
          style={{ marginRight: "2px" }}
          onClick={handlePlantilla}
        >
          <i className="fa fa-print"></i>
        </button>

        <button
          className="btn btn-primary"
          style={{ marginRight: "2px" }}
          onClick={handlePlantilla}
        >
          <i className="fa fa-save"></i>
        </button>

        <button
          className="btn btn-primary"
          style={{ marginRight: "2px" }}
          onClick={handlePlantilla}
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>
    );
  };

  const canSetPlantilla = () => {
    const items = tipo === "cronograma" ? partidas : sections;
    if (Array.isArray(items)) {
      if (items.length === 0) {
        return (
          <div className="d-inline-block">
            <button
              className="btn btn-outline-primary mb-2 me-2"
              onClick={handleUploadPlantilla}
            >
              <i className="fas fa-folder"></i> Cargar Plantilla
            </button>
            {hasPartidasPreciario() && (
              <button
                className="btn btn-outline-primary mb-2 me-2"
                onClick={handlePreciario}
              >
                <i className="fa fa-edit"></i> Cargar Catálogo
              </button>
            )}
          </div>
        );
      }
    }
  };

  const renderButtons = () => {
    if (["admin", "coordinador"].includes(permiso)) {
      return (
        <div className="col-12">
          {canSetPlantilla()}
          {renderButtonsHeader()}
        </div>
      );
    }
  };
  return (
    <div className="row px-3 mb-3">
      {renderButtons()}
      <GuardarMovil
        idProyecto={idProyecto}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default PlantillasButtons;

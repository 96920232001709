import React, { useContext } from "react";
import Obra from "./views/Obra";
import Obras from "./views/Obras";
//import Navbar from "./views/Navbar";
import Alertas from "./views/Alertas";
import Usuarios from "./views/Usuarios";
import Clientes from "./views/Clientes";
//import Manuales from "./views/Manuales";
import Menu from "./components/menu/Menu";
import { CapacitorContext } from "./context/CapacitorContext";
import BottomNavbar from "./components/global/BottomNavbar";
import MenuMobile from "./components/menu/MenuMobile";
import SingleCliente from "./views/SingleCliente";
import { Routes, Route } from "react-router-dom";
import { hasNotch } from "./utils/ionic";
import Permisos from "./views/Permisos";
import Navbar from "./views/Navbar";
import Ajustes from "./views/Ajustes";
import { AppConfigContext } from "./context/AppConfigContext";
import { getValue } from "./utils";
import Almacen from "./views/Almacen";
import SingleProduct from "./views/SingleProduct";
import PurchaseOrder from "./views/PurchaseOrder";
import Proveedores from "./views/Proveedores";
import SingleProveedor from "./views/SingleProveedor";

const Home = () => {
  const appconfig = useContext(AppConfigContext);
  const { device, platform } = useContext(CapacitorContext);

  const renderBottomNavbar = () => {
    if (platform !== "web") {
      return <BottomNavbar />;
    }
  };

  const renderAjustes = () => {
    if (getValue(appconfig, "show_settings", "boolean")) {
      return <Route path="/ajustes" element={<Ajustes />} />;
    }
  };

  return (
    <div className="container-fluid vw-100 px-0 vh-100" >
      <Menu />
      <Navbar />
        <div
          id="content"
          style={{ 
            marginTop: hasNotch(device) ? 32 : 0,
            paddingBottom:  platform !== 'web' ? 56 : 0, 
            paddingRight: 0
          }}
          className="col-2 col-md-9 col-xl-10 bg-light d-flex flex-column"
        >
          <MenuMobile />
          <Routes>
            {renderAjustes()}
            <Route path="/" element={<Obras />} />
            <Route path="/alertas" element={<Alertas />} />
            <Route path="/usuarios" element={<Usuarios />} />
            <Route path="/clientes" element={<Clientes />} />
            <Route path="/permisos" element={<Permisos />} />
            <Route path="/almacen" element={<Almacen />} />
            <Route path="/proveedores" element={<Proveedores />} />
            <Route path="/proveedores/:idProveedor" element={<SingleProveedor />} />
            <Route path="/orden/:idOrden" element={<PurchaseOrder />} />
            <Route path="/almacen/producto/:idProducto" element={<SingleProduct />} />
            <Route path="/clientes/:idCliente" element={<SingleCliente />} />
            <Route path="/obra/:idProyecto/*" element={<Obra />} />
          </Routes>
          {renderBottomNavbar()}
        </div>
      </div>
  );
};

export default Home;

import {
  RECEIVED_PRODUCTS,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_SINGLE_PRODUCT,
  CLONE_PRODUCT,
  GET_PRODUCT_INSUMOS,
  UPDATE_PRODUCT_INSUMOS
} from "../types/products";

const ProductsReducer = (state, { type, payload }) => {
  let currProducts = state.products;
  let updatedProducts = [];
  switch (type) {
    case RECEIVED_PRODUCTS:
      return { ...state, products: payload };
    case UPDATE_PRODUCT:
      const updatedProduct = payload.producto
      return {
        ...state,
        product: updatedProduct,
      };
    case GET_SINGLE_PRODUCT:
      const foundProduct = payload.producto
      return {
        ...state,
        product: foundProduct,
      };
    case CREATE_PRODUCT:
      if (currProducts === null) currProducts = [];
      return {
        ...state,
        products: [...currProducts, payload],
      };
    case DELETE_PRODUCT:
      updatedProducts = state.products.filter(
        (product) => product.idProducto !== payload.idProducto
      );
      return {
        ...state,
        products: updatedProducts,
      };

    case CLONE_PRODUCT:
      if (currProducts === null) currProducts = [];
      return {
        ...state,
        products: [...currProducts, payload],
      };

    case GET_PRODUCT_INSUMOS:
      const productInsumos = payload.currentProductInsumos
      return {
        ...state,
        currentProductInsumos: productInsumos,
      };
    default:
      return { ...state };

    case UPDATE_PRODUCT_INSUMOS:
      const product_id = payload
      return {
        ...state,
        product: { ...state.product, idProducto: product_id, },
      };
  }
};

export default ProductsReducer;

import React from "react";
import ProveedorRow from "./ProveedorRow";

const ProveedoresTable = ({ proveedores }) => {

  const renderProveedores = () => {
    if (Array.isArray(proveedores)) {
      return proveedores.map((proveedor) => (
        <ProveedorRow key={proveedor.idProveedor} proveedor={proveedor} />
      ));
    }
  };
  return (
    <div className="proveedores-table-container">
      <table className="proveedores-table">
        <thead>
          <tr style={{ textAlign: 'start', fontWeight: 'bold' }}>
            <td>ID</td>
            <td> NOMBRE</td>
            <td>CONTACTO</td>
            <td>EMAIL</td>
            <td>TELÉFONO</td>
            <td>DOMICILIO</td>
            <td>CÓDIGO POSTAL</td>
            <td>RFC</td>
            <td style={{ textAlign: 'center' }}>ACCIONES</td>
          </tr>
        </thead>
        <tbody>{renderProveedores()}</tbody>
      </table>
    </div>
  );
};

export default ProveedoresTable;
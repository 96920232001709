import React, { useContext, useState, useEffect } from "react";
import { EntradasContext } from "../../context/EntradasContext";
import { AuthContext } from "../../context/AuthContext";
import BitacoraInput from "../bitacora/BitacoraInput";
import Entradas from "../bitacora/Entradas";
import { RevisionesContext } from "../../context/RevisionesContext";
import { ItemsContext } from "../../context/ItemsContext";
import { AdjuntosContext } from "../../context/AdjuntosContext";

const BitacoraItem = ({ idProyecto }) => {
  const [entradas, setEntradas] = useState([]);

  const { 
    currentRevision, 
    addItemToRevision, 
    isItemOnRevision
  } = useContext(RevisionesContext);

  const { 
    item, 
    itemsByRevision,
    getItemsByRevision,
  } = useContext(ItemsContext);

  const { 
    max, 
    postEntrada,
    getEntradasByRevisionItem
  } = useContext(EntradasContext);

  const { 
    uploadSingleAdjunto
  } = useContext(AdjuntosContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchEntradas();
  }, [itemsByRevision]);


  const handleSubmit = async (message, adjuntos) => {
    const [isOnRevision, revisionItem] = isItemOnRevision();

    const itemData = {
      idItem: item.idItem,
      estado: item.estado
    }

    if(isOnRevision) {
      const entradaData = { message };

      if(adjuntos.length > 0) {
        adjuntos.forEach(async (file) => {
          const data = await postAdjuntoOnRevision(revisionItem.idFolder, file);
          entradaData.idAdjunto = data.idAdjunto;

          await postEntradaOnRevision(entradaData, revisionItem);
        });
      } else {
        postEntradaOnRevision(entradaData, revisionItem);
      }


    } else {
      const { data } = await addItemToRevision(itemData, currentRevision, idProyecto);
      const entradaData = { message };

      if(adjuntos.length > 0) {
        adjuntos.forEach(async (file) => {
          const adjuntoData = await postAdjuntoOnRevision(data.idFolder, file);
          entradaData.idAdjunto = adjuntoData.idAdjunto;
          await postEntradaOnRevision(entradaData, data);
        });
      } else {
        postEntradaOnRevision(entradaData, data);
      }
    }
  };

  const postAdjuntoOnRevision = async (idFolder, adjunto) => {
    return await uploadSingleAdjunto(idProyecto, idFolder, adjunto);
  }

  const postEntradaOnRevision = async (entradaData, revisionItem) => {
    entradaData.idRevisionItem = revisionItem.idRevisionItem;
      await postEntrada(idProyecto, entradaData);
      await getItemsByRevision(currentRevision.idRevision);
      fetchEntradas();
  }

  const fetchEntradas = async () => {
    const [isOnRevision, revisionItem] = isItemOnRevision();

    if(isOnRevision) {
      const data = await getEntradasByRevisionItem(revisionItem?.idRevisionItem);
      setEntradas(data);
    }
  };

  return (
    <div>
      <Entradas
        scrollCallback={fetchEntradas}
        idUsuario={user.idUsuario}
        idProyecto={idProyecto}
        entradas={entradas}
        user={user}
        max={max}
      />
      <BitacoraInput modifier={handleSubmit} />
    </div>
  );
};

export default BitacoraItem;

import React, { useEffect, useState, useContext } from "react";
import { ProveedoresContext } from "../../context/ProveedoresContext";
import { ProyectosContext } from "../../context/ProyectosContext";

const ProveedorForm = ({ proveedorToEdit, handleCancel }) => {
    const { createProveedor, updateProveedor } = useContext(ProveedoresContext);
    const { getAllProyectos, proyectos } = useContext(ProyectosContext);
    const [proveedor, setProveedor] = useState({});

    const initialProveedorState = {
        nombre: "Provider A",
        contact: "John Doe",
        email: "john.doe@example.com",
        domicilio: 'Calle A 123',
        codigoPostal: '81270',
        numeroTelefono: "123 - 456 - 7890",
        RFC: "DEF789012UVW",
    };

    useEffect(() => {
        // Edit proveedor
        if (proveedorToEdit) {
            setProveedor(proveedorToEdit);
        } else {
            // Create new proveedor
            setProveedor({
                ...initialProveedorState,
            });
        }
        getAllProyectos();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProveedor({ ...proveedor, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!proveedor) {
            return;
        }

        //update
        if (proveedorToEdit) {
            updateProveedor(proveedor);
            //create
        } else {
            createProveedor(proveedor);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="form proveedor-form">
            <label>Nombre:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="text"
                name="nombre"
                value={proveedor.nombre}
                onChange={handleChange}
            />

            <label>Contacto:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="text"
                name="contact"
                value={proveedor.contact}
                onChange={handleChange}
            />

            <label>Email:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="email"
                name="email"
                value={proveedor.email}
                onChange={handleChange}
            />

            <label>Domicilio:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="text"
                name="domicilio"
                value={proveedor.domicilio}
                onChange={handleChange}
            />

            <label>Código Postal:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="text"
                name="codigoPostal"
                value={proveedor.codigoPostal}
                onChange={handleChange}
            />

            <label>Numero Teléfono:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="text"
                name="numeroTelefono"
                value={proveedor.numeroTelefono}
                onChange={handleChange}
            />
            <label>RFC:</label>
            <input
                className="form-control bg-light py-2 mb-4"
                type="text"
                name="RFC"
                value={proveedor.RFC}
                onChange={handleChange}
            />

            <div className="w-100 d-flex mt-3">
                <button className="btn me-1 w-50" onClick={handleCancel}>
                    Cancelar
                </button>
                <button className="btn btn-primary ms-1 w-50" type="submit">
                    {proveedorToEdit ? "Editar proveedor" : "Agregar Proveedor"}
                </button>
            </div>
        </form>
    );
};

export default ProveedorForm;

import {
  GET_REVISIONES,
  SET_CURRENT_REVISION
} from "../types/revisiones";

export default (state, { type, payload }) => {
  switch (type) {
    case GET_REVISIONES:
      return { ...state, revisiones: payload}
    case SET_CURRENT_REVISION:
      return { ...state, currentRevision: payload}
    default:
      return { ...state };
  }
};

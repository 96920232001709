import React from "react";
import { IonItem, IonLabel, IonButton } from "@ionic/react";

const DestajoMobile = ({
  destajo,
  collapsed,
  setDestajo,
  toggleSubdestajos,
}) => {
  return (
    <IonItem key={destajo.idDestajo} className="custom-ionitem">
      {/* <CheckBox checked={isComplete(destajo)} disabled /> */}
      <IonLabel className="ion-text-wrap" style={{ marginLeft: "1.6x" }}>
        {/*<span className="small bold me-2" style={{ color: "gray" }}>
          {formatPonderacion(destajo.progreso)}
          </span>*/}
        <button
          slot="end"
          fill="light"
          className="btn btn-outline-primary"
          onClick={() => toggleSubdestajos(destajo.idDestajo)}
        >
          <i className={`fa fa-chevron-${collapsed ? "down" : "up"}`}></i>
        </button>
        <span className="small ps-2 bold" style={{ color: "#b76d4f" }}>
          {destajo.nombre}
        </span>
      </IonLabel>
      <IonButton
        slot="end"
        fill="light"
        onClick={() => setDestajo(destajo)}
        style={{ color: "#b76d4f" }}
        className=" mx-1 px-1 border-0"
      >
        <i className="fa fa-edit"></i>
      </IonButton>
      <IonButton
        slot="end"
        fill="light"
        style={{ color: "#b76d4f" }}
        onClick={() => setDestajo(destajo)}
        className=" mx-1 px-1 border-0"
      >
        <i className="fa fa-info"></i>
      </IonButton>
    </IonItem>
  );
};

export default DestajoMobile;

import React, { useState, useEffect, useContext } from 'react';
import { ProyectosContext } from "../../context/ProyectosContext";
import { WarehousesContext } from "../../context/WarehousesContext";

const WarehouseForm = ({ warehouseToEdit, handleCancel }) => {

    const [selectedParentProject, setSelectedParentProject] = useState('');
    const [name, setName] = useState('');


    const { proyectos, getAllProyectos } =
        useContext(ProyectosContext);

    const projects = proyectos ? proyectos : [];

    const { createWarehouse, updateWarehouse } =
        useContext(WarehousesContext);

    useEffect(() => {
        if (warehouseToEdit) {
            setName(warehouseToEdit.name)
            setSelectedParentProject(warehouseToEdit.parentProject)
        }
    }, [warehouseToEdit])


    useEffect(() => {
        getAllProyectos();
    }, []);

    const handleParentProjectChange = (event) => {
        setSelectedParentProject(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        if (warehouseToEdit) {
            const warehouseData = { name: name, parentProject: selectedParentProject }
            updateWarehouse(warehouseData, warehouseToEdit.id)
        } else {
            const newWarehouseData = { name: name, parentProject: selectedParentProject }
            createWarehouse(newWarehouseData)
        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='mb-3'>
                <p className="mb-1" htmlFor="parentProject">Proyecto:</p>
                <select
                    id="parentProject"
                    value={selectedParentProject}
                    className='form-control'
                    onChange={handleParentProjectChange}
                    required
                    disabled={warehouseToEdit ? true : false}
                >
                    <option value="" disabled>
                        Selecciona un Proyecto
                    </option>
                    {projects.map((project) => (
                        <option key={project.id} value={project.idProyecto}>
                            {project.nombre}
                        </option>
                    ))}
                </select>
            </div>
            <div className='mb-3'>
                <p className="mb-1" htmlFor="name">Nombre del Almacén:</p>
                <input
                    className='form-control'
                    type="text"
                    id="name"
                    value={name}
                    onChange={handleNameChange}
                    required
                />
            </div>

            <div className="w-100 d-flex mt-5">
                <button className="btn me-1 w-50" onClick={handleCancel}>
                    Cancelar
                </button>
                <button className="btn btn-primary ms-1 w-50" type="submit"> {warehouseToEdit ? 'Editar Almacén' : 'Añadir Almacén'} </button>
            </div>

        </form>
    );
};

export default WarehouseForm;

import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import logo from '../../assets/logo_login.png';


const InfoCardColumn = ({ usuario, imgSrc, proyectName, editUsuario, deleteUsuario, platform, handleNavigate }) => {
  const { S3_ENDPOINT } = useContext(AppConfigContext);

  let currentImgSrc = `${S3_ENDPOINT}/files/adjuntos/${usuario.idAdjunto}.${usuario.tipo_adjunto}`;
  
  if(usuario.idAdjunto === null || usuario.tipo_adjunto === null) {
    currentImgSrc = logo;
  }

  if(usuario.idAdjunto === undefined || usuario.tipo_adjunto === undefined) {
    currentImgSrc = logo;
  }

  const renderContent = () => {
    if (platform === "web") {
      return (
        <div className="card-body position-relative d-flex flex-column 
        justify-content-center align-items-center ">
          <div className="dropdown position-absolute top-0 end-0 mt-2 me-2">
            <button
              className="btn btn-sm btn-outline-secondary"
              type="button"
              id={`dropdownMenuButton-${usuario.idUsuario}`}
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#db8c63", border: "none", fontSize: "20px" }}
            >
              ...
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby={`dropdownMenuButton-${usuario.idUsuario}`}
            >
              <button
                className="dropdown-item"
                onClick={() => editUsuario(usuario)}
              >
                <i className="fa fa-edit" /> Editar
              </button>
              <button
                className="dropdown-item"
                onClick={() => deleteUsuario(usuario)}
              >
                <i className="fa fa-trash" /> Eliminar
              </button>
            </div>
          </div>
          <img
            src={imgSrc ? imgSrc : currentImgSrc}
            alt={usuario.nombre || usuario.nombre_usuario}
            className="card-img-top rounded-circle mb-3"
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
          <h5
            className="card-title text-center"
            style={{
              maxWidth: "230px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {usuario.nombre || usuario.nombre_usuario}
          </h5>

          <h5
            className="card-title text-center"
            style={{
              maxWidth: "230px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {proyectName}
          </h5>

          <p className="card-text text-uppercase text-primary">
            {usuario.permiso ? usuario.permiso : null}
          </p>

          <p
            className="card-text"
            style={{
              maxWidth: "230px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <i className={`fa fa-envelope ${usuario.correo ? '' : 'd-none'}`} />
            <span className="ms-2">{usuario.correo}</span>
          </p>
        </div>
      );
    } else {
      return (
        <div 
          className="card-body position-relative d-flex flex-column 
          justify-content-start align-items-center px-0"
          onClick={() => {
            if(typeof handleNavigate === 'function') {
              handleNavigate(usuario.idCliente);
            }
          }}
        >

          <div className="position-relative mb-3">
            <div 
              className={`position-absolute border border-white
              ${usuario.color ? '' : 'd-none'}`} 
              style={{
                backgroundColor: usuario.color,
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                top: 'calc(50% - 15px)',
                left: '-15px'
              }}> 
            </div>

            <img
              src={imgSrc ? imgSrc : currentImgSrc}
              alt={usuario.nombre || usuario.nombre_usuario}
              className="card-img-top rounded-circle"
              style={{
                maxWidth: "75px",
                objectFit: "cover",
              }}
            />
          </div>
 
          <div>
            <div className="dropdown position-absolute top-0 end-0 mt-2 me-2">
              <button
                onClick={(e) => e.stopPropagation()}
                className="btn btn-sm border-0"
                type="button"
                id={`dropdownMenuButton-${usuario.idUsuario}`}
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  fontSize: "20px",
                }}
              >
                <i className="fa fa-ellipsis-h text-primary fs-3"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby={`dropdownMenuButton-${usuario.idUsuario}`}
                onClick={(e) => e.stopPropagation()}

              >
                <button
                  className="dropdown-item"
                  onClick={() => editUsuario(usuario)}
                >
                  <i className="fa fa-edit" /> Editar
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => deleteUsuario(usuario)}
                >
                  <i className="fa fa-trash" /> Eliminar
                </button>
              </div>
            </div>
            <h5
              className=" text-start"
              style={{
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
             
            >
              {usuario.nombre || usuario.nombre_usuario}
            </h5>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={`${platform === "web" ? "card m-2" : "card col-12 mt-3"} px-0`}
      style={platform === "web" ? { width: "18rem" } : { width: "" }}
    >
      {renderContent()}
    </div>
  );
};

export default InfoCardColumn;

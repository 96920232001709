import React, { useState } from "react";
import {
  IonModal,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonIcon,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import "./guardarMovil.css";
import { FaSave } from "react-icons/fa";

const CrearItem = ({
  showModal,
  setShowModal,
  UltimoAvance,
  comentarios,
  idChecklist,
  idProyecto,
  postItem,
  handleCancel,
}) => {
  const [nombre, setNombre] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    postItem(idProyecto, { nombre, idChecklist });
  };

  return (
    <IonModal
      isOpen={showModal}
      onDidDismiss={() => setShowModal(false)}
      initialBreakpoint={0.7}
      breakpoints={[0.7, 0.8]}
    >
      <IonContent className="py-4 ps-4">
        <IonGrid>
          <form
            className="py-4 ps-4"
            style={{ paddingRight: "30px" }}
            onSubmit={onSubmit}
          >
            <IonRow>
              <IonCol>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2 style={{ fontWeight: "700" }}>Crear Item</h2>
                  <IonIcon
                    icon={closeOutline}
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => setShowModal(false)}
                  />
                </div>
                <h4 className="h5">Ingresar la siguiente información</h4>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel className="mt-4 h6 titleInputPlantilla mt-5">
                  NOMBRE
                </IonLabel>
                <IonInput
                  value={nombre}
                  type="message"
                  className="form-control mb-3"
                  onIonChange={(e) => setNombre(e.target.value)}
                ></IonInput>
              </IonCol>
            </IonRow>
            <div className="row align-items-center">
              <div className="col-12 text-end mt-2">
                <button type="button" className="btn" onClick={handleCancel}>
                  CANCELAR
                </button>
                <button className="btn btn-primary me-2" type="submit">
                  <FaSave className="me-2" />
                  GUARDAR
                </button>
              </div>
            </div>
          </form>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default CrearItem;

import React, { useContext, useEffect } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { PartidasContext } from "../../context/PartidasContext";
import { EntradasContext } from "../../context/EntradasContext";
import { AuthContext } from "../../context/AuthContext";
import BitacoraInput from "../bitacora/BitacoraInput";
import Entradas from "../bitacora/Entradas";
import ItemDetail from "./ItemDetail";

const PartidaDetail = ({ idProyecto, partida, callback }) => {
  const { getSinglePartida, postSinglePartida, setPropertySinglePartida } =
    useContext(PartidasContext);

  const { postEntrada } = useContext(EntradasContext);

  const { proyecto } = useContext(ProyectosContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchPartida();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPartida = () => {
    getSinglePartida(idProyecto, partida.idPartida);
  };

  const postComentario = (message, files) => {
    postEntrada(
      idProyecto,
      { message, files, idPartida: partida.idPartida },
      fetchPartida
    );
  };

  const renderComments = () => {
    if (Array.isArray(partida.Entradas)) {
      return (
        <div className="my-3">
          <h6 className="bold">Comentarios</h6>
          <Entradas
            user={user}
            height="auto"
            idProyecto={idProyecto}
            entradas={partida.Entradas}
          />
          <BitacoraInput modifier={postComentario} />
        </div>
      );
    }
  };

  return (
    <div className="container-fluid pb-4">
      <ItemDetail
        id="idPartida"
        item={partida}
        itemLabel="Partida"
        proyecto={proyecto}
        callback={callback}
        saveItem={postSinglePartida}
        modifier={setPropertySinglePartida}
      />
      {renderComments()}
    </div>
  );
};

export default PartidaDetail;

import React, { useContext } from "react";
import { ProveedoresContext } from "../../context/ProveedoresContext";
import { ModalContext } from "../../context/ModalContext";
import ProveedorForm from "../../components/proveedores/ProveedorForm";
import { useNavigate } from "react-router-dom";


const ProveedoresHandler = () => {
    const { modalComponent, clearModal } = useContext(ModalContext);
    const { deleteProveedor } = useContext(ProveedoresContext);
    const navigate = useNavigate()

    const handleCancel = () => {
        clearModal();
    };

    const handleCreateProveedor = () => {
        modalComponent(
            "Añadir Proveedor:",
            <ProveedorForm
                handleCancel={handleCancel}
                title={"Agregar Proveedor"}
            />
        );
    };

    const handleEditProveedor = (proveedor) => {
        modalComponent(
            `Editar Proveedor:`,
            <ProveedorForm
                handleCancel={handleCancel}
                title={"Editar Proveedor"}
                proveedorToEdit={proveedor}
            />
        );
    };

    const handleDeleteProveedor = (idProveedor, goBack) => {

        const onDelete = () => {
            deleteProveedor(idProveedor);
            if (goBack) {
                navigate(-1);
            }
        };

        modalComponent(
            "¿Eliminar Proveedor?",
            <div className="w-100 d-flex mt-3">
                <button className="btn me-1 w-50" onClick={clearModal}>
                    Cancelar
                </button>
                <button className="btn btn-danger ms-1 w-50" onClick={onDelete}>
                    Eliminar Proveedor
                </button>
            </div>
        );
    };


    return {
        handleCreateProveedor,
        handleEditProveedor,
        handleDeleteProveedor,
    };
};

export default ProveedoresHandler;

import React, { useContext, useEffect } from "react";
import { UsuariosContext } from "../../context/UsuariosContext";
import { PermisosContext } from "../../context/PermisosContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import AdjuntosService from "../../services/AdjuntosService";
import ImageInput from "../common/ImageInput";

const UsuarioForm = ({ handleCancel }) => {
  const { usuario, postUsuario, setPropiedadUsuario } = useContext(UsuariosContext);
  const { S3_ENDPOINT } = useContext(AppConfigContext);
  const { permisos, getPermisos } = useContext(PermisosContext);

  useEffect(() => {
    getPermisos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(permisos)) {
      handlePermiso();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permisos]);

  const handlePermiso = () => {
    if (usuario.idPermiso === null) {
      const current = permisos[1];
      setPropiedadUsuario("idPermiso", current.idPermiso);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (usuario.file && usuario.file !== null) {
      let formData = AdjuntosService.getFormData(usuario.file);
      // console.log(usuario.file);
      console.log(formData);
      AdjuntosService.postAdjunto(formData).then((res) => {
        const { idAdjunto } = res.data;
        postUsuario({ ...usuario, idAdjunto }, handleCancel);
      });
    } else {
      postUsuario(usuario, handleCancel);
    }
  };

  const renderPermisos = () => {
    if (permisos && permisos !== null) {
      return permisos.map((permiso) => (
        <option
          className="text-capitalize"
          key={permiso.idPermiso}
          value={permiso.idPermiso}
        >
          {permiso.nombre}
        </option>
      ));
    }
  };

  const getAdjuntoValue = () => {
    if (usuario?.tipo_adjunto !== null) {
      return `${S3_ENDPOINT}/files/adjuntos/${usuario?.idAdjunto}.${usuario?.tipo_adjunto}`;
    }
  };

  return (
    <form onSubmit={onSubmit} className="container-fluid">
      <div className="d-flex">
        <div className="col-8">
          <label className="mb-2 titleInputPlantilla">NOMBRE</label>
          <input
            type="text"
            value={usuario?.nombre}
            className="form-control mb-4"
            placeholder="Juan Pérez"
            onChange={(e) => setPropiedadUsuario("nombre", e.target.value)}
          />
        </div>
        <div className="col-4 ps-3">
          <label className="mb-2 ps-2 titleInputPlantilla">FOTO</label>

          <div className="d-flex flex-row align-items-center">
            {/* <img
              src={userImgSrc}
              alt={usuario.nombre}
              className="card-img-top rounded-circle"
              style={{ maxWidth: "50px", objectFit: "cover" }}
            /> */}

            <ImageInput
              hideLabel
              value={getAdjuntoValue()}
              modifier={(file) => setPropiedadUsuario("file", file)}
            />
          </div>
        </div>
      </div>

      <div className="d-flex">
        <div className="col-8">
          <label className="mb-2 titleInputPlantilla">CORREO</label>
          <input
            type="email"
            value={usuario?.correo}
            className="form-control mb-3"
            placeholder="hola@ejemplo.com"
            onChange={(e) => setPropiedadUsuario("correo", e.target.value)}
          />
        </div>

        <div className="col-4 ps-4">
          <label className="mb-2 titleInputPlantilla">PERMISO</label>
          <select
            value={usuario?.defaultType}
            className="form-control mb-3"
            onChange={(e) => setPropiedadUsuario("defaultType", e.target.value)}
          >
            {renderPermisos()}
          </select>
        </div>
      </div>
      {/*<div style={{ display: "flex", alignItems: "center" }}>
        <label
          className="mb-2 titleInputPlantilla mt-3"
          style={{ marginRight: "30px" }}
        >
          ACCESO
        </label>
        <ReactSwitch
          value={!usuario.disabled}
          onChange={(checked) => setPropiedadUsuario("disabled", checked)}
        />
          </div>*/}
      <div className="d-flex justify-content-end mt-3">
        <button className="btn" type="button" onClick={handleCancel}>
          CANCELAR
        </button>
        <button className="btn btn-primary">
          <i className="fa fa-save"></i> GUARDAR
        </button>
      </div>
    </form>
  );
};

export default UsuarioForm;

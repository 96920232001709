import React, { useContext, useEffect, useState } from "react";
import ListaClientes from "../components/clientes/ListaClientes";
import ClienteForm from "../components/clientes/ClienteForm";
import { ClientesContext } from "../context/ClientesContext";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";
import TabOptions from "../components/common/TabOptions";
import { CapacitorContext } from "../context/CapacitorContext";
import MobileSubHeader from "../components/movil/MobileSubHeader";
import { isCoordinador } from "../utils/permisos";
import { AuthContext } from "../context/AuthContext";
import MobileModal from "../components/movil/MobileModal";

const Clientes = () => {
  const { confirm, clearModal, modalComponent } = useContext(ModalContext);
  const { selectTabs } = useContext(MenuContext);
  const { platform } = useContext(CapacitorContext);
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [cliente, setCliente] = useState(null);


  const [isTableView, setIsTableView] = useState(platform === "web");
  const [query, setQuery] = useState("");

  const { user } = useContext(AuthContext);
  const { setSelected } = useContext(MenuContext);


  const {
    clientes,
    getClientes,
    editCliente,
    clearCliente,
    createCliente,
    deleteCliente,
  } = useContext(ClientesContext);

  useEffect(() => {
    selectTabs();
    getClientes();
    editCliente(null);
    setSelected({ name: "clientes" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    clearModal();
    clearCliente();
  };

  const handleEditCliente = (cliente) => {
    editCliente(cliente);

    if(platform === 'web') {
      modalComponent(
        "Editar CLiente",
        <ClienteForm handleCancel={handleCancel} />
      );
    } else {
      setShowModal(true)
    }
  };

  const handleCreateCliente = () => {
    createCliente();

    if(platform === 'web') {
      modalComponent(
        "Crear Cliente",
        <ClienteForm handleCancel={handleCancel} />
      );
    } else {
      setShowModal(true);
    }

  };

  const handleDelete = (cliente) => {
    if(platform === 'web') {
      confirm(
        `¿Estás seguro que deseas eliminar el cliente ${cliente.nombre}? Esta acción NO puede deshacerse.`,
        () => deleteCliente(cliente.idCliente)
      );
    } else {
      setCliente(cliente);
      setShowDelete(true);
    }
  };

  const renderClientes = () => {
    let currentClients = clientes;

    if (query && query !== "") {
      let currentQuery = String(query).toLowerCase();
      currentClients = clientes.filter((client) => {
        let nombre = String(client.nombre).toLowerCase();
        return nombre.includes(currentQuery);
      });

    }
    return (
      <ListaClientes
        query={query}
        platform={platform}
        clientes={currentClients}
        isTableView={isTableView}
        editCliente={handleEditCliente}
        deleteCliente={handleDelete}
      />
    );
  };

  const renderAddBtn = () => {
    if(isCoordinador(user));
    return(
      <div className="col-12 px-0 col-md-6 text-end">
        <button 
          className="btn btn-primary" 
          onClick={handleCreateCliente}
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>
    )
  }

  if(platform === 'web') {
    return(
      <div className="container-fluid view-container ">
        <TabOptions
          query={query}
          setQuery={setQuery}
          isTableView={isTableView}
          setIsTableView={setIsTableView}
          handleCreate={handleCreateCliente}
          buttonCreateText={'+ NUEVO CLIENTE'}
          searchInputPlaceholder={'Buscar cliente...'}
        />

        {renderClientes()}
      </div>
    )
  } else {
    return(
      <div className="container-fluid view-container px-0 ">
        <MobileSubHeader 
          title="Clientes"
        >
          {renderAddBtn()}
        </MobileSubHeader>

        <div className="col-12" style={{height: 'max-content'}}>
          <input
            type="text"
            value={query}
            className="form-control bg-white"
            placeholder="Buscar cliente..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        {renderClientes()}

        <MobileModal
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <ClienteForm handleCancel={() => setShowModal(false)} />
        </MobileModal>

        <MobileModal
          showModal={showDelete}
          setShowModal={setShowDelete}
        >
          <h3>Precaución</h3>
          <p>¿Estás seguro que deseas eliminar el cliente {cliente?.nombre}? Esta acción NO puede deshacerse.</p>

          <div className="row text-end">
            <div className="col-6">
              <button 
                className="w-100 btn btn-light"
                onClick={() => setShowDelete(false)}
              >
                Cancelar
              </button>
            </div>

            <div className="col-6">
              <button 
                className="w-100 btn btn-danger"
                onClick={() => deleteCliente(cliente.idCliente)}
              >
                Eliminar
              </button>
            </div>
          </div>

        </MobileModal>
      </div>
    )
  }
 
};

export default Clientes;

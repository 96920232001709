import {
  SET_PROGRESS,
  SET_LAST_ITEM,
  SET_LAST_POST,
  CHECKLISTS_RECIBIDOS,
  SET_PROPERTY_CHECKLIST,
  SINGLE_CHECKLIST_RECIBIDO,
} from "../types/checklist";

const ChecklistReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_PROGRESS:
      return { ...state, progreso: payload };
    case CHECKLISTS_RECIBIDOS:
      return { ...state, checklists: payload };
    case SINGLE_CHECKLIST_RECIBIDO: {
      return { ...state, checklist: payload };
    }
    case SET_PROPERTY_CHECKLIST: {
      const { key, value } = payload;
      const { checklist } = { ...state };
      checklist[key] = value;
      return { ...state, checklist };
    }
    case SET_LAST_ITEM:
      return { ...state, item: payload };
    case SET_LAST_POST:
      return { ...state, entrada: payload };
    default:
      return { ...state };
  }
};
export default ChecklistReducer;

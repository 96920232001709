import React, { useContext } from "react";
import ClienteRow from "./ClienteRow";
import Table from "../common/Table";
import InfoCard from "../common/InfoCard";
import { AppConfigContext } from "../../context/AppConfigContext";
import InfoCardColumn from "../common/InfoCardColumn";
import { useNavigate } from "react-router-dom";

const ListaClientes = ({
  query,
  clientes,
  platform,
  editCliente,
  deleteCliente,
  isTableView,
}) => {
  const { S3_ENDPOINT } = useContext(AppConfigContext);

  const navigate = useNavigate();

  const handleNavigate = (idCliente) => {
    navigate(`/clientes/${idCliente}`);
  }

  const renderClientes = () => {
    if (Array.isArray(clientes)) {
      if (clientes.length === 0) {
        return <p>Aún no has agregado usuarios.</p>;
      }
      return clientes.map((cliente) => {
        // const imgSrc = `${S3_ENDPOINT}/files/adjuntos/${cliente.Adjunto?.idAdjunto}.${cliente.Adjunto?.tipo}`

        return(
          <div key={cliente.idCliente} className="col-6">
            <InfoCardColumn
              usuario={cliente}
              platform={platform}
              editUsuario={editCliente}
              deleteUsuario={deleteCliente}
              handleNavigate={handleNavigate}
            />
          </div>
          
        )
      });
    }
  };

  const renderTableColumns = () => {
    return(
      <>
        <th className="col-5 py-4 ps-5">Cliente</th>
        <th className="col-1 py-4 ps-2">Foto</th>
        <th className="col-3 py-4 ps-1">Color</th>
        <th className="col-2 py-4 ps-1">Acciones</th>
      </>
    )
  }

  const renderClientesRows = () => {
    return clientes?.map((cliente) => (
      <ClienteRow
        cliente={cliente}
        key={cliente.idCliente}
        editCliente={editCliente}
        deleteCliente={deleteCliente}
      />
    ));
  } 


  const renderContent = () => {
    if (isTableView) {
      return (
        <Table
          data={clientes}
          query={query}
          renderItems={renderClientesRows}
          renderColumns={renderTableColumns}
        />
      );
    }

    return (
      <div className="row justify-content-start flex-wrap">
        {renderClientes()}
      </div>
    )
  };


  return renderContent();
};

export default ListaClientes;

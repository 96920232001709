import React, { useContext } from "react";
import { ConceptosContext } from "../../context/ConceptosContext";
import { DestajosContext } from "../../context/DestajosContext";
import { formatMonto } from "../../utils";
import ReactSwitch from "react-switch";
import moment from "moment";

const PreviewConcepto = ({ concepto }) => {
  const { destajos } = useContext(DestajosContext);
  const { deleteConcepto, setPropiedadConcepto } = useContext(ConceptosContext);
  const { clave, unidad, cantidad, precio_unitario } = concepto;

  const renderFecha = (key) => {
    if (key === "inicio") {
      if (concepto.fecha_inicio && concepto.fecha_inicio !== null) {
        return moment(concepto.fecha_inicio).utc().format("YYYY-MM-DD");
      }
    } else if (concepto.fecha_fin && concepto.fecha_fin !== null) {
      return moment(concepto.fecha_fin).utc().format("YYYY-MM-DD");
    }
  };

  const renderDestajo = () => {
    if (Array.isArray(destajos)) {
      if (destajos.length === 0) {
        return (
          <ReactSwitch
            checked={concepto.cronograma}
            onChange={(checked) =>
              setPropiedadConcepto(concepto.idConcepto, "cronograma", checked)
            }
          />
        );
      }
      return (
        <select
          className="form-control mb-3"
          onChange={(e) =>
            setPropiedadConcepto(
              concepto.idConcepto,
              "cronograma",
              e.target.value
            )
          }
        >
          {[
            <option value={null}>Sin Asignar</option>,
            <option value="create">Crear un Nuevo</option>,
            ...destajos.map((destajo) => (
              <option value={destajo.idDestajo}>{destajo.nombre}</option>
            )),
          ]}
        </select>
      );
    }
  };
  return (
    <tr>
      <td>{clave}</td>
      <td>{concepto.concepto}</td>
      <td>{unidad}</td>
      <td>{cantidad}</td>
      <td>${formatMonto(precio_unitario)}</td>
      <td>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => deleteConcepto(concepto.idConcepto)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
      <td>{renderDestajo()}</td>
      <td>{renderFecha("inicio")}</td>
      <td>{renderFecha("fin")}</td>
    </tr>
  );
};

export default PreviewConcepto;

import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getValue } from "../../utils";

const ProgresoPartidaChart = ({ sqSize, partidas, onClick }) => {
  const appconfig = useContext(AppConfigContext);
  //Funcion para obtener un rectangulo con esquinas derechas redondas
  const rightRoundedRect = (x, y, width, height, radius) => {
    if (!width) width = 0;
    return (
      "M" +
      x +
      "," +
      y +
      "h" +
      (width - radius) +
      "a" +
      radius +
      "," +
      radius +
      " 0 0 1 " +
      radius +
      "," +
      radius +
      "v" +
      (height - 2 * radius) +
      "a" +
      radius +
      "," +
      radius +
      " 0 0 1 " +
      -radius +
      "," +
      radius +
      "h" +
      (radius - width) +
      "z"
    );
  };

  const getProgreso = (partida) => {
    if (getValue(appconfig, "progress") === "money") {
      return (parseFloat(partida.progreso) / 100).toFixed(2);
    }
    return parseFloat(partida.real).toFixed(2);
  };

  const getRectWidth = () => {
    return sqSize;
  };

  const getBarHeight = (barHeight) => {
    return barHeight + 50;
  };

  const barHeight = 30;
  const rectWidth = getRectWidth();
  const separation = getBarHeight(barHeight);
  const rectHeight = partidas.length * separation;
  const left = (sqSize - rectWidth) / 2;
  const chartBeginX = left ;
  const textBeginX = left ;

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          maxHeight: 400,
          position: "relative",
        }}
      >
        <svg width={'max-content'} height={rectHeight} className="mt-3">
          <defs>
            {/* Gradientes para relleno */}
            <linearGradient id="real" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#89D405" stopOpacity="100%" />
              <stop offset="100%" stopColor="#B3FF00" stopOpacity="100%" />
            </linearGradient>
            <linearGradient id="programado" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#007991" stopOpacity="100%" />
              <stop offset="100%" stopColor="#05D4C6" stopOpacity="100%" />
            </linearGradient>
            <filter id="dropshadow" width={"100%"} height={"115%"} x="0" y="0">
              <feDropShadow dx="0" dy="-4" stdDeviation="4" />
            </filter>
          </defs>
       
          {partidas.map((partida, index) => {
            let textOffset = 15;
            let barOffset = 25;
            let real = getProgreso(partida);
            return (
              <svg
                width={'100%'}
                key={"progreso-partida-" + partida.idPartida}
                onClick={() => onClick(partida.idPartida)}
              >
                <text
                  x={0}
                  y={index * separation + textOffset}
                  textAnchor={textOffset === 30 ? "end" : ""}
                >
                  {partida.nombre}
                </text>
                <path
                  d={rightRoundedRect(
                    chartBeginX,
                    index * separation + barOffset,
                    partida.programado * rectWidth,
                    barHeight,
                    5
                  )}
                  fill="url(#programado)"
                  x={0}
                />
                <path
                  d={rightRoundedRect(
                    chartBeginX,
                    index * separation + barOffset,
                    real * rectWidth,
                    barHeight,
                    5
                  )}
                  fill="url(#real)"
                  filter="url(#dropshadow)"
                />
              </svg>
            );
          })}
        </svg>
      </div>
      <div style={{ width: "100%", position: "relative", zIndex: 1000 }}>
        <svg  width="100%" height="40">
          <line
            x1={chartBeginX}
            x2={chartBeginX + rectWidth}
            y1={10}
            y2={10}
            stroke="#ddd"
            strokeWidth={1}
          />
          {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
            (porcentaje, index) => {
              let coordX =
                (rectWidth * porcentaje) / 100 + chartBeginX + left * 2;
              return (
                <g key={"porcentaje-label-partida-" + index}>
                  <text
                    className="small"
                    x={coordX - 8}
                    y={25}
                    key={"text-partida-" + index}
                  >
                    {porcentaje}
                    {"%"}
                  </text>
                  <line
                    x1={coordX}
                    x2={coordX}
                    y1={5}
                    y2={15}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                </g>
              );
            }
          )}
        </svg>
      </div>
    </div>
  );
};

export default ProgresoPartidaChart;

import React from "react";

const TabOptions = ({
  query,
  setQuery,
  handleCreate,
  isTableView,
  setIsTableView,
  buttonCreateText,
  searchInputPlaceholder
}) => {
  return (
    <div className="row align-items-end hide-mobile mb-3">
      <div className="col col-md-6 text-start ps-4">
        <button
          className="btn btn-outline-primary text-uppercase"
          onClick={handleCreate}
        >
          {buttonCreateText}
        </button>
        <i
          className={`fa fa-bars ms-3 ${
            isTableView ? "text-primary" : "text-muted"
          }`}
          style={{
            cursor: "pointer",
            fontSize: "20px",
          }}
          onClick={() => setIsTableView(true)}
        />
        <i
          className={`fa fa-th-large ms-3 ${
            !isTableView ? "text-primary" : "text-muted"
          }`}
          style={{
            cursor: "pointer",
            fontSize: "20px",
          }}
          onClick={() => setIsTableView(false)}
        />
      </div>
      <div className="col col-md-6">
        <input
          type="text"
          value={query}
          className="form-control bg-white"
          placeholder={searchInputPlaceholder}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
    </div>
  );
};

export default TabOptions;
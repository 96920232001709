import React, { createContext, useContext, useReducer } from "react";
import {
  CONCEPTOS_EXTRA_RECIBIDOS,
  HIDE_SPINNER,
  SET_EDIT_MODE,
  SHOW_SPINNER,
} from "../actions/types";
import {
  CONCEPTOS_RECIBIDOS,
  CREATE_CONCEPTO,
  DELETE_CONCEPTO,
  SET_PROPERTY_CONCEPTO,
} from "../types/conceptos";
import { validarConceptos } from "../utils";
import ConceptosService from "../services/ConceptosService";
import ConceptosReducer from "../reducers/ConceptosReducer";
import { ModalContext } from "./ModalContext";
import { SELECT_ESTIMACION, SET_CONCEPTO_PRECIOS } from "../types/estimaciones";

const initialState = {
  conceptosExtra: null,
  conceptos: null,
  concepto_precios: null,
  spinner: false,
};

function getColValue(concepto, col) {
  const anterior = `${col}Anterior`;
  const value = parseFloat(concepto[col]) - parseFloat(concepto[anterior]);
  if (value > 0) return value;
  return 0;
}

export const ConceptosContext = createContext(initialState);

export const ConceptosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ConceptosReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  const getConceptosContrato = (idProyecto, idContrato) => {
    ConceptosService.getConceptosContrato(idProyecto, idContrato).then(
      (res) => {
        const { conceptos, conceptosExtra } = res.data;
        dispatch({ type: CONCEPTOS_RECIBIDOS, payload: conceptos });
        dispatch({ type: CONCEPTOS_EXTRA_RECIBIDOS, payload: conceptosExtra });
      }
    );
  };

  const getConceptosEstimacion = (idProyecto, idEstimacion) => {
    ConceptosService.getConceptosEstimacion(idProyecto, idEstimacion).then(
      (res) => {
        const { conceptos } = res.data;
        dispatch({
          type: SELECT_ESTIMACION,
          payload: conceptos,
        });
      }
    );
  };


  const getConceptoPrecios = (idConcepto, idProyecto) => {
    ConceptosService.getConceptoPrecios(idConcepto, idProyecto).then(
      (res) => {
        const { precios } = res.data;
        
        dispatch({
          type: SET_CONCEPTO_PRECIOS,
          payload: precios,
        });
      }
    );
  };

  const setConceptos = (conceptos) => {
    dispatch({ type: CONCEPTOS_RECIBIDOS, payload: conceptos });
  };

  const createConcepto = (idPartida) => {
    dispatch({ type: CREATE_CONCEPTO, payload: idPartida });
  };

  const setPropiedadConcepto = (idConcepto, key, value) => {
    dispatch({
      type: SET_PROPERTY_CONCEPTO,
      payload: { idConcepto, key, value },
    });
  };

  const deleteConcepto = (idConcepto) => {
    dispatch({ type: DELETE_CONCEPTO, payload: idConcepto });
  };

  const postConceptos = async (idProyecto, idContrato, conceptos, callback) => {
    if (!validarConceptos(conceptos)) {
      return alert(
        "Todos los conceptos deben tener al menos una clave, nombre y unidad."
      );
    }
    dispatch({ type: SHOW_SPINNER });
    if (conceptos.length > 100) {
      let iterations = Math.ceil(conceptos.length / 100);
      for (let i = 0; i < iterations; i++) {
        let currentConceptos = [...conceptos].slice(i * 100, (i + 1) * 100);
        await ConceptosService.postConceptos(
          idProyecto,
          idContrato,
          currentConceptos
        );
      }
      dispatch({ type: SET_EDIT_MODE, payload: null });
      success("Conceptos guardados con éxito.");
      dispatch({ type: HIDE_SPINNER });
      if (typeof callback === "function") {
        callback();
      }
    } else {
      ConceptosService.postConceptos(idProyecto, idContrato, conceptos)
        .then(() => {
          dispatch({ type: HIDE_SPINNER });
          if (typeof callback === "function") {
            callback();
          }
          dispatch({ type: SET_EDIT_MODE, payload: null });
          success("Conceptos guardados con éxito.");
        })
        .catch((error) => {
          dispatch({ type: HIDE_SPINNER });
          alert(error);
        });
    }
  };

  const postConceptosEstimacion = (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptos,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    if (!conceptos || conceptos === null) {
      return alert("No hay conceptos para esta estimación");
    }
    conceptos = conceptos.map((concepto) => {
      if (
        concepto.estimadoAnterior > 0 &&
        parseFloat(concepto.estimado) !== parseFloat(concepto.estimadoAnterior)
      ) {
        concepto.estimado = getColValue(concepto, "estimado");
        concepto.supervisado = getColValue(concepto, "supervisado");
      }
      return concepto;
    });
    const promises = [];
    const iterations = conceptos.length / 25;
    for (let i = 0; i < iterations; i++) {
      const current = conceptos.slice(i * 25, i * 25 + 25);
      promises.push(
        ConceptosService.postConceptosEstimacion(
          idProyecto,
          idContrato,
          idEstimacion,
          current
        )
      );
    }
    Promise.all(promises)
      .then((res) => {
        success("Conceptos actualizados con éxito.");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        //alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const postConceptosEstimacionUpload = (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptos,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    if (!conceptos || conceptos === null) {
      return alert("No hay conceptos para esta estimación");
    }
    conceptos = conceptos.map((concepto) => {
      if (
        concepto.estimadoAnterior > 0 &&
        parseFloat(concepto.estimado) !== parseFloat(concepto.estimadoAnterior)
      ) {
        concepto.estimado = getColValue(concepto, "estimado");
        concepto.supervisado = getColValue(concepto, "supervisado");
      }
      return concepto;
    });
    const promises = [];
    const iterations = Math.ceil(conceptos.length / 25);
    for (let i = 0; i < iterations; i++) {
      const current = [...conceptos].slice(i * 25, i * 25 + 25);
      promises.push(
        ConceptosService.postConceptosEstimacionUpload(
          idProyecto,
          idContrato,
          idEstimacion,
          current
        )
      );
    }
    Promise.all(promises)
      .then((res) => {
        success("Conceptos actualizados con éxito.");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const postConceptosAutorizados = (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptos,
    conceptosExtra
  ) => {
    dispatch({ type: SHOW_SPINNER });
    if (!Array.isArray(conceptos)) {
      return alert("No hay conceptos para esta estimación");
    }
    if (Array.isArray(conceptosExtra)) {
      if (conceptosExtra.length > 0) {
        ConceptosService.postConceptosAutorizadosExtra(
          idProyecto,
          idContrato,
          idEstimacion,
          conceptosExtra
        )
          .then((res) => {
            success("Conceptos autorizados con éxito.");
          })
          .catch((error) => {
            dispatch({ type: HIDE_SPINNER });
            // alert(error);
          });
      }
    }
    conceptos = conceptos.map((concepto) => {
      if (
        concepto.autorizadoAnterior > 0 &&
        parseFloat(concepto.autorizadoAnterior) !==
          parseFloat(concepto.autorizado)
      ) {
        concepto.autorizado = getColValue(concepto, "autorizado");
      }
      return concepto;
    });
    const promises = [];
    const iterations = Math.ceil(conceptos.length / 25);
    for (let i = 0; i < iterations; i++) {
      const current = conceptos.slice(i * 25, i * 25 + 25);
      promises.push(
        ConceptosService.postConceptosAutorizados(
          idProyecto,
          idContrato,
          idEstimacion,
          current
        )
      );
    }
    Promise.all(promises)
      .then((res) => {
        success("Conceptos actualizados con éxito.");
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const postConceptosAutorizadosUpload = (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptos
  ) => {
    dispatch({ type: SHOW_SPINNER });
    if (!Array.isArray(conceptos)) {
      return alert("No hay conceptos para esta estimación");
    }
    conceptos = conceptos.map((concepto) => {
      if (
        concepto.autorizadoAnterior > 0 &&
        parseFloat(concepto.autorizadoAnterior) !==
          parseFloat(concepto.autorizado)
      ) {
        concepto.autorizado = getColValue(concepto, "autorizado");
      }
      return concepto;
    });
    const promises = [];
    const iterations = Math.ceil(conceptos.length / 25);
    for (let i = 0; i < iterations; i++) {
      const current = conceptos.slice(i * 25, i * 25 + 25);
      promises.push(
        ConceptosService.postConceptosAutorizadosUpload(
          idProyecto,
          idContrato,
          idEstimacion,
          current
        )
      );
    }
    Promise.all(promises)
      .then((res) => {
        success("Conceptos actualizados con éxito.");
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };
  return (
    <ConceptosContext.Provider
      value={{
        ...state,
        setConceptos,
        postConceptos,
        createConcepto,
        deleteConcepto,
        getConceptosContrato,
        getConceptoPrecios,
        setPropiedadConcepto,
        getConceptosEstimacion,
        postConceptosEstimacion,
        postConceptosAutorizados,
        postConceptosEstimacionUpload,
        postConceptosAutorizadosUpload,
        
      }}
    >
      {children}
    </ConceptosContext.Provider>
  );
};

import React from "react"

export default function DropDown({
  title,
  btnOutlined,
  children,
}) {


  return(
    <div className="dropdown">
      <div
        data-bs-toggle="dropdown" 
        aria-expanded="false"
      >
        <button 
          className={`btn w-100 ${btnOutlined ? 'btn-outline-light': 'btn-light' } dropdown-toggle`}
          type="button" 
        >
          {title}
        </button>
      </div>
      
      <ul className="dropdown-menu p-0 border border-primary" style={{minWidth: '190px'}}>
        {children}
      </ul>
    </div>
  )
}
import { Link } from "react-router-dom";
import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";
import DateRange from "../global/DateRange";

const ChecklistCard = ({ checklist, handleDelete, handleEdit }) => {
  const { idChecklist, nombre } = checklist;

  const renderFechaLimite = () => {
    const fecha_inicio = moment(checklist.fecha_inicio);
    const fecha_limite = moment(checklist.fecha_fin);
    if (fecha_limite.isValid() && fecha_inicio.isValid()) {
      return (
        <DateRange
          end_date={checklist.fecha_fin}
          start_date={checklist.fecha_inicio}
        />
      );
    }
  };

  const renderChart = () => {
    let { completados, items } = checklist;
    if (completados === null) completados = 0;
    if (items !== null && items > 0) {
      let progreso = ((parseInt(completados) / parseInt(items)) * 100).toFixed(
        2
      );
      return (
        <Chart
          type="radialBar"
          options={{
            stroke: {
              lineCap: "round",
            },
            colors: ["#89d405"],
            dataLabels: {
              enabled: false,
            },
            labels: [`${completados} / ${items}`],
            fill: {
              type: "gradient",
              gradient: {
                shade: "dark",
                stops: [0, 100],
                gradientToColors: ["#B3FF00"],
              },
            },
            plotOptions: {
              radialBar: {
                track: {
                  background: ["#ddd"],
                },
              },
            },
            chart: {
              offsetX: 0,
              offsetY: -5,
              width: '100%'

            }
          }}
          series={[progreso]}
        />
      );
    }
  };

  return (
    <Link to={`./${idChecklist}`}>
      <div className="row align-items-center">
        <div className="col-4 px-0">
          <div className="chart-wrapper">{renderChart()}</div>
        </div>

        <div className="col-8 px-0">
          <h4 className="">{nombre}</h4>
          {renderFechaLimite()}
          <div className="row mt-3">

            <div className="col-12 col-md-6 px-0">
              <div className="row align-items-center">

                <div className="col-6 px-0">
                  {checklist.buen_estado !== null && (
                    <span className="badge small badge-pill bg-success me-2">
                      <i className="fa fa-check me-1"></i> {checklist.buen_estado}
                    </span>
                  )}
                  {checklist.mantenimiento !== null && (
                    <span className="badge small text-dark badge-pill bg-warning">
                      <i className="fa fa-exclamation-circle me-1"></i>{" "}
                      {checklist.mantenimiento}
                    </span>
                  )}
                </div>
                

                <div className="col-6 ps-0 col-md-6 text-end">
                  <button
                    className="btn btn-sm btn-outline-dark me-2"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleEdit(checklist);
                    }}
                  >
                    <i className="fa fa-edit"></i>
                  </button>

                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDelete(checklist);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
      
    </Link>
  );
};
export default ChecklistCard;

import React, { useContext } from "react";
import { PartidasContratoContext } from "../../context/PartidasContratoContext";

const UploadSheet = () => {
  const { preciario, setPreciario } = useContext(PartidasContratoContext);

  const renderInput = () => {
    if (preciario === null) {
      return (
        <input
          type="file"
          accept="spreadsheet"
          className="form-control"
          onChange={(e) => setPreciario(e.target.files[0])}
        />
      );
    }
    return (
      <div className="container-fluid px-0">
        <h5 className="bold">Archivo Cargado</h5>
        <p>{preciario.name}</p>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => setPreciario(null)}
        >
          <i className="fa fa-edit me-1"></i>Cambiar Archivo
        </button>
      </div>
    );
  };

  return <div className="card p-3 shadow mb-3">{renderInput()}</div>;
};

export default UploadSheet;

import React, { useContext, useEffect } from "react";
import PreviewConcepto from "./PreviewConcepto";
import { ConceptosContext } from "../../context/ConceptosContext";
import { useNavigate } from "react-router-dom";
import { DestajosContext } from "../../context/DestajosContext";

const PreviewConceptosTable = ({ idProyecto, idContrato }) => {
  const navigate = useNavigate();
  const { getDestajos } = useContext(DestajosContext);
  const { spinner, conceptos, postConceptos } = useContext(ConceptosContext);

  useEffect(() => {
    getDestajos(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitCallback = () => {
    navigate(`/obra/${idProyecto}/finanzas`);
  };

  const handleSubmitConceptos = () => {
    console.log(conceptos);
    
    // postConceptos(idProyecto, idContrato, conceptos, handleSubmitCallback);
  };

  const renderConceptos = () => {
    if (Array.isArray(conceptos)) {
      let conceptosRender = [...conceptos];
      conceptosRender = conceptosRender.filter(
        (concepto) => String(concepto.idConcepto)[0] !== "d"
      );
      return (
        <div className="card p-3 my-3 shadow">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr className="border bg-light bold">
                  <th className="col">Clave</th>
                  <th className="col">Concepto</th>
                  <th className="col">Unidad</th>
                  <th className="col">Cantidad</th>
                  <th className="col">Precio Unitario</th>
                  <th className="col">Acciones</th>
                  <th className="col">Cronograma</th>
                  <th className="col">Fecha Inicio</th>
                  <th className="col">Fecha Fin</th>
                </tr>
              </thead>
              <tbody>
                {conceptosRender.map((concepto) => (
                  <PreviewConcepto
                    key={concepto.idConcepto}
                    concepto={concepto}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div className="container-fluid px-0 my-3">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleSubmitConceptos}
              disabled={spinner}
            >
              <i className="fa fa-save me-1"></i>{" "}
              {spinner ? (
                <div className="spinner-border"></div>
              ) : (
                "Guardar Conceptos"
              )}
            </button>
          </div>
        </div>
      );
    }
  };
  return <div>{renderConceptos()}</div>;
};

export default PreviewConceptosTable;

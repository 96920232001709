import React, { useEffect, useContext } from "react";
import { MenuContext } from "../context/MenuContext";
import Folder from "./Folder";

const Catalogo = ({ idProyecto }) => {
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected({ name: "catalogo" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Folder
      title="Catálogo"
      idFolder="catalogo"
      idProyecto={parseInt(idProyecto)}
    />
  );
};

export default Catalogo;

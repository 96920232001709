import React, { useContext, useEffect, useState } from "react";
import { SubdestajosContext } from "../../context/SubdestajosContext";
import { CronogramaContext } from "../../context/CronogramaContext";
import { PartidasContext } from "../../context/PartidasContext";
import { DestajosContext } from "../../context/DestajosContext";
import SubdestajoDetail from "./SubdestajoDetail";
import SubdestajoMobile from "./SubdestajoMobile";
import { IonList, IonModal } from "@ionic/react";
import DestajoDetail from "./DestajoDetail";
import PartidaDetail from "./PartidaDetail";
import PartidaMobile from "./PartidaMobile";
import DestajoMobile from "./DestajoMobile";

const CronogramaMobile = ({
  idProyecto,
  getAllItems,
  subdestajos,
  partidas,
  destajos,
}) => {
  const [query, setQuery] = useState("");
  const [fetching, setFetching] = useState(false);

  const {
    showDestajos,
    showSubDestajos,
    setOpenPartidas,
    setOpenDestajos,
    toggleOpenPartida,
    toggleOpenDestajo,
  } = useContext(CronogramaContext);

  const { partida, setPartida, clearSinglePartida } =
    useContext(PartidasContext);

  const { destajo, setDestajo, clearSingleDestajo } =
    useContext(DestajosContext);

  const { subdestajo, setSubdestajo, clearSingleSubdestajo } =
    useContext(SubdestajosContext);

  useEffect(() => {
    if (query !== "" && !fetching) {
      setFetching(true);
    } else if (query === "") {
      resetItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const resetItems = () => {
    setQuery("");
    setFetching(false);
    setOpenPartidas([]);
    setOpenDestajos([]);
  };

  const hasOpenItems = () => {
    return showDestajos.length > 0 || showSubDestajos.length > 0;
  };

  const searchItems = (query, items, idItem, filtered) => {
    query = String(query).toLowerCase();
    return items.filter((item) => {
      let nombre = String(item.nombre).toLowerCase();
      const nameMatch = nombre.startsWith(query) || nombre.includes(query);
      if (idItem && filtered) {
        return nameMatch || filtered.includes(item[idItem]);
      }
      return nameMatch;
    });
  };

  const handleStopSearch = (destajosQuery, subdestajosQuery) => {
    setTimeout(() => {
      if (query !== "" && fetching) {
        setFetching(false);
        setOpenPartidas(destajosQuery);
        setOpenDestajos(subdestajosQuery);
      }
    }, 500);
  };

  const handleSearch = () => {
    let subdestajosQuery = searchItems(query, subdestajos);
    subdestajosQuery = subdestajosQuery.map(({ idDestajo }) => idDestajo);
    let destajosQuery = searchItems(query, destajos);
    destajosQuery = destajosQuery
      .map(({ idPartida }) => idPartida)
      .concat(subdestajosQuery);
    const partidasQuery = searchItems(
      query,
      partidas,
      "idPartida",
      destajosQuery
    );
    handleStopSearch(destajosQuery, subdestajosQuery);
    return partidasQuery;
  };

  const getDestajos = (idPartida) => {
    let current = [];
    if (Array.isArray(destajos)) {
      current = destajos.filter(
        (destajo) => String(destajo.idPartida) === String(idPartida)
      );
    }
    return current;
  };

  const getSubdestajos = (idDestajo) => {
    let current = [];
    if (Array.isArray(subdestajos)) {
      current = subdestajos.filter(
        (subdestajo) => String(subdestajo.idDestajo) === String(idDestajo)
      );
    }
    return current;
  };

  const renderPartidas = () => {
    if (Array.isArray(partidas)) {
      const components = [];
      let partidasRender = partidas;
      if (query !== "" && query !== null) {
        partidasRender = handleSearch();
      }
      partidasRender.forEach((partida) => {
        components.push(
          <PartidaMobile
            partida={partida}
            destajos={destajos}
            setPartida={setPartida}
            key={partida.idPartida}
            subdestajos={subdestajos}
            toggleDestajos={toggleOpenPartida}
            collapsed={!showDestajos.includes(partida.idPartida)}
          />
        );
        if (showDestajos.includes(partida.idPartida)) {
          const currentDestajos = getDestajos(partida.idPartida);
          currentDestajos.forEach((destajo) => {
            components.push(
              <DestajoMobile
                destajo={destajo}
                setDestajo={setDestajo}
                key={`des-${destajo.idDestajo}`}
                toggleSubdestajos={toggleOpenDestajo}
                collapsed={!showSubDestajos.includes(destajo.idDestajo)}
              />
            );
            const { idDestajo } = destajo;
            if (showSubDestajos.includes(idDestajo)) {
              const currentSubdestajos = getSubdestajos(idDestajo);
              currentSubdestajos.forEach((subdestajo) => {
                components.push(
                  <SubdestajoMobile
                    key={`sub-${subdestajo.idSubDestajo}`}
                    setSubdestajo={setSubdestajo}
                    subdestajo={subdestajo}
                  />
                );
              });
            }
          });
        }
      });
      if (components.length === 0 && !fetching) {
        return (
          <p className="mx-2 px-3 small">
            No encontramos resultados para tu búsqueda.
          </p>
        );
      }
      return components;
    }
  };

  const renderModal = () => {
    return partida !== null || destajo !== null || subdestajo !== null;
  };

  const renderItem = () => {
    if (partida !== null) {
      return (
        <PartidaDetail
          idProyecto={idProyecto}
          callback={getAllItems}
          partida={partida}
        />
      );
    }
    if (destajo !== null) {
      return (
        <DestajoDetail
          idProyecto={idProyecto}
          callback={getAllItems}
          destajo={destajo}
        />
      );
    }
    if (subdestajo !== null) {
      return (
        <SubdestajoDetail
          idProyecto={idProyecto}
          subdestajo={subdestajo}
          callback={getAllItems}
        />
      );
    }
  };

  const handleCloseModal = () => {
    clearSinglePartida();
    clearSingleDestajo();
    clearSingleSubdestajo();
  };

  return (
    <div className="container py-2 h-100" >
      <div className="br-10 shadow border-0 pr-3 py-2 bg-white h-100" >
        <div className="row mx-0 px-3 align-items-center">
          <div className={`col-${hasOpenItems() ? "10" : "12"}`}>
            <input
              type="text"
              value={query}
              placeholder="Buscar una partida o concepto..."
              className="form-control px-3 my-2 ml-2"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div
            className={`col-2 text-center ${hasOpenItems() ? "" : "d-none"}`}
          >
            <button className="btn border" onClick={resetItems}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
        {fetching && <div className="spinner-border m-3 small"></div>}
        <IonList className="cronograma-mobile " style={{overflowY: 'auto', overflowX: 'hidden'}}>{renderPartidas()}</IonList>
      </div>
      <IonModal
        className="p-3"
        handleBehavior="cycle"
        isOpen={renderModal()}
        initialBreakpoint={0.3}
        breakpoints={[0.3, 0.75, 0.95]}
        onDidDismiss={handleCloseModal}
      >
        <div className="p-3 py-4">{renderItem()}</div>
      </IonModal>
    </div>
  );
};

export default CronogramaMobile;

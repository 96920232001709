import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import ConceptoEstimacion from "./ConceptoEstimacion";
import ConceptoAutorizado from "./ConceptoAutorizado";
import { formatMonto, getValue } from "../../utils";
import ConceptoEstimado from "./ConceptoEstimado";
import { Link } from "react-router-dom";

const ConceptoPreciario = ({
  permiso,
  editMode,
  concepto,
  idProyecto,
  estimacion,
  modalComponent,
  showEstimaciones,
  setPropiedadConcepto,
}) => {
  const appconfig = useContext(AppConfigContext);

  function renderEstimacion() {
    if (showEstimaciones) {
      if (
        editMode === "conceptos-estimacion" &&
        ["admin", "supervisor"].includes(permiso)
      ) {
        return (
          <ConceptoEstimado
            permiso={permiso}
            concepto={concepto}
            estimacion={estimacion}
            idProyecto={idProyecto}
            modifier={setPropiedadConcepto}
          />
        );
      }
      if (
        editMode === "conceptos-estimacion" &&
        ["admin", "finanzas", "obras"].includes(permiso)
      )
        return (
          <ConceptoAutorizado
            concepto={concepto}
            modifier={setPropiedadConcepto}
            estimacion={estimacion}
            idProyecto={idProyecto}
            modalComponent={modalComponent}
          />
        );
      return <ConceptoEstimacion concepto={concepto} />;
    }
  }

  function getTipo(concepto) {
    if (concepto.extra) return "Extra";
    if (concepto.autorizado && concepto.autorizado !== null) {
      if (
        parseFloat(concepto.autorizado) > parseFloat(concepto.cantidad) ||
        parseFloat(concepto.autorizadoActual) +
          parseFloat(concepto.autorizado) >
          parseFloat(concepto.cantidad)
      ) {
        return "Aditivo";
      }
    }
    return "Normal";
  }

  function renderDestajo() {
    if (concepto.nombre_destajo && concepto.nombre_destajo !== null) {
      return concepto.nombre_destajo;
    }
  }

  const renderPrecio = () => {
    if (getValue(appconfig, "costs_enabled", "boolean")) {
      return (
        <Link
          className="text-underline text-primary"
          to={`/obra/${idProyecto}/finanzas/conceptos/${concepto.idConcepto}`}
        >
          {"$"}
          {formatMonto(precio_actual)}
        </Link>
      );
    }
    console.log(concepto);
    return <span>${formatMonto(precio_unitario)}</span>;
  };

  const { clave, unidad, cantidad, precio_unitario, precio_actual, importe } =
    concepto;

  const tipo = getTipo(concepto);

  return (
    <tr className={`concepto-row border-bottom concepto-${tipo.toLowerCase()}`}>
      <td>{tipo}</td>
      <td className="min-120">{clave}</td>
      <td className="nombre-concepto">
        {String(concepto.nombre).substring(0, 50)}
      </td>
      <td>{renderDestajo()}</td>
      <td>{unidad}</td>
      <td>{cantidad}</td>
      <td>{renderPrecio()}</td>
      <td>
        {"$"}
        {formatMonto(importe)}
      </td>
      {renderEstimacion()}
    </tr>
  );
};

export default ConceptoPreciario;

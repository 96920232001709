import React, { useState, useContext, useEffect, useRef } from "react";
import ProgresoPartidaChart from "../charts/ProgresoPartidaChart";
import { ProyectosContext } from "../../context/ProyectosContext";
import { PartidasContext } from "../../context/PartidasContext";
import { RetrasosContext } from "../../context/RetrasosContext";
import ColorLegend from "../common/ColorLegend";
import Retrasos from "./Retrasos";

const TrabajosRetrasados = () => {
  const [selectedPartida, setSelectedPartida] = useState(null);
  const { getRetrasos } = useContext(RetrasosContext);
  const { proyecto } = useContext(ProyectosContext);
  const { partidas } = useContext(PartidasContext);
  const { idProyecto, permiso } = proyecto;

  const chartContainer = useRef();

  useEffect(() => {
    getRetrasos(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(idPartida) {
    setSelectedPartida(idPartida);
    chartContainer.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    
  }

  function renderRetrasos() {
    if (selectedPartida && Array.isArray(partidas)) {
      const currentPartida = partidas.find(
        (partida) => partida.idPartida === selectedPartida
      );
      if (currentPartida) {
        return (
          <Retrasos
            idProyecto={idProyecto}
            partida={currentPartida}
            permiso={permiso}
          />
        );
      }
    }
    return <p>Selecciona una partida para mostrar comentarios</p>;
  }

  const renderChart = () => {
    if (Array.isArray(partidas)) {
      return (
        <ProgresoPartidaChart
          sqSize={
            window.innerWidth < 767
              ? window.innerWidth * 0.8
              : window.innerWidth * 0.4
          }
          partidas={partidas}
          onClick={handleClick}
        />
      );
    }
  };

  return (
    <div className="row" ref={chartContainer}>
      <div className="col-md-12 col-lg-6 col-xl-8">
        <h4>Progreso por Partida</h4>
        <div className="container-fluid px-0">{renderChart()}</div>
        <div className="container-fluid pt-2 px-0">
          <ColorLegend colors={colors} />
        </div>
      </div>
      <div className="col-md-12 col-lg-6 col-xl-4" style={{paddingBottom: '70px'}}>
        <h4>Comentarios</h4>
        {renderRetrasos()}
      </div>
    </div>
  );
};

const colors = [
  { title: "Real", color: "#89d405", className: "real-gradiente" },
  { title: "Programado", color: "#007991", className: "programado-gradiente" },
];

export default TrabajosRetrasados;

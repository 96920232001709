import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DestajosContext } from "../../context/DestajosContext";
import { ConceptosContext } from "../../context/ConceptosContext";
import PreviewConceptoEstimacion from "./PreviewConceptoEstimacion";

const PreviewConceptosEstimacionTable = ({
  permiso,
  idProyecto,
  idContrato,
  idEstimacion,
}) => {
  const navigate = useNavigate();
  const { getDestajos } = useContext(DestajosContext);
  const {
    spinner,
    conceptos,
    postConceptosEstimacionUpload,
    postConceptosAutorizadosUpload,
  } = useContext(ConceptosContext);

  useEffect(() => {
    getDestajos(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitCallback = () => {
    navigate(`/obra/${idProyecto}/finanzas/${idContrato}`);
  };

  const handleSubmitConceptos = () => {
    postConceptosEstimacionUpload(
      idProyecto,
      idContrato,
      idEstimacion,
      conceptos,
      handleSubmitCallback
    );
    if (["admin", "obras", "finanzas"].includes(permiso)) {
      const hasAutorizados = conceptos.find(
        (concepto) =>
          concepto.autorizado !== null &&
          concepto.autorizado > 0 &&
          concepto.autorizado !== undefined
      );
      if (hasAutorizados) {
        postConceptosAutorizadosUpload(
          idProyecto,
          idContrato,
          idEstimacion,
          conceptos,
          handleSubmitCallback
        );
      }
    }
  };

  const renderConceptos = () => {
    if (Array.isArray(conceptos)) {
      let conceptosRender = [...conceptos];
      conceptosRender = conceptosRender.filter(
        (concepto) => String(concepto.idConcepto)[0] !== "d"
      );
      return (
        <div className="card p-3 my-3 shadow">
          <div className="container-fluid">
            <div className="row border bg-light bold py-2">
              <div className="col">Clave</div>
              <div className="col">Cantidad Estimada</div>
              <div className="col">Acciones</div>
            </div>
            {conceptosRender.map((concepto) => (
              <PreviewConceptoEstimacion
                key={concepto.idConcepto}
                concepto={concepto}
              />
            ))}
          </div>
          <div className="container-fluid px-0 my-3">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleSubmitConceptos}
              disabled={spinner}
            >
              <i className="fa fa-save me-1"></i>{" "}
              {spinner ? (
                <div className="spinner-border"></div>
              ) : (
                "Guardar Conceptos"
              )}
            </button>
          </div>
        </div>
      );
    }
  };
  return <div>{renderConceptos()}</div>;
};

export default PreviewConceptosEstimacionTable;

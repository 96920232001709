import React, { useState, useContext, useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ProyectosContext } from "../../context/ProyectosContext";
import { ChecklistContext } from "../../context/ChecklistContext";
import { ModalContext } from "../../context/ModalContext";
import EvidenciaForm from "../items/EvidenciaForm";
import ItemRowForm from "../items/ItemRowForm";
import { getSectionItems } from "../../utils";
import ItemForm from "../items/ItemForm";
import ItemRow from "../items/ItemRow";
import arrayMove from "array-move";
import SectionForm from "../items/SectionForm";
import { ItemsContext } from "../../context/ItemsContext";
import "../../css/ChecklistSection.css";
import { RevisionesContext } from "../../context/RevisionesContext";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import moment from "moment";
import MobileModal from "../movil/MobileModal";
import { CapacitorContext } from "../../context/CapacitorContext";


const ChecklistSection = ({
  idSection,
  handleCreateItem,
  handleComments,
  handleCallback,
  idChecklist,
  idProyecto,
  editMode,
  section,
  expand,
  estado,
  query,
}) => {
  const [showItems, setShowItems] = useState(false);
  const [showModalSection, setShowModalSection] = useState(false);
  const [showModalEvidencia, setShowModalEvidencia] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const { platform } = useContext(CapacitorContext);

  const { 
    getSingleChecklist, 
    postChecklistSection, 
    deleteChecklistSection 
  } = useContext(ChecklistContext);

  const {
    items,
    itemsByRevision,
    getItemsByRevision,
    setItem,
    deleteItem,
    completeItem,
    setPropertyItem,
    putChecklistItems,
    uploadEvidencia
  } = useContext(ItemsContext);

  const { 
    uploadSingleAdjunto
  } = useContext(AdjuntosContext);

  const { 
    alert
  } = useContext(ModalContext);

  const { currentRevision, updateItemByRevision, addItemToRevision } = useContext(RevisionesContext);
  const { proyecto } = useContext(ProyectosContext);
  const { confirm, clearModal, modalComponent } = useContext(ModalContext);


  useEffect(() => {
    getItemsByRevision(currentRevision?.idRevision, idProyecto);
  }, [currentRevision]);

  useEffect(() => {
    setShowItems(expand);
  }, [expand]);


  const handleComplete = (idItem, completed) => {
    // completeItem(idProyecto, idItem, idChecklist, handleCallback);
    const itemOnRev = findItemOnRevision(idItem);
    const idRevisionItem = itemOnRev?.idRevisionItem;

    const revisionUpdated = {
      completedAt: moment().format()
    }

    if(completed) revisionUpdated.completedAt = null;
    if(itemOnRev) {
      updateItemByRevision(idRevisionItem, revisionUpdated);
    } else {
      alert('Es necesario adjuntar evidencia para completar')
    }
  };

  const findItemOnRevision = (idItem) => {
    const itemOnRev = itemsByRevision?.find(obj => {
      return obj.idItem === idItem;
    });

    return itemOnRev;
  }

  const handleCancel = () => {
    clearModal();
    getSingleChecklist(idProyecto, idChecklist);
  };

  const handleEditSection = () => {
    if(platform === 'web') {
      modalComponent(
        "Editar Sección",
        <SectionForm
          section={section}
          idProyecto={idProyecto}
          idChecklist={idChecklist}
          handleCancel={handleCancel}
          postItem={postChecklistSection}
          idChecklistSection={section.idChecklistSection}
        />
      );
    } else {
      setShowModalSection(true);
    }
  };

  const handleEdit = (item) => {
    setItem(item);
    modalComponent(
      "Editar Item",
      <ItemForm
        idProyecto={idProyecto}
        idChecklist={idChecklist}
        handleCancel={handleCancel}
      />
    );
  };

  const handleUpload = (item) => {
    setCurrentItem(item);

    if(platform === 'web') {
      modalComponent(
        "Agregar Evidencia",
        <EvidenciaForm
          item={item}
          handleCancel={handleCancel}
          handleSubmit={(item, file) => handleUploadEvidencia(idProyecto, item, file)}
        />
      );
    } else {
      setShowModalEvidencia(true);
    }

  };

  const handleUploadEvidencia = async (idProyecto, item, file) => {
    const itemRev = findItemOnRevision(item.idItem);
    const itemData = {
      idItem: item.idItem,
      estado: item.estado
    }

    if(itemRev) {
      uploadSingleAdjunto(idProyecto, itemRev.idFolder, file);
    } else {
      const { data } = await addItemToRevision(itemData, currentRevision, idProyecto);
      uploadSingleAdjunto(data.idFolder, file);
    }
  }

  const handleDeleteSection = () => {
    if(platform === 'web') {
      confirm(
        `¿Estás seguro que deseas eliminar la sección ${section.nombre}? Esta acción NO puede deshacerse y todos los items serán eliminados.`,
        () =>
          deleteChecklistSection(
            idProyecto,
            idChecklist,
            section.idChecklistSection
          )
      );
    } else {
      setShowDelete(true);
    }
    
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newItems = arrayMove(items, oldIndex, newIndex).map(
        (item, index) => {
          return { ...item, idItem: index + 1 };
        }
      );
      putChecklistItems(idProyecto, newItems, handleCancel);
    }
  };

  const SortableItem = SortableElement(({ item }) => {
    let CustomComponent = editMode ? ItemRowForm : ItemRow;
    return (
      <CustomComponent
        item={item}
        focused={item}
        key={item.idItem}
        handleEdit={handleEdit}
        idProyecto={idProyecto}
        deleteItem={deleteItem}
        permiso={proyecto.permiso}
        handleUpload={handleUpload}
        completeItem={handleComplete}
        handleCallback={handleCallback}
        handleComments={handleComments}
        setPropertyItem={setPropertyItem}
      />
    );
  });

  const SortableList = SortableContainer(({ items, isEditable }) => {
    return (
      <div>
        {items.map((item, index) => (
          <SortableItem
            key={`item-${item.idItem}`}
            index={index}
            item={item}
            disabled={!isEditable}
          />
        ))}
      </div>
    );
  });

  const renderItems = () => {
    if (Array.isArray(items) && showItems) {
      let itemsRender = getSectionItems(
        items,
        section.idChecklistSection,
        query
      );
      itemsRender = itemsRender.filter(
        (item) => String(item.idItem)[0] !== "d"
      );
      if (itemsRender.length === 0 && !editMode) {
        return <p className="mb-0 p-2">No hay items registrados.</p>;
      }
      if (estado !== "todo") {
        itemsRender = itemsRender.filter((item) => {
          if (estado === "bueno") {
            return item.estado > 6;
          }
          return item.estado !== null && item.estado < 7;
        });
      }
      return (
        <SortableList
          lockAxis="y"
          items={itemsRender}
          onSortEnd={onSortEnd}
          isEditable={editMode}
        />
      );
    }
  };

  const renderSectionDropdown = () => {
    return(
      <div className="dropdown col-4 text-end px-0 show-mobile">
        <button
          className="btn btn-sm btn-outline-secondary px-0"
          type="button"
          id={`dropdownMenuButton-${idSection}`}
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{ color: "#db8c63", border: "none", fontSize: "20px" }}
        >
          <i className="fas fa-ellipsis-h text-primary fs-2"></i>
        </button>

        <div
          className="dropdown-menu"
          aria-labelledby={`dropdownMenuButton-${idSection}`}
        >
          <button
            onClick={() => handleCreateItem(section.idChecklistSection)}
            className="dropdown-item mb-2"
          >
            <i className="fa fa-plus me-2"></i>
            Agregar
          </button>

          <button className="dropdown-item mb-2" onClick={handleEditSection}>
            <i className="fa fa-edit me-2"></i>
            Editar
          </button>

          <button
            className="dropdown-item text-danger"
            onClick={handleDeleteSection}
          >
            <i className="fa fa-trash me-2"></i>
            Eliminar
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <div
        className="row align-items-center bg-light px-2 py-2 d-flex"
        style={{ borderTop: "2px solid #f4f6f8" }}
      >
        <div className="col-1 col-md-1 px-0">
          <button
            onClick={() => setShowItems(!showItems)}
            className="btn btn-light"
            style={{ backgroundColor: "#db8c63", color: "white" }}
          >
            <i className={`fa fa-chevron-${showItems ? "up" : "down"}`}></i>
          </button>
        </div>
        
        <div 
          className="col-11 col-md-11 align-items-center 
          d-flex justify-content-between "
        >
          <div className="col-8">
            <h5 
              className="mb-0 small" 
            >
              {section.nombre}
            </h5>
          </div>

          <div 
            className="col-4 col-md-3 position-relative dropdown  hide-mobile"
          >
            <div className="row justify-content-between">
              <button
                onClick={() => handleCreateItem(section.idChecklistSection)}
                className="col-4 btn btn-outline-primary section__btns hide-mobile"
              
              >
                <i className="fa fa-plus"></i>
              </button>

              <button className="col-4 btn btn-light border section__btns hide-mobile " onClick={handleEditSection}>
                <i className="fa fa-edit"></i>
              </button>

              <button
                className="col-4 btn btn-outline-danger section__btns hide-mobile"
                onClick={handleDeleteSection}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>

          {renderSectionDropdown()}
          
        </div>
      </div>
      {renderItems()}

      <MobileModal
        showModal={showModalSection}
        setShowModal={setShowModalSection}
      >
        <SectionForm
          section={section}
          idProyecto={idProyecto}
          idChecklist={idChecklist}
          handleCancel={() => setShowModalSection(false)}
          postItem={postChecklistSection}
          idChecklistSection={section.idChecklistSection}
        />
      </MobileModal>

      <MobileModal
        showModal={showModalEvidencia}
        setShowModal={setShowModalEvidencia}
      >
        <EvidenciaForm
          item={currentItem}
          handleCancel={() => setShowModalEvidencia(false)}
          handleSubmit={(item, file) => handleUploadEvidencia(idProyecto, item, file)}
        />
      </MobileModal>

      <MobileModal
        showModal={showDelete}
        setShowModal={setShowDelete}
      >
        <h3>Precaución</h3>
        <p>
          ¿Estás seguro que deseas eliminar la sección {section.nombre}? 
          Esta acción NO puede deshacerse y todos los items serán eliminados.
        </p>

        <div className="row text-end">
          <div className="col-6">
            <button 
              className="w-100 btn btn-light"
              onClick={() => setShowDelete(false)}
            >
              Cancelar
            </button>
          </div>

          <div className="col-6">
            <button 
              className="w-100 btn btn-danger"
              onClick={() => {
                deleteChecklistSection(
                  idProyecto,
                  idChecklist,
                  section.idChecklistSection
                )
              }}
            >
              Eliminar
            </button>
          </div>
        </div>
      </MobileModal>
    </div>
  );
};

export default ChecklistSection;

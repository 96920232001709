import React, { useContext, useEffect, useState } from "react";
import { ProyectosContext } from "../context/ProyectosContext";
import { AdjuntosContext } from "../context/AdjuntosContext";
import AdjuntoCard from "../components/folder/AdjuntoCard";
import FolderIcon from "../components/folder/FolderIcon";
import Breadcrumb from "../components/common/Breadcrumb";
import FileUpload from "../components/folder/FileUpload";
import FolderForm from "../components/folder/FolderForm";
import { FolderContext } from "../context/FolderContext";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";
import { notCliente } from "../utils/permisos";
import MobileSubHeader from "../components/movil/MobileSubHeader";

const Folder = ({ idProyecto, idFolder, hideFolderButton }) => {
  const [subHeaderTitle, setSubHeaderTitle] = useState('');
  const [folderActive, setFolderActive] = useState('');
  const { setAdjuntos, uploadAdjuntos } = useContext(AdjuntosContext);

  const { show, clearModal, modalComponent } = useContext(ModalContext);

  const { proyecto } = useContext(ProyectosContext);

  const { setSelected, selected } = useContext(MenuContext);

  const platform = "w";

  const { folder, getFolder, clearFolder, pushBreadcrumb, createSubFolder } =
    useContext(FolderContext);

  useEffect(() => {
    if(selected === 'supervision') setSubHeaderTitle('Supervisión');
    if(selected === 'catalogo') setSubHeaderTitle('Catálogo');
    if(selected === 'proyecto') setSubHeaderTitle('Proyecto');
    
  }, [selected]);

  useEffect(() => {
    fetchFolder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idFolder]);

  useEffect(() => {
    if (folder !== null) {
      setSelected({ name: folder.nombre });
      if(selected === 'supervision' ) setFolderActive(folder.nombre);
    } 
  }, [folder]);

  const uploadFilesModal = () => {
    if (show) {
      clearModal();
      return setTimeout(() => {
        modalComponent(
          "Subir Archivos",
          <FileUpload modifier={setAdjuntos} handleSubmit={handleUploadFiles} />
        );
      }, 1000);
    }
    modalComponent(
      "Subir Archivos",
      <FileUpload modifier={setAdjuntos} handleSubmit={handleUploadFiles} />
    );
  };

  const handleUploadFiles = (files) => {
    uploadAdjuntos(idProyecto, folder.idFolder, files, fetchFolder);
  };

  const fetchFolder = () => {
    clearFolder();
    getFolder(idProyecto, idFolder);
  };

  const handlePushBreadcrumb = () => {
    if (!folder.root) {
      pushBreadcrumb(folder);
    }
    clearFolder();
  };

  const handleCreateSubFolder = () => {
    createSubFolder();
    modalComponent(
      "Nueva Carpeta",
      <FolderForm
        idFolder="nuevo"
        idPadre={folder.idFolder}
        handleCallback={fetchFolder}
      />
    );
  };

  const renderCarpetas = () => {
    if (folder && folder !== null) {
      return folder.subfolders.map((folder) => (
        <FolderIcon
          folder={folder}
          key={folder.idFolder}
          idProyecto={idProyecto}
          editable={folder.editable}
          getParentFolder={getFolder}
          handleBreadcrumb={handlePushBreadcrumb}
        />
      ));
    }
  };

  const renderContenido = () => {
    if (folder !== null) {
      if (folder.subfolders.length === 0 && folder.adjuntos.length === 0) {
        return <p className="ps-4 mt-3">Aún no hay contenido</p>;
      }
      let carpetas = renderCarpetas();
      let adjuntos = renderAdjuntos();
      let components = carpetas.concat(adjuntos);
      return components;
    }
    return <div className="spinner-border m-3"></div>;
  };

  const renderAdjuntos = () => {
    if (folder !== null) {
      if (Array.isArray(folder.adjuntos) && folder.adjuntos.length > 0) {
        return(
          <div key={'adjuntos-container'} className="row px-2">
            {
              folder.adjuntos.map((adjunto) => (
                <AdjuntoCard
                  adjunto={adjunto}
                  key={adjunto.idAdjunto}
                  focused={adjunto}
                  idProyecto={idProyecto}
                  idFolder={folder.idFolder}
                />
              ))
            }
          </div>
        )
      }
    }
  };

  const renderCarpetaButton = () => {
    if (!hideFolderButton && notCliente(proyecto)) {
      return (
        <button
          onClick={handleCreateSubFolder}
          disabled={folder ? false : true}
          className="btn btn-primary me-2"
        >
          <i className="fa fa-plus"></i>
        </button>
      );
    }
  };

  const renderButtons = () => {
    if (proyecto !== null) {
      if (notCliente(proyecto)) {
        if (platform === "web") {
          return (
            <div className="mb-3">
              {renderCarpetaButton()}
              <button
                className="btn btn-outline-primary text-capitalize mb-2"
                onClick={uploadFilesModal}
              >
                + <span className="hide-mobile">Subir nuevos </span>archivos
              </button>
            </div>
          );
        } else {
          return (
            <div className="text-end">
              {renderCarpetaButton()}
              <button
                onClick={uploadFilesModal}
                className="btn btn-primary"
              >
                <i className="fa fa-upload"></i>
              </button>
            </div>
          );
        }
      }
    }
  };

  return (
    <div className="container-fluid px-0 h-100" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
      <div className="row">
        {/* <div className="col-12 col-lg-6 ">
          <Breadcrumb idProyecto={idProyecto} />
        </div> */}

        <MobileSubHeader
            title={subHeaderTitle}
            subtitle={folderActive !== 'supervision' ? folderActive : null}
          >
           {renderButtons()}
          </MobileSubHeader>
        {/* <div className="col-12 col-lg-6 text-end"></div> */}

      </div>
      <div className="row align-items-center">{renderContenido()}</div>
    </div>
  );
};

export default Folder;

import React from "react";
import ColorBox from "./ColorBox";

const ColorLegend = ({ block, colors }) => {
  return (
    <div className="row py-3 align-items-center">
      <div className="col-12 px-0">
        {colors.map((color, index) => {
          return <ColorBox color={color} key={index} block={block} />;
        })}
      </div>
    </div>
  );
};

export default ColorLegend;
